import { useState, useCallback } from "react";
import ScholarshipFilters from "../components/ScholarshipFilters";
import PortalDrawer from "../components/PortalDrawer";
import "./BasedOnMajor.css";
const BasedOnMajor = () => {
  const [isScholarshipFiltersOpen, setScholarshipFiltersOpen] = useState(false);

  const openScholarshipFilters = useCallback(() => {
    setScholarshipFiltersOpen(true);
  }, []);

  const closeScholarshipFilters = useCallback(() => {
    setScholarshipFiltersOpen(false);
  }, []);

  return (
    <>
      <div className="based-on-major">
        {/* <div className="navbar2">
          <div className="info2">
            <div className="left-side-top-nav3">
              <img className="headerlogo-icon3" alt="" src="/headerlogo.svg" />
              <div className="top-nav-left3">
                <div className="headernav-link15">
                  <div className="nav-link-default12">Home</div>
                </div>
                <div className="headernav-link16">
                  <div className="nav-link-default12">Scholarships</div>
                </div>
                <div className="headernav-link17">
                  <div className="nav-link-default12">Services</div>
                </div>
                <div className="headernav-link17">
                  <div className="nav-link-default12">{`Community `}</div>
                </div>
                <div className="headernav-link17">
                  <div className="nav-link-default12">Resources</div>
                </div>
              </div>
            </div>
            <div className="right-side-top-nav3">
              <button className="menu3">
                <img className="vector-icon24" alt="" />
                <img className="vector-icon25" alt="" src="/vector.svg" />
              </button>
              <div className="messages-container">
                <img className="messages-icon2" alt="" src="/messages1.svg" />
                <img
                  className="notifications-icon2"
                  alt=""
                  src="/notifications2.svg"
                />
                <div className="mask-group-container">
                  <img
                    className="mask-group-icon2"
                    alt=""
                    src="/mask-group@2x.png"
                  />
                  <img className="vector-icon26" alt="" src="/vector1.svg" />
                </div>
              </div>
            </div>
          </div>
          <img
            className="scholarships-line-icon2"
            alt=""
            src="/scholarships-line.svg"
          />
          <img
            className="services-line-icon2"
            alt=""
            src="/services-line.svg"
          />
          <img
            className="community-line-icon2"
            alt=""
            src="/community-line.svg"
          />
          <img className="resources-icon2" alt="" src="/resources.svg" />
        </div> */}
        <div className="search2">
          <div className="header4">
            <div className="headertop-navprimarybreadcr2">
              <div className="page-title10">
                <div className="page-title11">Scholarships</div>
                <img className="underline-icon6" alt="" src="/underline.svg" />
              </div>
              <img className="arrow-icon4" alt="" src="/arrow.svg" />
              <div className="page-title12">
                <div className="based-on-your2">Based on Your Major</div>
                <img className="underline-icon7" alt="" />
              </div>
              <img className="arrow-icon5" alt="" src="/arrow1.svg" />
              <div className="page-title13">
                <div className="page-title11">Page Title</div>
                <img className="underline-icon7" alt="" />
              </div>
            </div>
            <div className="allpage-header2">
              <b className="based-on-major1">Based On Major</b>
            </div>
          </div>
          <div className="search-and-filter2">
            <input className="search-bar2" type="text" placeholder="Search" />
            <div className="filter2">
              <div className="filters-wrapper2">
                <div className="filter-button12">
                  <div className="button-text19">Category</div>
                  <div className="circle-112">
                    <img className="vector-icon27" alt="" src="/vector2.svg" />
                    <div className="div19">1</div>
                  </div>
                  <div className="arrows12">
                    <img
                      className="chevrondown-icon12"
                      alt=""
                      src="/chevrondown.svg"
                    />
                  </div>
                </div>
                <div className="filter-button13">
                  <div className="circle-113">
                    <img className="vector-icon27" alt="" src="/vector3.svg" />
                    <div className="div19">1</div>
                  </div>
                  <div className="button-text-parent4">
                    <div className="button-text20">Award Amount</div>
                    <div className="arrows12">
                      <img
                        className="chevrondown-icon12"
                        alt=""
                        src="/chevrondown1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="filter-button14">
                  <div className="circle-113">
                    <img className="vector-icon27" alt="" src="/vector4.svg" />
                    <div className="div19">1</div>
                  </div>
                  <div className="button-text-parent5">
                    <div className="button-text19">Location</div>
                    <div className="arrows12">
                      <img
                        className="chevrondown-icon12"
                        alt=""
                        src="/chevrondown2.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="filter-button15">
                  <div className="circle-113">
                    <img className="vector-icon27" alt="" src="/vector5.svg" />
                    <div className="div19">1</div>
                  </div>
                  <div className="button-text-parent5">
                    <div className="button-text19">Major</div>
                    <div className="arrows12">
                      <img
                        className="chevrondown-icon12"
                        alt=""
                        src="/chevrondown3.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="filter-button16">
                  <div className="button-text19">Grade Level</div>
                  <div className="circle-112">
                    <img className="vector-icon27" alt="" src="/vector6.svg" />
                    <div className="div19">1</div>
                  </div>
                  <div className="arrows12">
                    <img
                      className="chevrondown-icon12"
                      alt=""
                      src="/chevrondown4.svg"
                    />
                  </div>
                </div>
                <div className="filter-button17">
                  <div className="button-text19">Citizenship</div>
                  <div className="circle-112">
                    <img className="vector-icon27" alt="" src="/vector7.svg" />
                    <div className="div19">1</div>
                  </div>
                  <div className="arrows12">
                    <img
                      className="chevrondown-icon12"
                      alt=""
                      src="/chevrondown5.svg"
                    />
                  </div>
                </div>
                <div className="filter-button17">
                  <img className="vector-icon33" alt="" src="/vector8.svg" />
                </div>
              </div>
            </div>
            <div className="search-and-filter-inner1">
              <div className="frame-parent3">
                <div
                  className="frame-wrapper53"
                  onClick={openScholarshipFilters}
                >
                  <div className="tune-container">
                    <img className="tune-icon2" alt="" src="/tune@2x.png" />
                    <div className="filters2">Filters</div>
                  </div>
                </div>
                <div className="frame-wrapper54">
                  <div className="tune-container">
                    <img className="vector-icon34" alt="" src="/vector9.svg" />
                    <div className="filters2">Sort</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="available2">
          <div className="body2">
            <div className="scholarships-available2">
              785 scholarships available
            </div>
            <div className="filter-dropdown-button2">
              <div className="group-div">
                <div className="sort-by-container">
                  <div className="sort-by2">{`Sort by `}</div>
                  <select className="relevance-container">
                    <option value="relevance">Relevance</option>
                    <option value="a-z">A-Z</option>
                    <option value="deadline">Deadline</option>
                    <option value="newest">Newest</option>
                    <option value="awardamount">Award Amount</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="availble2">
          <div className="see-more-progress-bar4">
            <div className="scholarship-available2">
              785 Scholarship Available
            </div>
          </div>
        </div>
        <div className="scholarship-body2">
          <div className="row-2-wrapper2">
            <div className="card8">
              <div className="cardscholarshipsbackgroundco8">
                <img
                  className="cardscholarshipsimage-icon8"
                  alt=""
                  src="/cardscholarshipsimage@2x.png"
                />
              </div>
              <b className="title8">“Be Bold” No-Essay Scholarship</b>
              <div className="organization8">Bold.org</div>
              <div className="award-amount9">$25,000</div>
              <div className="deadline8">Deadline: 04/30/2023</div>
              <img
                className="component-2-icon8"
                alt=""
                src="/component-22.svg"
              />
            </div>
            <div className="card8">
              <div className="cardscholarshipsbackgroundco9">
                <img
                  className="cardscholarshipsimage-icon8"
                  alt=""
                  src="/cardscholarshipsimage1@2x.png"
                />
              </div>
              <b className="title8">$2,000 Nitro Scholarship Sweepstakes</b>
              <div className="organization8">Nitro</div>
              <div className="award-amount9">$2,000</div>
              <div className="deadline8">Deadline: 12/31/2023</div>
              <img
                className="component-2-icon8"
                alt=""
                src="/component-22.svg"
              />
            </div>
            <div className="card8">
              <div className="cardscholarshipsbackgroundco10">
                <img
                  className="cardscholarshipsimage-icon10"
                  alt=""
                  src="/cardscholarshipsimage2@2x.png"
                />
              </div>
              <b className="title8">
                $50,000 ScholarshipOwl No Essay Scholarship
              </b>
              <div className="organization8">ScholarshipOwl</div>
              <div className="award-amount9">$50,000</div>
              <div className="deadline8">Deadline: 06/17/2023</div>
              <img
                className="component-2-icon8"
                alt=""
                src="/component-22.svg"
              />
            </div>
            <div className="card8">
              <div className="cardscholarshipsbackgroundco11">
                <img
                  className="cardscholarshipsimage-icon10"
                  alt=""
                  src="/cardscholarshipsimage3@2x.png"
                />
              </div>
              <b className="title8">Coca-Cola Scholars Scholarship</b>
              <div className="organization8">Coca-Cola</div>
              <div className="award-amount9">$20,000</div>
              <div className="deadline8">Deadline: 10/18/2023</div>
              <img
                className="component-2-icon8"
                alt=""
                src="/component-22.svg"
              />
            </div>
          </div>
        </div>
        <div className="see-more4">
          <div className="see-more-progress-bar5">
            <div className="div25">24 / 785</div>
            <div className="buttonlarge17">
              <div className="button5" />
              <div className="button-text25">See More</div>
            </div>
            <img
              className="progress-bar-icon2"
              alt=""
              src="/progress-bar.svg"
            />
          </div>
        </div>
        <div className="footer3">
          <div className="footer-grid-row3">
            <div className="footer-menu15">
              <img className="footerlogo-icon3" alt="" src="/footerlogo2.svg" />
              <div className="footer-links15">
                <div className="footerlinknav9">
                  <div className="nav-link9">info@example.com</div>
                </div>
                <div className="footerlinknav9">
                  <div className="nav-link9">+1 234 567 9830</div>
                </div>
                <div className="footerlinknav9">
                  <div className="nav-link9">Address</div>
                </div>
              </div>
              <div className="icon-wrapper3">
                <img
                  className="footericoninstagram3"
                  alt=""
                  src="/footericoninstagram.svg"
                />
                <img
                  className="footericonfacbook3"
                  alt=""
                  src="/footericonfacbook.svg"
                />
                <img
                  className="footericontwitter3"
                  alt=""
                  src="/footericontwitter1.svg"
                />
                <img
                  className="footericonfacbook3"
                  alt=""
                  src="/footericonlinkedin.svg"
                />
                <img
                  className="footericonyoutube3"
                  alt=""
                  src="/footericonyoutube2.svg"
                />
              </div>
            </div>
            <div className="footer-menu-parent1">
              <div className="footer-menu16">
                <div className="students3">Students</div>
                <div className="footer-links15">
                  <div className="main-footer-link39">
                    <div className="footer-link39">How it works</div>
                  </div>
                  <div className="main-footer-link39">
                    <div className="footer-link39">Scholarships</div>
                  </div>
                  <div className="main-footer-link39">
                    <div className="footer-link39">Community</div>
                  </div>
                  <div className="main-footer-link39">
                    <div className="footer-link39">Services</div>
                  </div>
                </div>
              </div>
              <div className="footer-menu16">
                <div className="students3">Coaches</div>
                <div className="footer-links15">
                  <div className="main-footer-link39">
                    <div className="footer-link39">How it works</div>
                  </div>
                  <div className="main-footer-link39">
                    <div className="footer-link39">Blog</div>
                  </div>
                  <div className="main-footer-link39">
                    <div className="footer-link39">Mission</div>
                  </div>
                  <div className="main-footer-link39">
                    <div className="footer-link39">Footer Link</div>
                  </div>
                </div>
              </div>
              <div className="footer-menu16">
                <div className="students3">Company</div>
                <div className="footer-links15">
                  <div className="main-footer-link39">
                    <div className="footer-link39">About Us</div>
                  </div>
                  <div className="main-footer-link39">
                    <div className="footer-link39">Partners</div>
                  </div>
                  <div className="main-footer-link39">
                    <div className="footer-link39">Careers</div>
                  </div>
                  <div className="main-footer-link39">
                    <div className="footer-link39">Contact</div>
                  </div>
                </div>
              </div>
              <div className="footer-menu16">
                <div className="students3">Join our Email List</div>
                <div className="footer-links19">
                  <div className="main-footer-link51">
                    <div className="footer-link51">
                      Be the first to know about new scholarships, events,
                      workshops and much more!
                    </div>
                  </div>
                  <div className="subscribe-email-text-box3">
                    <div className="text-box2" />
                    <div className="input-placeholder2">Email</div>
                  </div>
                  <div className="buttonlarge18">
                    <div className="button6" />
                    <div className="button-text25">Sign Up</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-legal3">
            <div className="page-title11">
              © 2022 Scholist. All rights reserved.
            </div>
            <div className="page-title11">Disclaimer Cookies</div>
          </div>
        </div>
      </div>
      {isScholarshipFiltersOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom"
          onOutsideClick={closeScholarshipFilters}
        >
          <ScholarshipFilters onClose={closeScholarshipFilters} />
        </PortalDrawer>
      )}
    </>
  );
};

export default BasedOnMajor;
