import React from "react";

import Container from "@mui/material/Container";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import MenuItem from "@mui/material/MenuItem";
import { useState, useCallback, useEffect } from "react";

import Box from "@mui/material/Box";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";

import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { Navigate, useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./AppliedScholarship.css";
import { BASE_Server_URL } from "../requestMethods";

function handleDivClick(link) {
  window.open(link, "_blank");
}

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          sx={{
            backgroundColor: "rgb(217 214 214)",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "black",
            },
          }}
          variant="determinate"
          {...props}
        />
      </Box>
      {/* <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box> */}
    </Box>
  );
}

const AppliedScholarship = () => {
  const [sortType, setSortType] = React.useState("deadline");

  const [searchLoading, setSearchLoading] = useState(false);
  const [filteredScholarship, setFilteredScholarship] = useState([]);
  const [loadValue, setLoadValue] = useState(8);
  const [loadValue1, setLoadValue1] = useState(8);
  const [scholarshipBookmarks, setScholarshipBookmarks] = useState([]);
  const colors = ["#F8D100", "#5D57F9", " #F40000", "#00B8C3"];
  const user = useSelector((state) => state.user.currentUser);

  const navigate = useNavigate();
  const handleChange = (event) => {
    setSortType(event.target.value);
  };
  function extractScholarshipInfo(scholarshipName) {
    const awardTypes = /(scholarship|grant|loan|fellowship)/i;
    const match = scholarshipName.match(awardTypes);

    if (match) {
      const words = scholarshipName.split(" ");
      const awardIndex = words.findIndex(
        (word) => word.toLowerCase() === match[1].toLowerCase()
      );

      if (awardIndex > 0) {
        if (awardIndex >= 2) {
          return `${words.slice(0, 2).join(" ")} ${words[awardIndex]}`;
        } else {
          return `${words[0]} ${words[awardIndex]}`;
        }
      }
    }

    return scholarshipName.split(" ").slice(0, 2).join(" ");
  }

  function SearchLoading() {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    if (searchLoading) {
      return (
        <div>
          {" "}
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <>
          {/* based on profile scholarship */}
          {filteredScholarship.slice(0, loadValue).map((elem, index) => (
            <div
              className="card24"
              //onClick={() => handleDivClick(elem.link + "/" + elem.id)}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div
                style={{
                  backgroundColor:
                    hoveredIndex === index
                      ? "#ccc"
                      : Number(elem.amount) > 1000 && Number(elem.amount) < 3000
                      ? colors[0]
                      : Number(elem.amount) > 3000 && Number(elem.amount) < 6000
                      ? colors[1]
                      : Number(elem.amount) > 6000 &&
                        Number(elem.amount) < 10000
                      ? colors[2]
                      : Number(elem.amount) > 10000
                      ? colors[3]
                      : colors[1],
                }}
                className="cardscholarshipsbackgroundco24"
              >
                <Link
                  className="link"
                  to={user ? `/scholarships-description1/${elem.id}` : `/login`}
                >
                  <img
                    className="cardscholarshipsimage-icon24"
                    alt={getSubstring(elem.link, ".", ".")}
                    //src="/cardscholarshipsimage4@2x.png"
                    // src="https://logo.clearbit.com/mortarboard.org/"
                    src={
                      "https://logo.clearbit.com/" +
                      getSubstringForLogo(elem.link, ".", ".") +
                      "/"
                    }
                  />
                </Link>
              </div>
              {/* .split('.').slice(0,2).join(' ') */}
              {/* <b className="title24">{elem.name.split(' ').slice(0,3).join(' ') + ' '+ elem.awardType}</b> */}
              <b className="title24">
                <Link
                  className="link"
                  to={user ? `/scholarships-description1/${elem.id}` : `/login`}
                  style={{ color: "black" }}
                >
                  {extractScholarshipInfo(elem.name)}
                </Link>
              </b>
              <div className="organization24">
                {getSubstring(elem.link, ".", ".")}
              </div>
              <div className="award-amount25">
                ${elem.amount ? elem.amount : "Varies"}
              </div>
              <div className="deadline23">
                Deadline:{" "}
                {DeadlineOrganiser(
                  elem.deadline !== null
                    ? elem.deadline.slice(0, elem.deadline.indexOf("T"))
                    : "not specified"
                )}
              </div>
              {/* <img
         className="component-2-icon24"
         alt=""
         src="/component-23.svg"
       /> */}
              {BookmarkChecker(elem.scholarshipId, elem)}
            </div>
          ))}
        </>
      );
    }
  }

  const getScholarships = async () => {
    setSearchLoading(true);

    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.get("/appliedscholarship");

      console.log("applied scholarship", res.data);
      //setAppli
      //console.log(res.data)
      // const pageC = await res.data.totalPages
      // console.log(res)
      //setScholarships(res.data)
      setFilteredScholarship(res.data);
    } catch (error) {}
    setSearchLoading(false);
  };
  useEffect(() => {
    getScholarships();
  }, []);

  function getSubstring(string, char1, char2) {
    //console.log(string.split(".").length - 1)

    if (string.split(".").length - 1 > 1) {
      const first = string.indexOf(char1) + 1;
      const link = string.slice(
        string.indexOf(char1) + 1,
        //string.lastIndexOf(char2),
        string.indexOf(char2, first + 1)
      );

      return link.charAt(0).toUpperCase() + link.slice(1);
    } else {
      //console.log(string)
      const firstSlash = string.indexOf("/") + 1;
      //console.log(string.indexOf("/", firstSlash + 1))
      // console.log(  string.indexOf('/') + 1)
      // console.log( string.indexOf(char1) + 1)
      const link = string.slice(
        // string.indexOf(char1) + 1,
        //string.lastIndexOf(char2),
        //string.indexOf("/", firstSlash + 1),
        string.indexOf("/") + 2,
        string.indexOf(char1)
      );

      return link.charAt(0).toUpperCase() + link.slice(1);
      //return ()
    }
    //count number of dot in string
    //get word between second slash
  }

  function getSubstringForLogo(string, char1, char2) {
    //console.log(string.split(".").length - 1)

    if (string.split(".").length - 1 > 1) {
      const first = string.indexOf(char1) + 1;
      const link = string.slice(
        string.indexOf(char1) + 1,
        //string.lastIndexOf(char2),
        string.indexOf(char2, first + 1) + 4
      );
      //console.log(link)

      return link.charAt(0).toUpperCase() + link.slice(1);
    } else {
      //console.log(string)
      const firstSlash = string.indexOf("/") + 1;
      //console.log(string.indexOf("/", firstSlash + 1))
      // console.log(  string.indexOf('/') + 1)
      // console.log( string.indexOf(char1) + 1)
      const link = string.slice(
        // string.indexOf(char1) + 1,
        //string.lastIndexOf(char2),
        //string.indexOf("/", firstSlash + 1),
        string.indexOf("/") + 2,
        string.indexOf(char1) + 4
      );
      //console.log(link)

      return link.charAt(0).toUpperCase() + link.slice(1);
      //return ()
    }
    //count number of dot in string
    //get word between second slash
  }

  function LoadMore() {
    //setSearchLoading(true)
    if (loadValue < filteredScholarship.length) {
      setLoadValue(loadValue + 8);
    }
    if (loadValue > filteredScholarship.length) {
      setLoadValue(filteredScholarship.length);
      console.log("this");
    }

    if (loadValue + 8 > filteredScholarship.length) {
      setLoadValue(filteredScholarship.length);
    }

    // if(!user && loadValue + 8 > 20){
    //   console.log('Pls sign UPdcec')
    // }
    else {
      console.log("No more data to display");
    }
    //setSearchLoading(false)
  }

  function LoadMore1() {
    console.log(loadValue1);
    console.log(filteredScholarship);
    if (loadValue1 < filteredScholarship.length) {
      setLoadValue1(loadValue1 + 8);
    }
    if (loadValue1 > filteredScholarship.length) {
      console.log("this");
      setLoadValue1(filteredScholarship.length);
    }
    if (loadValue1 + 8 > filteredScholarship.length) {
      setLoadValue1(filteredScholarship.length);
    } else {
      console.log("No more data to display");
    }
  }

  function ScholarshipName(scholarship) {
    //{elem.name.split(' ').slice(0,3).join(' ') + ' '+ elem.awardType}

    const elem = scholarship.name.split(" ");
    //console.log(elem)
    if (
      elem[2] == "Scholarship" ||
      elem[2] == "Grant" ||
      elem[2] == "Fellowship" ||
      elem[2] == "Loan"
    ) {
      return scholarship.name.split(" ").slice(0, 2).join(" ");
    }

    if (
      elem[1] == "Scholarship" ||
      elem[1] == "Grant" ||
      elem[1] == "Fellowship" ||
      elem[1] == "Loan"
    ) {
      return scholarship.name.split(" ").slice(0, 2).join(" ");
    } else {
      return (
        scholarship.name.split(" ").slice(0, 2).join(" ") +
        " " +
        scholarship.awardType
      );
    }
  }

  function DeadlineOrganiser(deadline) {
    if (deadline == "not specified") {
      return "not specified";
    } else {
      //console.log('fgvhbnbhgfc',deadline)
      const date = deadline.split("-");
      return date[1] + "/" + date[2] + "/" + date[0];
    }
  }

  const getScholarshipBookmarks = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      //console.log(currentUser)
      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.get("/scholarshipbookmark");
      console.log("scholarship bookmarks", res.data);
      setScholarshipBookmarks(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getScholarshipBookmarks();
  }, []);

  const deleteScholarshipBookmark = async (event, id) => {
    console.log("delete", event);
    console.log(id);
    event.preventDefault();

    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      //console.log(currentUser)
      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.delete("/scholarshipbookmark/" + id);
      console.log(res);
      getScholarshipBookmarks();
      getScholarships();
    } catch (error) {
      console.log(error);
    }
  };

  function BookmarkChecker(Id, elem) {
    //console.log(coachBookmarks)
    // const filteredResult = coachBookmarks.filter((item) => {
    //   return (item.coachId.indexOf(coachId) > 0);

    //});
    const isObjectPresent = scholarshipBookmarks.find(
      (o) => o.scholarshipId === Id
    );
    if (isObjectPresent) {
      //console.log(isObjectPresent)
      return (
        //red love

        <img
          className="component-2-icon24"
          alt=""
          src="/component-23.svg"
          onClick={(e) => deleteScholarshipBookmark(e, elem.scholarshipId)}
        />

        //   <svg  onClick={ (e)=>deleteScholarshipBookmark(e, elem.id)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 96 96" fill="none">
        //   <g clip-path="url(#clip0_1109_34052)">
        //     <path d="M24 76C24 80.4 27.6 84 32 84H64C68.4 84 72 80.4 72 76V28H24V76ZM32 36H64V76H32V36ZM62 16L58 12H38L34 16H20V24H76V16H62Z" fill="#434343"/>
        //   </g>
        //   <defs>
        //     <clipPath id="clip0_1109_34052">
        //       <rect width="96" height="96" fill="white"/>
        //     </clipPath>
        //   </defs>
        // </svg>
      );
    } else {
      return (
        // white love

        <img
          className="component-2-icon32"
          alt=""
          src="/component-22.svg"
          onClick={(e) => handleScholarshipBookmark(e, elem)}
        />
      );
    }
  }

  const handleScholarshipBookmark = async (event, data) => {
    console.log(data);
    event.preventDefault();

    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      //console.log(currentUser)
      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.post("/scholarshipbookmark", {
        scholarshipId: data.id,
        name: data.name,
        deadline: data.deadline,
        amount: data.amount ? data.amount : "NaN",
        details: data.details,
        link: data.link,
      });

      //const eventReminder = await

      //console.log('gvhbnm vghcrcfghkhgb gghk gyhh ,',data.deadline)
      if (data.deadline) {
        const eventReminder = await client.post("/event", {
          itemId: data.id,
          name: data.name,
          link: data.link,
          date: data.deadline,
          eventType: "scholarship",
        });

        //console.log(res)
        console.log("event reminder", eventReminder);
      }

      getScholarshipBookmarks();
    } catch (error) {
      console.log(error);
    }
  };

  function Sorting(sortType) {
    console.log("sort has been called", sortType);
    if (sortType === "latest") {
      return (filteredScholarship) =>
        [...filteredScholarship].sort((a, b) => a.createdAt - b.createdAt);
    }
    if (sortType === "amount") {
      return (filteredScholarship) =>
        [...filteredScholarship].sort((a, b) => b.amount - a.amount);
    }

    if (sortType === "deadline") {
      return (filteredScholarship) =>
        [...filteredScholarship].sort(
          (a, b) => new Date(a.deadline) - new Date(b.deadline)
        );
      //return new Date(a.deadline) - new Date(b.deadline); // Sort by deadline
    }
    if (sortType === "a-z") {
      //setFilteredScholarship((filteredScholarship) =>[...filteredScholarship].sort((a, b) => a.name- b.name))

      // setFilteredScholarship(
      //   filteredScholarship.sort(function (a, b) {
      //     if (a.name < b.name) {
      //       return -1;
      //     }
      //     if (a.name > b.name) {
      //       return 1;
      //     }
      //     return 0;
      //   })
      //   //(filteredScholarship) =>[...filteredScholarship].sort((a, b) => b.name- a.name)
      //   )

      //return(filteredScholarship.sort((a,b) =>  a.name.localeCompare(b.name)))
      return (filteredScholarship) =>
        [...filteredScholarship].sort((a, b) => a.name.localeCompare(b.name));

      //return((filteredScholarship) =>[...filteredScholarship].sort((a, b) => a.name> b.name))
      //setFilteredScholarship(filteredScholarship.sort())

      // return a.name.localeCompare(b.name); // Sort by name
    } else {
      return filteredScholarship;
    }
  }

  // Function to sort scholarships based on the chosen criteria
  function sortScholarships(sortBy, scholarships) {
    return scholarships.sort((a, b) => {
      if (sortBy === "a-z") {
        return a.name.localeCompare(b.name); // Sort by name
      } else if (sortBy === "deadline") {
        return new Date(a.deadline) - new Date(b.deadline); // Sort by deadline
      } else if (sortBy === "amount") {
        console.log("amount");
        return Number(a.amount) - Number(b.amount); // Sort by amount
      }
      // Handle other sorting options if needed...
    });
  }

  useEffect(() => {
    setFilteredScholarship(Sorting(sortType));
    //setFilteredScholarship(sortScholarships(sortType, filteredScholarship))
  }, [sortType]);

  const onDashBoardClick = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  return (
    <>
      <Container>
        <div
          className="dashboardheaderbox"
          onClick={
            //handleClose
            onDashBoardClick
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 22"
            fill="none"
          >
            <g clip-path="url(#clip0_1095_34041)">
              <path
                d="M12.8416 15.2075L9.02496 11L12.8416 6.7925L11.6666 5.5L6.66663 11L11.6666 16.5L12.8416 15.2075Z"
                fill="#4A93CF"
              />
            </g>
            <defs>
              <clipPath id="clip0_1095_34041">
                <rect width="20" height="22" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p className="dasboardheader">Dashboard</p>
        </div>

        <div className="allpage-header9-applied">
          <div className="savedIconName">
            <b className="scholarshipsApplied">Applied Scholarships</b>
          </div>
        </div>

        {/* <div className="available3">
          <div className="body8">
            <div>
            <a className="scholarships-available3">
              {/* {filteredScholarship.length} scholarships available */}
        {/* You have 13 scholarships matches based on your profile 
            </a>
            </div>
            
            <div className="filter-dropdown-button3">
              <div className="filter-dropdown-button-inner1">
                <div className="sort-by-parent1">
                  <div className="sort-by3">{`Sort by `}</div>
                  <FormControl>
                  <Select  label="Relevance" sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
              }}  value={sortType}
              onChange={handleChange}
              >
                    <MenuItem value="relevance"><a className="sortList" >Relevance</a></MenuItem>
                    <MenuItem value="a-z"><a className="sortList">A-Z</a></MenuItem>
                    <MenuItem value="deadline"><a className="sortList">Deadline</a></MenuItem>
                    {/* <MenuItem value="newest"><a className="sortList">Newest</a></MenuItem> */}
        {/* <MenuItem value="amount"><a className="sortList">Amount</a></MenuItem>
                  </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="availablematches">
          <div className="MatchesndDeadline">
            <div className="noofmatches1namebox">
              <a className="noOfmatches">
                {/* {filteredScholarship.length} scholarships available */}
                You have
              </a>{" "}
              <a className="noOfmatches1">
                {filteredScholarship.length} submitted scholarships
              </a>
            </div>

            <div className="filter-dropdown-button3">
              <div className="filter-dropdown-button-inner1">
                <div className="sort-by-parent1">
                  <div className="sort-by3">{`Sort by `}</div>
                  <FormControl>
                    <Select
                      label="Relevance"
                      sx={{
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                      }}
                      value={sortType}
                      onChange={handleChange}
                    >
                      <MenuItem value="relevance">
                        <a className="sortList">Relevance</a>
                      </MenuItem>
                      <MenuItem value="a-z">
                        <a className="sortList">A-Z</a>
                      </MenuItem>
                      <MenuItem value="deadline">
                        <a className="sortList">Deadline</a>
                      </MenuItem>
                      {/* <MenuItem value="newest"><a className="sortList">Newest</a></MenuItem> */}
                      <MenuItem value="amount">
                        <a className="sortList">Amount</a>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="scholarshipsContainer-cat">{SearchLoading()}</div>
      </Container>
      <div className="seemore-progress-body">
        <div className="see-more-progress-bar11">
          <div className="progress-body">
            <div className="div58">
              {loadValue > filteredScholarship.length
                ? filteredScholarship.length
                : loadValue}{" "}
              / {filteredScholarship.length}
            </div>
            {/* <img
        className="progress-bar-icon6"
        alt=""
        src="/progress-bar.svg"
      /> */}
          </div>
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel
              value={(loadValue / filteredScholarship.length) * 100}
            />
          </Box>
          <div></div>

          <div className="buttonlarge30">
            <button
              className="button-seemore"
              onClick={() =>
                !user && loadValue > 20
                  ? console.log("pls sign updnmm")
                  : LoadMore()
              }
              disabled={loadValue == filteredScholarship.length}
            >
              See More
            </button>
          </div>
        </div>
      </div>
    </>

    // <div>ScholarshipMatches</div>
  );
};

export default AppliedScholarship;
