import { useEffect, useState } from "react";
import "./WriteMessagesPopUp.css";
import axios from "axios";
import emailjs from "emailjs-com"; // <-- New import
import { BASE_Server_URL } from "../requestMethods";

const WriteMessagesPopUp = (props) => {
  const [messagedetails, setMessageDetails] = useState("");
  const [messageSent, setmessageSent] = useState(false);
  const [currentChat, setCurrentChat] = useState(null);

  const handleTextareaChange = (event) => {
    setMessageDetails(event.target.value);
  };

  const handleSendMessage = () => {
    StartConversation(); // <-- Updated to call StartConversation
    sendEmail(); // <-- New function call
    setmessageSent(true);
  };

  const buttonStyle = {
    backgroundColor: messagedetails ? "#4A93CF" : "#E7E7E7",
    cursor: messagedetails ? "pointer" : "not-allowed", // <-- Added cursor style
  };

  useEffect(() => {
    console.log("write message", props);
  }, []);

  function CheckIfConversationExist(userId, coachId) {
    const conversationToFind = [userId, coachId];

    const foundConversation = props.conversations.find((conversation) =>
      conversation.members.every((member) =>
        conversationToFind.includes(member)
      )
    );

    return foundConversation;
  }

  const ConversationStart = async (userId, coachId) => {
    const conversation = {
      senderId: userId,
      receiverId: coachId,
    };

    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      const res = await client.post("/conversation", conversation);
      setCurrentChat(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  function StartConversation() {
    const convo = CheckIfConversationExist(
      props.user.user.id,
      props.coach.owner
    );

    if (convo) {
      setCurrentChat(convo);
    } else {
      ConversationStart(props.user.user.id, props.coach.owner);
    }
  }

  const handleSubmit = async (e) => {
    const message = {
      sender: props.user.user.id,
      text: messagedetails,
      conversationId: currentChat.id,
    };

    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      const res = await client.post("/message", message);
      setMessageDetails("");

      console.log("new message", res.data);

      setmessageSent(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log("current chat", currentChat);
    if (currentChat) {
      handleSubmit();
    }
  }, [currentChat]);

  const sendEmail = () => {
    // <-- New function
    const templateParams = {
      // <-- New object
      to_name: props.coach.name, // <-- New line
      message: messagedetails, // <-- New line
      to_email: props.coach.email, // <-- New line
    };
    console.log("ewnm", props.coach.email);

    emailjs
      .send(
        //"service_vz0mth2",
        "service_40zofqo",
        //"template_u7ko3so",
        "template_kvutiac",
        templateParams,
        //"ezjGhcddeSjOgFGjj"
        "Z71scS1wOVMi8nG2V"
      )
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text); // <-- New line
      })
      .catch((err) => {
        console.error("Failed to send email. Error:", err); // <-- New line
      });
  };

  return messageSent ? (
    <div className="writemessagespopupsent">
      <div className="writemessagespopupsent-inner">
        <div className="ellipse-group" onClick={props.onClose}>
          <div className="group-item" />
          <div className="div1">+</div>
        </div>
      </div>
      <div className="message-sent-wrapper">
        <b className="message-sent">{`Message Sent `}</b>
      </div>
    </div>
  ) : (
    <div className="writemessagespopup">
      <div className="writemessagespopup-inner">
        <div className="ellipse-group" onClick={props.onClose}>
          <div className="group-item" />
          <div className="div1">+</div>
        </div>
      </div>
      <div className="new-message-wrapper">
        <div className="new-message">New Message</div>
      </div>
      <div className="writemessagesreceiverbox">
        <div className="new-message">To:</div>
        <div className="writemessagesreceiverinput">
          <p className="writemessagesreceiverinputemai" type="text">
            {props.coach.name}
          </p>
          <input type="hidden" name="coachEmail" value={props.coach.email} />
        </div>
      </div>
      <div className="writemessagesdetailsbox">
        <div className="new-message">Your Message:</div>
        <div className="writemessageinput">
          <textarea
            className="jessicahowesgmailcom-nsxbnnc"
            placeholder="Type Message"
            value={messagedetails}
            onChange={handleTextareaChange}
          />
        </div>
      </div>
      <button
        className="writemessagessendbutton"
        style={buttonStyle}
        onClick={handleSendMessage} // <-- Updated to call handleSendMessage
        disabled={!messagedetails}
      >
        <div className="writemessagessendtext">Send Message</div>
      </button>
    </div>
  );
};

export default WriteMessagesPopUp;

// import { useEffect, useState } from "react";
// import "./WriteMessagesPopUp.css";
// import axios from "axios";
// import { BASE_Server_URL } from "../requestMethods";

// const WriteMessagesPopUp = (props) => {
//   // console.log(props)

//   const [messagedetails, setMessageDetails] = useState("");
//   const [messageSent, setmessageSent] = useState(false);
//   const [currentChat, setCurrentChat] = useState(null);

//   // Function to handle changes in the textarea
//   const handleTextareaChange = (event) => {
//     setMessageDetails(event.target.value);
//   };

//   // Function to handle sending the message
//   const handleSendMessage = () => {
//     // Your logic to send the message goes here
//     setmessageSent(true);
//   };

//   // Define the inline style for the button
//   const buttonStyle = {
//     backgroundColor: messagedetails ? "#4A93CF" : "#E7E7E7",
//     // Add other CSS properties as needed
//   };
//   //   return (

//   //     {
//   //         if(messageSent){
//   //             return(
//   //                 <div className="writemessagespopup">
//   //                  <h1> Message Sent</h1>
//   //                 </div>
//   //             )
//   //         }
//   //         else{
//   //             return(
//   //                 <>
//   //     <div className="writemessagespopup">
//   //       <div className="writemessagespopup-inner">
//   //         <div className="ellipse-parent">
//   //           <div className="group-child" />
//   //           <div className="div5">+</div>
//   //         </div>
//   //       </div>
//   //       <div className="new-message-wrapper">
//   //         <div className="new-message">New Message</div>
//   //       </div>
//   //       <div className="writemessagesreceiverbox">
//   //         <div className="new-message">To:</div>
//   //         <div className="writemessagesreceiverinput">
//   //           <input
//   //             className="writemessagesreceiverinputemai"
//   //             placeholder="jessicahowes@gmail.com"
//   //             type="text"
//   //           />
//   //         </div>
//   //       </div>
//   //       <div className="writemessagesdetailsbox">
//   //         <div className="new-message">Your Message:</div>
//   //         <div className="writemessageinput">
//   //           {/* <textarea
//   //             className="jessicahowesgmailcom-nsxbnnc"
//   //             placeholder="jessicahowes@gmail.com nsxbnncn bnx nbhbchbdcnxb cbxbsxdc  c bxnsx  csn shxbn csn ncb snxb csvbhn sxbvbsnc  hcbns shv bsvgbhbns xbnvbns x   v cshbn sb xvbnsx bcsvhsvc bcvhcd  sbvchbsbn csbgsbn swsgvbs sxbhgbs sxvbvss schnbs snbs wx"
//   //           /> */}
//   //             <textarea
//   //         className="jessicahowesgmailcom-nsxbnnc"
//   //         placeholder="jessicahowes@gmail.com nsxbnncn bnx nbhbchbdcnxb cbxbsxdc  c bxnsx  csn shxbn csn ncb snxb csvbhn sxbvbsnc  hcbns shv bsvgbhbns xbnvbns x   v cshbn sb xvbnsx bcsvhsvc bcvhcd  sbvchbsbn csbgsbn swsgvbs sxbhgbs sxvbvss schnbs snbs wx"
//   //         value={messagedetails}
//   //         onChange={handleTextareaChange}
//   //       />
//   //         </div>
//   //       </div>
//   //       <div className="writemessagessend"
//   //          style={buttonStyle}
//   //          onClick={handleSendMessage}>
//   //         <div className="writemessagessendtext">Send Message</div>
//   //       </div>
//   //     </div>
//   //     </>
//   //   )
//   //         }
//   //     }

//   //   );

//   useEffect(() => {
//     console.log("write message", props);
//   }, []);

//   function CheckIfConversationExist(userId, coachId) {
//     const conversationToFind = [userId, coachId];

//     const foundConversation = props.conversations.find((conversation) =>
//       conversation.members.every((member) =>
//         conversationToFind.includes(member)
//       )
//     );

//     return foundConversation;
//   }

//   // const getConversations = async () => {
//   //   try {
//   //     // ... (rest of your code)
//   //   const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
//   //   const currentUser = user && JSON.parse(user).currentUser;
//   //   const TOKEN = currentUser?.tokens.access.token;

//   //   const client = axios.create({
//   //     baseURL: "http://localhost:3000/v1/",
//   //     headers: { Authorization: `Bearer ${TOKEN}` },
//   //     });
//   //   const res = await client.get("/conversation");
//   //     console.log('concos', res)
//   //   setConversations(res.data);

//   //   } catch (err) {
//   //     console.log(err);
//   //   }
//   // };

//   const ConversationStart = async (userId, coachId) => {
//     const conversation = {
//       senderId: userId,
//       receiverId: coachId,
//     };

//     try {
//       // ... (rest of your code)

//       const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
//       const currentUser = user && JSON.parse(user).currentUser;
//       const TOKEN = currentUser?.tokens.access.token;

//       const client = axios.create({
//         baseURL: BASE_Server_URL,
//         headers: { Authorization: `Bearer ${TOKEN}` },
//       });
//       // const res = await client.get("/conversation");

//       const res = await client.post("/conversation", conversation);
//       //setMessages([...messages, res.data]);
//       //setNewMessage("");
//       //setc

//       console.log("new conversatiob", res.data);
//       setCurrentChat(res.data);

//       //getConversations()
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   function StartConversation() {
//     // console.log('coachId',coachId)
//     //console.log('Id',userd.user.id)

//     //console.log(coachId)

//     //check if conversations exist

//     const convo = CheckIfConversationExist(
//       props.user.user.id,
//       props.coach.owner
//     );

//     if (convo) {
//       setCurrentChat(convo);
//     } else {
//       //console.log('convofound',convo)

//       ConversationStart(props.user.user.id, props.coach.owner);
//     }

//     //closeNewMessagePopup()
//   }

//   const handleSubmit = async (e) => {
//     //e.preventDefault();
//     const message = {
//       sender: props.user.user.id,
//       text: messagedetails,
//       conversationId: currentChat.id,
//     };

//     // const receiverId = currentChat.members.find(
//     //   (member) => member !== user._id
//     // );

//     // socket.current.emit("sendMessage", {
//     //   senderId: user._id,
//     //   receiverId,
//     //   text: newMessage,
//     // });

//     try {
//       // ... (rest of your code)

//       const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
//       const currentUser = user && JSON.parse(user).currentUser;
//       const TOKEN = currentUser?.tokens.access.token;

//       const client = axios.create({
//         baseURL: BASE_Server_URL,
//         headers: { Authorization: `Bearer ${TOKEN}` },
//       });
//       // const res = await client.get("/conversation");

//       const res = await client.post("/message", message);
//       //setMessages([...messages, res.data]);
//       setMessageDetails("");

//       console.log("new message", res.data);

//       setmessageSent(true);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   useEffect(() => {
//     console.log("current chat", currentChat);
//     if (currentChat) {
//       handleSubmit();
//     }
//   }, [currentChat]);

//   return messageSent ? (
//     //   <div className="writemessagespopup">
//     //      <div className="writemessagespopup-inner">
//     //         <div className="ellipse-parent">
//     //           <div className="group-child" />
//     //           <div className="div5">+</div>
//     //         </div>
//     //       </div>
//     //     <h1>Message Sent</h1>
//     //   </div>
//     <div className="writemessagespopupsent">
//       <div className="writemessagespopupsent-inner">
//         <div className="ellipse-group" onClick={props.onClose}>
//           <div className="group-item" />
//           <div className="div1">+</div>
//         </div>
//       </div>
//       <div className="message-sent-wrapper">
//         <b className="message-sent">{`Message Sent `}</b>
//       </div>
//     </div>
//   ) : (
//     //   <>
//     //     <div className="writemessagespopup">
//     //       <div className="writemessagespopup-inner">
//     //         <div className="ellipse-parent">
//     //           <div className="group-child" />
//     //           <div className="div5">+</div>
//     //         </div>
//     //       </div>
//     //       <div className="new-message-wrapper">
//     //         <div className="new-message">New Message</div>
//     //       </div>
//     //       <div className="writemessagesreceiverbox">
//     //         <div className="new-message">To:</div>
//     //         <div className="writemessagesreceiverinput">
//     //           <input
//     //             className="writemessagesreceiverinputemai"
//     //             placeholder="jessicahowes@gmail.com"
//     //             type="text"
//     //           />
//     //         </div>
//     //       </div>
//     //       <div className="writemessagesdetailsbox">
//     //         <div className="new-message">Your Message:</div>
//     //         <div className="writemessageinput">
//     //           <textarea
//     //             className="jessicahowesgmailcom-nsxbnnc"
//     //             placeholder="jessicahowes@gmail.com nsxbnncn bnx nbhbchbdcnxb cbxbsxdc c bxnsx csn shxbn csn ncb snxb csvbhn sxbvbsnc hcbns shv bsvgbhbns xbnvbns x v cshbn sb xvbnsx bcsvhsvc bcvhcd sbvchbsbn csbgsbn swsgvbs sxbhgbs sxvbvss schnbs snbs wx"
//     //             value={messagedetails}
//     //             onChange={handleTextareaChange}
//     //           />
//     //         </div>
//     //       </div>
//     //       <div
//     //         className="writemessagessend"
//     //         style={buttonStyle}
//     //         onClick={handleSendMessage}
//     //       >
//     //         <div className="writemessagessendtext">Send Message</div>
//     //       </div>
//     //     </div>
//     //   </>

//     <div className="writemessagespopup">
//       <div className="writemessagespopup-inner">
//         <div className="ellipse-group" onClick={props.onClose}>
//           <div className="group-item" />
//           <div className="div1">+</div>
//         </div>
//       </div>
//       <div className="new-message-wrapper">
//         <div className="new-message">New Message</div>
//       </div>
//       <div className="writemessagesreceiverbox">
//         <div className="new-message">To:</div>
//         <div className="writemessagesreceiverinput">
//           {/* Display the coach's name */}
//           <p className="writemessagesreceiverinputemai" type="text">
//             {props.coach.name}
//           </p>

//           {/* Hidden input field for the coach's email */}
//           <input type="hidden" name="coachEmail" value={props.coach.email} />
//         </div>
//       </div>
//       <div className="writemessagesdetailsbox">
//         <div className="new-message">Your Message:</div>
//         <div className="writemessageinput">
//           <textarea
//             className="jessicahowesgmailcom-nsxbnnc"
//             placeholder="Type Message"
//             value={messagedetails}
//             onChange={handleTextareaChange}
//           />
//         </div>
//       </div>

//       <button
//         className="writemessagessendbutton"
//         style={buttonStyle}
//         onClick={() => StartConversation()}
//         disabled={!messagedetails}
//       >
//         <div className="writemessagessendtext">Send Message</div>
//       </button>
//     </div>
//   );
// };

// export default WriteMessagesPopUp;
