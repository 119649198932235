import { useState, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavigationDrawer from "../components/NavigationDrawer";
import PortalDrawer from "../components/PortalDrawer";
import "./HOMEPAGE.css";

import { BASE_Server_URL, userRequest } from "../requestMethods";
import axios from "axios";

import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useSelector } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";

import Tooltip from "@mui/material/Tooltip";
import { Faq } from "../components/Faq";

const HOMEPAGE = () => {
  const navigate = useNavigate();
  const [isNavigationDrawerOpen, setNavigationDrawerOpen] = useState(false);

  const onHeadernavLink1Click = useCallback(() => {
    navigate("/services");
  }, [navigate]);

  const onHeadernavLink2Click = useCallback(() => {
    navigate("/scholarships-category-page");
  }, [navigate]);

  const onHeadernavLink3Click = useCallback(() => {
    navigate("/communities");
  }, [navigate]);

  const openNavigationDrawer = useCallback(() => {
    setNavigationDrawerOpen(true);
  }, []);

  const closeNavigationDrawer = useCallback(() => {
    setNavigationDrawerOpen(false);
  }, []);

  const onButtonmediumClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const onButtonmedium1Click = useCallback(() => {
    // navigate("/signup");
    navigate("/welcomesignup");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    // navigate("/signup");
    navigate("/welcomesignup");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  const onLinkToPageClick = useCallback(() => {
    navigate("/scholarships-category-page");
  }, [navigate]);

  const onLinkToPage1Click = useCallback(() => {
    navigate("/communities");
  }, [navigate]);

  const onLinkToPage2Click = useCallback(() => {
    navigate("/services");
  }, [navigate]);

  const onLinkToPage3Click = useCallback(() => {
    //navigate("/signup");
    navigate("/welcomesignup");
  }, [navigate]);

  const onButtonlargeClick = useCallback(() => {
    navigate("/scholarships-description1");
  }, [navigate]);

  const onSeeMoreClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const onButtonlarge6Click = useCallback(() => {
    navigate("/scholarships-description1");
  }, [navigate]);

  const onButtonlarge8Click = useCallback(() => {
    navigate("/scholarships-description1");
  }, [navigate]);

  const onButtonlarge10Click = useCallback(() => {
    navigate("/scholarships-description1");
  }, [navigate]);

  const onSeeMore1Click = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const onMainFooterLink2Click = useCallback(() => {
    navigate("/communities");
  }, [navigate]);

  const [scholarships, setScholarships] = useState([]);
  const [scholarshipBookmarks, setScholarshipBookmarks] = useState([]);

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});

  const [communityBookmarks, setCommunityBookmarks] = useState([]);

  const [profile, setProfile] = useState(null);
  const colors = [
    "blue",
    "red",
    "green",
    "purple",
    "yellow",
    "brown",
    "pink",
    "golden",
    "orange",
    "crimson",
  ];

  const userd = useSelector((state) => state.user.currentUser);

  const [scholarshipLoading, setScholarshipLoading] = useState(false);

  const handleApplyRedirect = (e, link) => {
    // e.preventDefault();
    // window.location.href = link;

    e.preventDefault();
    window.open(link);
  };

  const getScholarships = async () => {
    try {
      setScholarshipLoading(true);
      //const res = await axios.get(`http://localhost:3000/v1/scholarship?page=${0}&limit=3`);
      const res = await axios.get(`${BASE_Server_URL}scholarship`);
      console.log(res);
      setScholarships(res.data);

      setScholarshipLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getScholarships();
  }, []);

  const user = useSelector((state) => state.user.currentUser);

  const handleScholarshipBookmark = async (event, data) => {
    console.log(data);
    event.preventDefault();
    try {
      const res = await userRequest.post("/scholarshipbookmark", {
        scholarshipId: data.id,
        name: data.name,
        deadline: data.deadline,
        amount: data.amount ? data.amount : "NaN",
        details: data.details,
        link: data.link,
      });
      console.log(res);
      getScholarshipBookmarks();
    } catch (error) {
      console.log(error);
    }
  };

  const getScholarshipBookmarks = async () => {
    try {
      const res = await userRequest.get("/scholarshipbookmark");
      console.log("scholarship bookmarks", res.data);
      setScholarshipBookmarks(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getScholarshipBookmarks();
  }, []);

  const deleteScholarshipBookmark = async (event, id) => {
    console.log("delete", event);
    console.log(id);
    event.preventDefault();

    try {
      const res = await userRequest.delete("/scholarshipbookmark/" + id);
      console.log(res);
      getScholarshipBookmarks();
    } catch (error) {
      console.log(error);
    }
  };

  function BookmarkChecker(Id, elem) {
    //console.log(coachBookmarks)
    // const filteredResult = coachBookmarks.filter((item) => {
    //   return (item.coachId.indexOf(coachId) > 0);

    //});
    const isObjectPresent = scholarshipBookmarks.find(
      (o) => o.scholarshipId === Id
    );
    if (isObjectPresent) {
      //console.log(isObjectPresent)
      return (
        <BookmarkIcon onClick={(e) => deleteScholarshipBookmark(e, elem.id)} />
      );
    } else {
      return (
        <BookmarkBorderIcon
          onClick={(e) => handleScholarshipBookmark(e, elem)}
        />
      );
    }
  }

  const getCommunities = async () => {
    try {
      const res = await axios.get(
        `${BASE_Server_URL}community?page=${0}&limit=3`
      );
      console.log(res);

      setCommunities(res.data.results);
    } catch (error) {}
  };
  useEffect(() => {
    getCommunities();
  }, []);

  function BookmarkCheckerC(Id, elem) {
    //console.log(coachBookmarks)
    // const filteredResult = coachBookmarks.filter((item) => {
    //   return (item.coachId.indexOf(coachId) > 0);

    //});
    const isObjectPresent = communityBookmarks.find(
      (o) => o.communityId === Id
    );
    if (isObjectPresent) {
      //console.log(isObjectPresent)
      return (
        <BookmarkIcon
          disabled={true}
          //onClick={ (e)=>deleteCommunityBookmark(e, elem.id)}
        />
      );
    } else {
      return (
        <BookmarkBorderIcon
          disabled={true}
          // onClick={ (e)=>handleCommunityBookmark(e, elem)}
        />
      );
    }
  }

  const handleCommunityBookmark = async (event, data) => {
    console.log(data);
    event.preventDefault();
    try {
      const res = await userRequest.post("/communitybookmark", {
        communityId: data.id,
        name: data.name,
        about: data.about,
        img: data.img,
        noOfMembers: data.noOfMembers,
      });
      console.log(res);
      getCommunityBookmarks();
    } catch (error) {
      console.log(error);
    }
  };

  const getCommunityBookmarks = async () => {
    try {
      const res = await userRequest.get("/communitybookmark");
      console.log("community bookmarks", res.data);
      setCommunityBookmarks(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getCommunityBookmarks();
  }, []);

  const deleteCommunityBookmark = async (event, id) => {
    console.log("delete", event);
    console.log(id);
    event.preventDefault();

    try {
      const res = await userRequest.delete("/communitybookmark/" + id);
      console.log(res);
      getCommunityBookmarks();
    } catch (error) {
      console.log(error);
    }
  };

  function getSubstringForLogo(string, char1, char2) {
    //console.log(string.split(".").length - 1)

    if (string.split(".").length - 1 > 1) {
      const first = string.indexOf(char1) + 1;
      const link = string.slice(
        string.indexOf(char1) + 1,
        //string.lastIndexOf(char2),
        string.indexOf(char2, first + 1) + 4
      );
      //console.log(link)

      return link.charAt(0).toUpperCase() + link.slice(1);
    } else {
      //console.log(string)
      const firstSlash = string.indexOf("/") + 1;

      const link = string.slice(
        string.indexOf("/") + 2,
        string.indexOf(char1) + 4
      );
      //console.log(link)

      return link.charAt(0).toUpperCase() + link.slice(1);
      //return ()
    }
    //count number of dot in string
    //get word between second slash
  }

  function formatToLongDate(dateString) {
    const inputDate = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return inputDate.toLocaleDateString("en-US", options);
  }

  function formatNumberToK(number) {
    if (number >= 1000) {
      const roundedNumber = Math.round(number / 100) / 10;
      return roundedNumber + "K";
    }
    return number.toString();
  }

  function toggleDescription(cardIndex) {
    const description = document.getElementById(`faqDescription${cardIndex}`);
    description.style.display =
      description.style.display === "none" || description.style.display === ""
        ? "block"
        : "none";
  }

  return (
    <>
      <div className="homepage">
        <div className="banner">
          <div className="bnner-backgrround">
            <div className="graduate">
              <div className="graduate-inner">
                <div className="frame-wrapper">
                  <div className="frame-container">
                    <div className="banner-image-choice-parent">
                      <img
                        className="banner-image-choice"
                        alt=""
                        src="/banner-image-choice@2x.png"
                      />
                      <div className="student-testimony">
                        <div className="student-name">{`Chelsea C. `}</div>
                        <div className="student-quote">
                          “As a full time student with a part time job, Scholist
                          app reduced the time spent searching for scholarships
                          on different websites.”
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="applying">
              <div className="frame-parent">
                <div className="frame-div">
                  <div className="applying-to-scholarships-made-parent">
                    <b className="applying-to-scholarships">{`Applying to scholarships made easy. `}</b>
                    <div className="discover-the-right-scholarship-wrapper">
                      <div className="discover-the-right">
                        Discover the right scholarships for you
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="buttonxxlarge-wrapper"
                  onClick={onFrameButtonClick}
                >
                  <div className="buttonxxlarge">
                    <div className="sign-up">Sign up</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="how-it-works">
          <div className="homesection-header">
            <b className="section-text">How It Works</b>
          </div>
        </div> */}
        <div className="partners">
          <div className="partnership-strip">
            <div className="see-what-others-are-saying-parent">
              <div className="see-what-others">{`See what others are saying: `}</div>
              <img
                className="cgi-stacked-lockup-blue1-icon"
                alt=""
                src="/414388813cgistackedlockupblue1@2x.png"
              />
              <img
                className="dbf43de48b00044eb5af-icon"
                alt=""
                src="/6102dbf43de48b00044eb5af@2x.png"
              />
              <img
                className="future-founders-color-logo-icon"
                alt=""
                src="/future-founders--color-logo@2x.png"
              />
              <img
                className="verizon-logo-1-icon"
                alt=""
                src="/verizonlogo-1@2x.png"
              />
              <img className="visa-logo-icon" alt="" src="/visa-logo@2x.png" />
            </div>
          </div>
        </div>
        <div className="success-stories">
          <div className="homepageHeaderSuccess">
            <div className="homepageHeaderSuccessBox">
              <div className="lineAboveSuccess"></div>
            </div>

            <b className="section-text2">How It Works?</b>
          </div>
        </div>
        <div className="home-section-how-it-works">
          {/* <div className="how-it-works1">
            <div className="homesection-header1" data-animate-on-scroll>
              <b className="section-text1">How It Works</b>
            </div>
          </div> */}

          <div className="personalized-matches">
            <div className="homehow-it-workssection-desc">
              <b className="how-it-works2">PERSONALIZED MATCHES</b>
              <div className="details">
                <div className="details1">{`Complete your profile to receive personalized Scholarship matches or search through our extensive database of scholarships `}</div>
                <button
                  className="link-to-page"
                  onClick={onLinkToPageClick}
                >{`SEE SCHOLARSHIPS >`}</button>
              </div>
            </div>
            <img
              className="homehow-it-worksimg"
              alt=""
              src="/homehow-it-worksimg@2x.png"
            />
          </div>
          <div className="community-and-support">
            <div className="homehow-it-workssection-desc-parent">
              <div className="homehow-it-workssection-desc">
                <b className="how-it-works3">COMMUNITY AND SUPPORT</b>
                <div className="details">
                  <div className="details1">{`Access a full community of your peers to discuss frustrations and wins along the way.  Hear from other students just like you. `}</div>

                  <Tooltip title="Coming Soon" arrow>
                    <span>
                      <button
                        className="link-to-page"
                        onClick={onLinkToPage1Click}
                        disabled={true}
                      >{`SEE COMMUNITIES >`}</button>
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className="homehow-it-workssection-desc">
                <b className="how-it-works4">Coaching and Mentors</b>
                <div className="details">
                  <div className="details1">{`Scholist offers 1:1 consultations with scholarship coaches. Find a coach to help with anything from FAFSA assitance to Essay writing. `}</div>
                  <button
                    className="link-to-page"
                    onClick={onLinkToPage2Click}
                  >{`SEE SERVICES >`}</button>
                </div>
              </div>
            </div>
            <img
              className="homehow-it-worksimg"
              alt=""
              src="/homehow-it-worksimg1@2x.png"
            />
          </div>
          <div className="community-and-support">
            <div className="homehow-it-workssection-desc">
              <b className="how-it-works3">Never Miss A Deadline</b>
              <div className="details">
                <div className="details1">{`Save scholarships, keep track of deadlines, and set reminders to keep you on track, right inside your own personal dashboard.   `}</div>
                <button
                  className="link-to-page"
                  onClick={onLinkToPage3Click}
                >{`SIGN UP NOW TO GET STARTED >`}</button>
              </div>
            </div>
            <div className="homehow-it-worksimg1">
              <img className="photo-icon" alt="" src="/photo@2x.png" />
            </div>
          </div>
        </div>

        <div className="scholarship-section">
          <div className="header">
            <div className="scholarships">
              <b className="scholarships1">Scholarships</b>
            </div>
          </div>
          <div className="communities-section">
            <div className="scholarship-cards">
              {scholarshipLoading ? (
                <CircularProgress />
              ) : (
                scholarships.slice(0, 3).map((elem) => (
                  <div className="cardscholarshipslarge-home">
                    <div className="cardscholarshipsbackgroundl-wrapper">
                      <div className="cardscholarshipsbackgroundl">
                        <img
                          className="homscholimg"
                          alt=""
                          // src="/image-24@2x.png"
                          src={
                            "https://logo.clearbit.com/" +
                            getSubstringForLogo(elem.link, ".", ".") +
                            "/"
                          }
                        />
                      </div>
                    </div>
                    <div className="community-title-parent">
                      <div className="community-title">
                        <Link
                          className="link"
                          to={
                            user
                              ? `/scholarships-description1/${elem.id}`
                              : `/login`
                          }
                          style={{ color: "black" }}
                        >
                          {elem.name}
                        </Link>
                      </div>
                      {/* <img
                      className="bookmark-disabled-icon"
                      alt=""
                      src="/bookmark-disabled.svg"
                    /> */}
                      {BookmarkChecker(elem.id, elem)}
                    </div>
                    <div className="members-wrapper">
                      <div className="members">
                        Deadline: {formatToLongDate(elem.deadline)}
                      </div>
                    </div>
                    <div className="description-wrapper">
                      <div className="description">
                        {elem.details.split(" ").slice(0, 16).join(" ")}
                      </div>
                    </div>
                    <div className="buttoncardscholarshipslarge-wrapper">
                      <div className="buttoncardscholarshipslarge">
                        <Link
                          className="link"
                          to={
                            userd
                              ? `/scholarships-description1/${elem.id}`
                              : `/Signup`
                          }
                        >
                          <button
                            className="buttonlarge"
                            //onClick={onButtonlargeClick}
                          >
                            <img
                              className="button-text-icon"
                              alt=""
                              src="/button-text.svg"
                            />
                          </button>
                        </Link>

                        <button
                          className="buttonlarge1"
                          onClick={(e) => handleApplyRedirect(e, elem.link)}
                        >
                          <img
                            className="button-text-icon1"
                            alt=""
                            src="/button-text1.svg"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
            <button
              className="see-more"
              onClick={onSeeMoreClick}
            >{`See More >`}</button>
          </div>
        </div>

        <div className="scholarship-section">
          <div className="header">
            <div className="scholarships">
              <b className="scholarships1">Communities</b>
            </div>
          </div>
          <div className="communities-section">
            <div className="scholarship-cards">
              {communities.map((elem) => (
                <div className="cardscholarshipslarge">
                  <div className="cardcommunitybackgroundlarg">
                    <img className="background-icon" alt="" src={elem.img} />
                  </div>
                  <div className="community-title-parent">
                    <div className="community-title">{elem.name}</div>

                    {/* <img
                       className="bookmark-disabled-icon"
                       alt=""
                       src="/bookmark-disabled.svg"
                     /> */}

                    {BookmarkCheckerC(elem.id, elem)}
                  </div>
                  <div className="members-wrapper">
                    <div className="members">
                      {formatNumberToK(elem.noOfMembers)} Members
                    </div>
                  </div>
                  <div className="description-wrapper">
                    <div className="description">
                      {/* {elem.about.split(' ').slice(0,7).join(' ')} */}
                      {elem.about}
                    </div>
                  </div>
                  <div className="buttoncardscholarshipslarge-wrapper">
                    <div className="buttoncardscholarshipslarge">
                      <Tooltip title="Coming Soon" arrow>
                        <span>
                          <button
                            className="buttonlarge"
                            onClick={onButtonlarge6Click}
                            disabled={true}
                          >
                            <img
                              className="button-text-icon"
                              alt=""
                              src="/button-text.svg"
                            />
                          </button>
                        </span>
                      </Tooltip>

                      <Tooltip title="Coming Soon" arrow>
                        <span>
                          <div className="buttonlarge7" disabled={true}>
                            <div className="nav-active">
                              <a className="communitiybutton">Join</a>
                            </div>
                          </div>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <Tooltip title="Coming Soon" arrow>
              <span>
                <button
                  className="see-more"
                  disabled={true}
                  onClick={onSeeMore1Click}
                >{`See More >`}</button>
              </span>
            </Tooltip>
          </div>
        </div>
        {/* <div className="divider1">
          <img
            className="homesection-divider-icon"
            alt=""
            src="/homesection-divider1.svg"
          />
        </div> */}
        <div className="success-stories">
          <div className="homepageHeaderSuccess">
            <div className="homepageHeaderSuccessBox">
              <div className="lineAboveSuccess"></div>
            </div>

            <b className="section-text2">Success Stories</b>
          </div>
        </div>

        <div className="testimonials">
          <div className="cardtestimonial-parent">
            <div className="cardtestimonial">
              <img
                className="cardtestimonialbackground-icon"
                alt=""
                src="/cardtestimonialbackground.svg"
              />
              <div className="cardtestimonialdescription">
                <div className="amount-awarded">
                  <div className="nav-active">Awarded $20,000</div>
                  <div className="line-under-amount" />
                </div>
                <div className="name-and-major">
                  <div className="name-and-major-container">
                    <p className="joshua-p">Kim Nguyen</p>
                    <p className="graphic-design-major">Graphic Design Major</p>
                  </div>
                </div>
                <div className="testimony">
                  <div className="testimony1">
                    “I felt more knowledgeable about scholarships after
                    consultation with my coach and I absolutely crushed it in
                    scholarships.”
                  </div>
                </div>
              </div>
            </div>
            <div className="cardtestimonial1">
              <img
                className="cardtestimonialbackground-icon"
                alt=""
                src="/cardtestimonialbackground1.svg"
              />
              <div className="cardtestimonialdescription">
                <div className="amount-awarded">
                  <div className="nav-active">Awarded $15,000</div>
                  <div className="line-under-amount" />
                </div>
                <div className="name-and-major">
                  <div className="name-and-major-container">
                    <p className="joshua-p">Opeoluwa Adenuga</p>
                    <p className="graphic-design-major">
                      Chemical Engingeering Major
                    </p>
                  </div>
                </div>
                <div className="testimony">
                  <div className="testimony1">
                    “I was able to receive 3 scholarships after using scholist
                    app highly recommend to anyone looking for scholarships and
                    don’t know where to start.”
                  </div>
                </div>
              </div>
            </div>
            <div className="cardtestimonial1">
              <img
                className="cardtestimonialbackground-icon"
                alt=""
                src="/cardtestimonialbackground2.svg"
              />
              <div className="cardtestimonialdescription">
                <div className="amount-awarded">
                  <div className="nav-active">Awarded $2,000</div>
                  <div className="line-under-amount" />
                </div>
                <div className="name-and-major">
                  <div className="name-and-major-container">
                    <p className="joshua-p">Anabelle willson</p>
                    <p className="graphic-design-major">Architecture Major</p>
                  </div>
                </div>
                <div className="testimony">
                  <div className="testimony1">
                    “I have been trying hard to get scholarships but after
                    getting consultation through scholist app I was able to
                    receive local scholarships that I qualify for.”
                  </div>
                </div>
              </div>
            </div>
            <div className="cardtestimonial1">
              <img
                className="cardtestimonialbackground-icon"
                alt=""
                src="/cardtestimonialbackground3.svg"
              />
              <div className="cardtestimonialdescription">
                <div className="amount-awarded">
                  <div className="nav-active">Awarded $5,000</div>
                  <div className="line-under-amount" />
                </div>
                <div className="name-and-major">
                  <div className="name-and-major-container">
                    <p className="joshua-p">{`Samira S. `}</p>
                    <p className="graphic-design-major">
                      English Literature Major
                    </p>
                  </div>
                </div>
                <div className="testimony">
                  <div className="testimony1">
                    “Scholist coaching helped me secure multiple scholarships. I
                    highly recommend this platform to any student looking to
                    fund their education”
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="faq">
          <b className="faqtext">Frequently Ask Questions</b>

          <div className="cardsframefaq">
            {/* <div className="faqcard">
            <div className="faqcardbox">
              <div className="faqcardbox1">
                <div className="faqcardicon" />
                <div className="faqcardheader"  onclick="toggleDescription(1)">
                  Can I find Scholarships for masters students?
                </div>
              </div>
              <div className="faqcarddescriptioncardbox2" id="faqDescription">{`It takes 2-3 weeks to get your first blog post ready. That includes the in-depth research & creation of your monthly content marketing strategy that we MUST do before writing your first blog post.`}</div>
            </div>
          </div> */}
            <Faq
              question="Can I find Scholarships for Masters Students?"
              answer="Yes Scholist App provides scholarships for high school seniors, Undergraduate, graduate and ph.d students."
            />

            <Faq
              question="Do I need good grades or a high GPA to get a scholarship?"
              answer="No, we have thousands of scholarships for every GPA category. Don't let that stop you from searching on Scholist App."
            />

            {/* <div className="faqcard1">
            <div className="faqcardbox11">
              <div className="faqcardplusicon">
                <div className="faqcardplusicon-child" />
                <div className="faqcardplusicon-item" />
              </div>
              <div className="faqcardheader1">
                Can I find Scholarships for masters students?
              </div>
            </div>
          </div> */}
          </div>

          <div className="cardsframefaq">
            {/* <div className="faqcard">
            <div className="faqcardbox">
              <div className="faqcardbox1">
                <div className="faqcardicon" />
                <div className="faqcardheader"  onclick="toggleDescription(1)">
                  Can I find Scholarships for masters students?
                </div>
              </div>
              <div className="faqcarddescriptioncardbox2" id="faqDescription">{`It takes 2-3 weeks to get your first blog post ready. That includes the in-depth research & creation of your monthly content marketing strategy that we MUST do before writing your first blog post.`}</div>
            </div>
          </div> */}
            <Faq
              question="How does the scholarship matching process work?"
              answer="When  you signup fpr Schollist App and complete your online profile, data is collected that is used tomatch you to scholarships we have in  our database."
            />

            <Faq
              question="Are there scholarships for international students?"
              answer="yes. Absolutely! international students can get matched with thousands of scholarship oppurtunities on scholist. We have scholarships for everyone!"
            />

            {/* <div className="faqcard1">
            <div className="faqcardbox11">
              <div className="faqcardplusicon">
                <div className="faqcardplusicon-child" />
                <div className="faqcardplusicon-item" />
              </div>
              <div className="faqcardheader1">
                Can I find Scholarships for masters students?
              </div>
            </div>
          </div> */}
          </div>

          <div className="cardsframefaq">
            {/* <div className="faqcard">
            <div className="faqcardbox">
              <div className="faqcardbox1">
                <div className="faqcardicon" />
                <div className="faqcardheader"  onclick="toggleDescription(1)">
                  Can I find Scholarships for masters students?
                </div>
              </div>
              <div className="faqcarddescriptioncardbox2" id="faqDescription">{`It takes 2-3 weeks to get your first blog post ready. That includes the in-depth research & creation of your monthly content marketing strategy that we MUST do before writing your first blog post.`}</div>
            </div>
          </div> */}
            <Faq
              question="Can I use Scholist app to search for scholarships specific to my intented field of study or career goals?"
              answer="Absolutely! Sholist app allows you to filter scholarship opportunities based on various criteria, including your intented field of study or career goals."
            />

            <Faq
              question="Can returning/adult/nontraditional students apply?"
              answer="For sure! There are numerous scholarships for nontraditional students to apply to on our database"
            />

            {/* <div className="faqcard1">
            <div className="faqcardbox11">
              <div className="faqcardplusicon">
                <div className="faqcardplusicon-child" />
                <div className="faqcardplusicon-item" />
              </div>
              <div className="faqcardheader1">
                Can I find Scholarships for masters students?
              </div>
            </div>
          </div> */}
          </div>
        </div>
        {/* <div className="footer">
          <div className="footer-grid-row">
            <div className="footer-menu">
              <img className="footerlogo-icon" alt="" src="/footerlogo.svg" />
              <div className="footer-links">
                <div className="footerlinknav">
                  <div className="nav-link">info@example.com</div>
                </div>
                <div className="footerlinknav">
                  <div className="nav-link">+1 234 567 9830</div>
                </div>
                <div className="footerlinknav">
                  <div className="nav-link">Address</div>
                </div>
              </div>
              <div className="icon-wrapper">
                <img
                  className="footericoninstagram"
                  alt=""
                  src="/footericoninstagram.svg"
                />
                <img
                  className="footericonfacbook"
                  alt=""
                  src="/footericonfacbook.svg"
                />
                <img
                  className="footericontwitter"
                  alt=""
                  src="/footericontwitter.svg"
                />
                <img
                  className="footericonfacbook"
                  alt=""
                  src="/footericonlinkedin.svg"
                />
                <img
                  className="footericonyoutube"
                  alt=""
                  src="/footericonyoutube.svg"
                />
              </div>
            </div>
            <div className="footer-menu-parent">
              <div className="footer-menu1">
                <div className="students">Students</div>
                <div className="footer-links">
                  <div className="main-footer-link">
                    <div className="footer-link">How it works</div>
                  </div>
                  <div className="main-footer-link">
                    <div className="footer-link">Scholarships</div>
                  </div>
                  <div
                    className="main-footer-link2"
                    onClick={onMainFooterLink2Click}
                  >
                    <div className="footer-link">Community</div>
                  </div>
                  <div className="main-footer-link">
                    <div className="footer-link">Services</div>
                  </div>
                </div>
              </div>
              <div className="footer-menu1">
                <div className="students">Coaches</div>
                <div className="footer-links">
                  <div className="main-footer-link">
                    <div className="footer-link">How it works</div>
                  </div>
                  <div className="main-footer-link">
                    <div className="footer-link">Blog</div>
                  </div>
                  <div className="main-footer-link">
                    <div className="footer-link">Mission</div>
                  </div>
                  <div className="main-footer-link">
                    <div className="footer-link">Footer Link</div>
                  </div>
                </div>
              </div>
              <div className="footer-menu1">
                <div className="students">Company</div>
                <div className="footer-links">
                  <div className="main-footer-link">
                    <div className="footer-link">About Us</div>
                  </div>
                  <div className="main-footer-link">
                    <div className="footer-link">Partners</div>
                  </div>
                  <div className="main-footer-link">
                    <div className="footer-link">Careers</div>
                  </div>
                  <div className="main-footer-link">
                    <div className="footer-link">Contact</div>
                  </div>
                </div>
              </div>
              <div className="footer-menu1">
                <div className="students">Join our Email List</div>
                <div className="footer-links4">
                  <div className="main-footer-link12">
                    <div className="footer-link12">
                      Be the first to know about new scholarships, events,
                      workshops and much more!
                    </div>
                  </div>
                  <input
                    className="subscribe-email-text-box"
                    type="text"
                    placeholder="Email"
                  />
                  <div className="buttonlarge12">
                    <div className="button" />
                    <div className="button-text2">Sign Up</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-legal">
            <div className="scholist-all-rights">
              © 2022 Scholist. All rights reserved.
            </div>
            <div className="scholist-all-rights">Disclaimer Cookies</div>
          </div>
        </div> */}
      </div>
      {isNavigationDrawerOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeNavigationDrawer}
        >
          <NavigationDrawer onClose={closeNavigationDrawer} />
        </PortalDrawer>
      )}
    </>
  );
};

export default HOMEPAGE;
