import React, { useCallback, useState } from "react";
import "./Support.css";
import TicketSubmitted from "../components/TicketSubmitted";
import PortalPopup from "../components/PortalPopup";
import axios from "axios";
import emailjs from "emailjs-com";
import { BASE_Server_URL } from "../requestMethods";
const Support = () => {
  const [isTicketSubmittedPopupOpen, setTicketSubmittedPopupOpen] =
    useState(false);

  const openTicketSubmittedPopup = useCallback(() => {
    setTicketSubmittedPopupOpen(true);
  }, []);

  const closeTicketSubmittedPopup = useCallback(() => {
    setTicketSubmittedPopupOpen(false);
  }, []);

  const [supportType, setSupportType] = useState("");
  const [supportQuestion, setSupportQuestion] = useState("");
  const [selectedSupport, setSelectedSupport] = useState(null);
  const [hasAttemptedSupport, setHasAttemptedSupport] = useState(false);

  const [ticketNo, setTicketNo] = useState(null);

  const handleSupportType = (event) => {
    setSupportType(event.target.value);

    if (event.target.value == "") {
      setSelectedSupport(null);
    } else {
      setSelectedSupport(event.target.value);

      // console.log('service mhn4edkmw' , services)
    }
  };

  const handleSupportQuestion = (event) => {
    if (event.target.value == "") {
      setSupportQuestion(null);
    } else {
      setSupportQuestion(event.target.value);

      // console.log('service mhn4edkmw' , services)
    }
  };

  //   async function submitSupport() {
  //     try {
  //         const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
  //         const currentUser = user && JSON.parse(user).currentUser;
  //         const TOKEN = currentUser?.tokens.access.token;

  //         const client = axios.create({
  //           baseURL: "http://localhost:3000/v1/", // Use an environment variable
  //           headers: { Authorization: `Bearer ${TOKEN}` },
  //         });

  //         const res = await client.post('/support', {
  //             type: supportType,
  //             question: supportQuestion

  //         });

  //         setSupportType('')
  //         setSupportQuestion(' ')

  //         console.log('support posted',res);

  //         // Handle success or show a success message to the user
  //       } catch (error) {
  //         // Handle specific error types and show appropriate messages
  //         console.log(error);
  //       }
  //   }

  //   const onSupportNowClick = () => {
  //     if (supportType == '') {
  //       // If serviceType is empty, set hasAttemptedBooking to true
  //       setHasAttemptedSupport(true);
  //       console.log('Select support type');
  //     } else {
  //       console.log('Navigate to coach availability');
  //       // ... (rest of your logic for navigation)
  //      // navigate("/coachavailability");

  //      submitSupport()

  //      openTicketSubmittedPopup()
  //     }
  //   };
  const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
  const currentUser = user && JSON.parse(user).currentUser;

  console.log("current usert", currentUser.user);

  async function submitSupport() {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL, // Use an environment variable
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      const res = await client.post("/support", {
        type: supportType,
        question: supportQuestion,
      });

      // Reset form fields after successful submission
      setSupportType("");
      setSupportQuestion("");

      setTicketNo(res.data.ticketNo);
      console.log("Support posted:", res);
      // Handle success or show a success message to the user

      const templateParams = {
        to_name: "Scholist",
        from_name: currentUser.user.name,
        message: `Support Type: ${supportType}\nQuestion: ${supportQuestion}`,
        to_email: "support@scholistapp.com",
      };

      emailjs
        .send(
          "service_vz0mth2",
          //"service_40zofqo",
          "template_u7ko3so",
          //"template_kvutiac",
          templateParams,
          "ezjGhcddeSjOgFGjj"
          //"Z71scS1wOVMi8nG2V"
        )
        .then(
          (result) => {
            console.log("Email successfully sent!", result.text);
          },
          (error) => {
            console.error("Error sending email:", error);
          }
        );
    } catch (error) {
      // Handle specific error types and show appropriate messages
      console.error("Error submitting support:", error);
      // You might want to set an error state here to display a message to the user
    }
  }

  const onSupportNowClick = async () => {
    try {
      if (supportType === "" || supportQuestion === "") {
        // If supportType or supportQuestion is empty, set hasAttemptedSupport to true
        setHasAttemptedSupport(true);
        console.log("Please select support type and provide a question.");
      } else {
        console.log("Navigating to coach availability...");
        // ... (rest of your logic for navigation)
        // navigate("/coachavailability");

        await submitSupport(); // Await the asynchronous submission

        setHasAttemptedSupport(false);

        openTicketSubmittedPopup();
      }
    } catch (error) {
      console.error("Error handling support click:", error);
    }
  };

  return (
    <>
      <div className="support">
        <div className="supportsecondbox">
          <div className="supporthelpheaderbox">
            <div className="supporthelpheader">{`Support & Help`}</div>
          </div>
          <div className="supporthelpbodybox">
            <div className="supporthelpbodybox2">
              <div className="supporthelpbox3">
                <div className="supporthelpheaderbox">
                  <div className="supporthselectboxquestiontext">
                    What do you need help with?
                  </div>
                </div>
                <select
                  className="supporthelpboxselectbox"
                  style={{
                    border:
                      hasAttemptedSupport && supportType === ""
                        ? "2px solid red"
                        : "1px solid #ced4da",
                  }}
                  value={supportType}
                  onChange={(e) => handleSupportType(e)}
                >
                  <option value="">Select Support Type</option>
                  <option value="scholarship">Scholarship</option>
                  <option value="coach">Coach</option>
                  <option value="account">Account</option>
                  <option value="others">Others</option>
                </select>
              </div>
              <div className="supporthelpboxquestionbox">
                <div className="supporthelpheaderbox">
                  <div className="supporthselectboxquestiontext">
                    What’s your question?
                  </div>
                </div>
                <textarea
                  className="supporthselectboxquestion2"
                  onChange={handleSupportQuestion}
                  value={supportQuestion}
                  //   placeholder="nmnmdnmccnbxsnxkcndxmzm mc sxnmznmsc m wmdx am mw cmw nsnx z s nednsxndcnxsnxmdcxnnc bsxznsxn zwnsx nwcxs n csxnn ncsx sx nncx "
                />
              </div>
            </div>
            <div className="supporthselectboxquestionbutto">
              <button
                className="supporthselectboxquestionbutto1"
                onClick={onSupportNowClick}
              >
                <div className="supporthselectboxquestionbutto2">Submit</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {isTicketSubmittedPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTicketSubmittedPopup}
        >
          <TicketSubmitted
            onClose={closeTicketSubmittedPopup}
            ticketNo={ticketNo}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default Support;
