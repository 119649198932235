import React from "react";
import "./Footer.css";
import NewsletterForm from "./NewsletterForm";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer1">
        <div className="footer-grid-row">
          <div className="footer-menu">
            <img className="footerlogo-icon" alt="" src="/footerlogo.svg" />
            <div className="footer-links">
              <div className="footerlinknav">
                <span className="nav-link">info@scholistapp.com</span>
              </div>
              {/*  <div className="footerlinknav">
                <span className="nav-link">+1 234 567 9830</span>
              </div>
              <div className="footerlinknav">
                <span className="nav-link">Address</span>
              </div> */}
            </div>
            <div className="icon-wrapper">
              <img
                className="footericoninstagram"
                alt=""
                src="/footericoninstagram.svg"
              />
              <img
                className="footericonfacbook"
                alt=""
                src="/footericonfacbook.svg"
              />
              <img
                className="footericontwitter"
                alt=""
                src="/footericontwitter.svg"
              />
              <img
                className="footericonfacbook"
                alt=""
                src="/footericonlinkedin.svg"
              />
              <img
                className="footericonyoutube"
                alt=""
                src="/footericonyoutube.svg"
              />
            </div>
          </div>
          <div className="footer-menu-parent">
            <div className="footer-menu1">
              <span className="students">Students</span>
              <div className="footer-links">
                <div className="main-footer-link">
                  <span className="footer-link">How it works</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">Scholarships</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">Community</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">Services</span>
                </div>
              </div>
            </div>
            <div className="footer-menu2">
              <span className="students">Coaches</span>
              <div className="footer-links">
                <div className="main-footer-link">
                  <span className="footer-link">How it works</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">Blog</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">Mission</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">Footer Link</span>
                </div>
              </div>
            </div>
            <div className="footer-menu2">
              <span className="students">Company</span>
              <div className="footer-links">
                <div className="main-footer-link">
                  <span className="footer-link">About Us</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">Partners</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">Careers</span>
                </div>
                <div className="main-footer-link">
                  <span className="footer-link">Contact</span>
                </div>
              </div>
            </div>
            <NewsletterForm />
          </div>
        </div>
        <div className="footer-legal">
          <span className="scholist-all-rights">
            © 2022 Scholist. All rights reserved.
          </span>
          <div className="disclaimer-cookies">Disclaimer Cookies</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
