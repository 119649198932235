import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Welcome.css";
const Welcome = () => {
  const navigate = useNavigate();

  const onButtonxxlargeClick = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  return (
    <div className="welcome2">
      {/* <div className="nav-bar3">
        <div className="headertop-navsecondary3">
          <div className="right-side-secondary-nav3">
            <div className="headerdrop-downlang3">
              <img className="language-icon3" alt="" src="/language-icon.svg" />
              <div className="language3">Language</div>
              <img className="down-icon3" alt="" src="/down-icon1.svg" />
            </div>
          </div>
          <div className="left-side-secondary-nav3">
            <div className="headertop-navlink-parent1">
              <div className="headertop-navlink6">
                <div className="language3">Students</div>
                <div className="line-under-link6" />
              </div>
              <div className="headertop-navlink6">
                <div className="language3">Coaches</div>
                <div className="line-under-link7" />
              </div>
            </div>
          </div>
        </div>
        <div className="headertop-navprimary3">
          <div className="left-side-top-nav-parent1">
            <div className="left-side-top-nav17">
              <div className="headerlogo-parent1">
                <img
                  className="headerlogo-icon17"
                  alt=""
                  src="/headerlogo5.svg"
                />
                <div className="top-nav-left17">
                  <div className="headernav-link-parent1">
                    <a className="headernav-link85">
                      <div className="nav-active17">Home</div>
                    </a>
                    <a className="headernav-link86">
                      <div className="nav-active17">Services</div>
                    </a>
                    <a className="headernav-link86">
                      <div className="nav-active17">Scholarships</div>
                    </a>
                    <a className="headernav-link86">
                      <div className="nav-active17">{`Community `}</div>
                    </a>
                    <div className="headernav-link89">
                      <div className="nav-active17">Resources</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side-top-nav17">
              <button className="menu17">
                <img className="vector-icon104" alt="" />
                <img className="vector-icon105" alt="" src="/vector36.svg" />
              </button>
              <div className="register3">
                <button className="buttonmedium8">
                  <div className="button-text91">Login</div>
                </button>
                <button className="buttonmedium9">
                  <div className="button-text92">Sign Up</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="body18">
        <div className="welcome3">
          <div className="header-image1">
            <img className="footerlogo-icon15" alt="" src="/footerlogo10.svg" />
          </div>
          <div className="welcome4">
            <b className="welcome-to-scholist">Welcome to Scholist!</b>
          </div>
          <div className="continue">
            <div className="would-you-like-container">
              <p className="would-you-like">Would you like to continue</p>
              <p className="would-you-like">completing your profile?</p>
            </div>
          </div>
          <div className="continue-button">
            <button className="buttonxxlarge3" onClick={onButtonxxlargeClick}>
              <div className="continue1">Continue</div>
            </button>
          </div>
          <div className="no-thanks">
            <div className="no-thanks-ill-complete-my-pr-wrapper">
              {/* <div className="no-thanks-ill">
                No thanks, I’ll complete my profile later.
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
