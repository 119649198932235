// import { useState, useCallback } from "react";
// import ScholarshipFilters from "../components/ScholarshipFilters";
// import PortalDrawer from "../components/PortalDrawer";
import "./BasedOnProfile.css";
// const BasedOnProfile = () => {
//   const [isScholarshipFiltersOpen, setScholarshipFiltersOpen] = useState(false);

//   const openScholarshipFilters = useCallback(() => {
//     setScholarshipFiltersOpen(true);
//   }, []);

//   const closeScholarshipFilters = useCallback(() => {
//     setScholarshipFiltersOpen(false);
//   }, []);

//   return (
//     <>
//       <div className="based-on-profile">
//         {/* <div className="navbar7">
//           <div className="info7">
//             <div className="left-side-top-nav8">
//               <img className="headerlogo-icon8" alt="" src="/headerlogo.svg" />
//               <div className="top-nav-left8">
//                 <div className="headernav-link40">
//                   <div className="nav-link-default32">Home</div>
//                 </div>
//                 <div className="headernav-link41">
//                   <div className="nav-link-default32">Scholarships</div>
//                 </div>
//                 <div className="headernav-link42">
//                   <div className="nav-link-default32">Services</div>
//                 </div>
//                 <div className="headernav-link42">
//                   <div className="nav-link-default32">{`Community `}</div>
//                 </div>
//                 <div className="headernav-link42">
//                   <div className="nav-link-default32">Resources</div>
//                 </div>
//               </div>
//             </div>
//             <div className="right-side-top-nav8">
//               <button className="menu8">
//                 <img className="vector-icon52" alt="" />
//                 <img className="vector-icon53" alt="" src="/vector.svg" />
//               </button>
//               <div className="messages-parent5">
//                 <img className="messages-icon7" alt="" src="/messages5.svg" />
//                 <img
//                   className="notifications-icon7"
//                   alt=""
//                   src="/notifications1.svg"
//                 />
//                 <div className="mask-group-parent5">
//                   <img
//                     className="mask-group-icon7"
//                     alt=""
//                     src="/mask-group@2x.png"
//                   />
//                   <img className="vector-icon54" alt="" src="/vector1.svg" />
//                 </div>
//               </div>
//             </div>
//           </div>
//           <img
//             className="scholarships-line-icon7"
//             alt=""
//             src="/scholarships-line.svg"
//           />
//           <img
//             className="services-line-icon7"
//             alt=""
//             src="/services-line.svg"
//           />
//           <img
//             className="community-line-icon7"
//             alt=""
//             src="/community-line.svg"
//           />
//           <img className="resources-icon7" alt="" src="/resources.svg" />
//         </div> */}
//         <div className="search5">
//           <div className="header8">
//             <div className="headertop-navprimarybreadcr4">
//               <div className="page-title19">
//                 <div className="page-title20">Scholarships</div>
//                 <img className="underline-icon12" alt="" src="/underline.svg" />
//               </div>
//               <img className="arrow-icon9" alt="" src="/arrow.svg" />
//               <div className="page-title21">
//                 <div className="based-on-your6">Based on Your Profile</div>
//                 <img className="underline-icon13" alt="" />
//               </div>
//               <img className="arrow-icon10" alt="" src="/arrow1.svg" />
//               <div className="page-title22">
//                 <div className="page-title20">Page Title</div>
//                 <img className="underline-icon13" alt="" />
//               </div>
//             </div>
//             <div className="allpage-header5">
//               <b className="based-on-profile1">Based On Profile</b>
//             </div>
//           </div>
//           <div className="search-and-filter3">
//             <input className="search-bar3" type="text" placeholder="Search" />
//             <div className="filter3">
//               <div className="filters-wrapper3">
//                 <div className="filter-button18">
//                   <div className="button-text56">Category</div>
//                   <div className="circle-118">
//                     <img className="vector-icon55" alt="" src="/vector2.svg" />
//                     <div className="div44">1</div>
//                   </div>
//                   <div className="arrows19">
//                     <img
//                       className="chevrondown-icon18"
//                       alt=""
//                       src="/chevrondown6.svg"
//                     />
//                   </div>
//                 </div>
//                 <div className="filter-button19">
//                   <div className="circle-119">
//                     <img className="vector-icon55" alt="" src="/vector3.svg" />
//                     <div className="div44">1</div>
//                   </div>
//                   <div className="button-text-parent7">
//                     <div className="button-text57">Award Amount</div>
//                     <div className="arrows19">
//                       <img
//                         className="chevrondown-icon18"
//                         alt=""
//                         src="/chevrondown7.svg"
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="filter-button20">
//                   <div className="circle-119">
//                     <img className="vector-icon55" alt="" src="/vector4.svg" />
//                     <div className="div44">1</div>
//                   </div>
//                   <div className="button-text-parent8">
//                     <div className="button-text56">Location</div>
//                     <div className="arrows19">
//                       <img
//                         className="chevrondown-icon18"
//                         alt=""
//                         src="/chevrondown8.svg"
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="filter-button21">
//                   <div className="circle-119">
//                     <img className="vector-icon55" alt="" src="/vector5.svg" />
//                     <div className="div44">1</div>
//                   </div>
//                   <div className="button-text-parent8">
//                     <div className="button-text56">Major</div>
//                     <div className="arrows19">
//                       <img
//                         className="chevrondown-icon18"
//                         alt=""
//                         src="/chevrondown9.svg"
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="filter-button22">
//                   <div className="button-text56">Grade Level</div>
//                   <div className="circle-118">
//                     <img className="vector-icon55" alt="" src="/vector6.svg" />
//                     <div className="div44">1</div>
//                   </div>
//                   <div className="arrows19">
//                     <img
//                       className="chevrondown-icon18"
//                       alt=""
//                       src="/chevrondown10.svg"
//                     />
//                   </div>
//                 </div>
//                 <div className="filter-button23">
//                   <div className="button-text56">Citizenship</div>
//                   <div className="circle-118">
//                     <img className="vector-icon55" alt="" src="/vector7.svg" />
//                     <div className="div44">1</div>
//                   </div>
//                   <div className="arrows19">
//                     <img
//                       className="chevrondown-icon18"
//                       alt=""
//                       src="/chevrondown11.svg"
//                     />
//                   </div>
//                 </div>
//                 <div className="ellipse-button3">
//                   <img className="vector-icon61" alt="" src="/vector8.svg" />
//                 </div>
//               </div>
//             </div>
//             <div className="search-and-filter-inner2">
//               <div className="frame-parent8">
//                 <div
//                   className="frame-wrapper56"
//                   onClick={openScholarshipFilters}
//                 >
//                   <div className="tune-parent1">
//                     <img className="tune-icon3" alt="" src="/tune@2x.png" />
//                     <div className="filters3">Filters</div>
//                   </div>
//                 </div>
//                 <div className="frame-wrapper57">
//                   <div className="tune-parent1">
//                     <img className="vector-icon62" alt="" src="/vector9.svg" />
//                     <div className="filters3">Sort</div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="available3">
//           <div className="body8">
//             <div className="scholarships-available3">
//               785 scholarships available
//             </div>
//             <div className="filter-dropdown-button3">
//               <div className="filter-dropdown-button-inner1">
//                 <div className="sort-by-parent1">
//                   <div className="sort-by3">{`Sort by `}</div>
//                   <select className="frame-select">
//                     <option value="relevance">Relevance</option>
//                     <option value="a-z">A-Z</option>
//                     <option value="deadline">Deadline</option>
//                     <option value="newest">Newest</option>
//                     <option value="awardamount">Award Amount</option>
//                   </select>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="availblespear">
//           <div className="see-more-progress-bar7">
//             <div className="scholarship-available3">
//               785 Scholarship Available
//             </div>
//           </div>
//         </div>
//         <div className="scholarship-body3">
//           <div className="row-2-wrapper5">
//             <div className="card32">
//               <div className="cardscholarshipsbackgroundco32">
//                 <img
//                   className="cardscholarshipsimage-icon32"
//                   alt=""
//                   src="/cardscholarshipsimage@2x.png"
//                 />
//               </div>
//               <b className="title32">“Be Bold” No-Essay Scholarship</b>
//               <div className="organization32">Bold.org</div>
//               <div className="award-amount33">$25,000</div>
//               <div className="deadline31">Deadline: 04/30/2023</div>
//               <img
//                 className="component-2-icon32"
//                 alt=""
//                 src="/component-22.svg"
//               />
//             </div>
//             <div className="card32">
//               <div className="cardscholarshipsbackgroundco33">
//                 <img
//                   className="cardscholarshipsimage-icon32"
//                   alt=""
//                   src="/cardscholarshipsimage1@2x.png"
//                 />
//               </div>
//               <b className="title32">$2,000 Nitro Scholarship Sweepstakes</b>
//               <div className="organization32">Nitro</div>
//               <div className="award-amount33">$2,000</div>
//               <div className="deadline31">Deadline: 12/31/2023</div>
//               <img
//                 className="component-2-icon32"
//                 alt=""
//                 src="/component-22.svg"
//               />
//             </div>
//             <div className="card32">
//               <div className="cardscholarshipsbackgroundco34">
//                 <img
//                   className="cardscholarshipsimage-icon34"
//                   alt=""
//                   src="/cardscholarshipsimage2@2x.png"
//                 />
//               </div>
//               <b className="title32">
//                 $50,000 ScholarshipOwl No Essay Scholarship
//               </b>
//               <div className="organization32">ScholarshipOwl</div>
//               <div className="award-amount33">$50,000</div>
//               <div className="deadline31">Deadline: 06/17/2023</div>
//               <img
//                 className="component-2-icon32"
//                 alt=""
//                 src="/component-22.svg"
//               />
//             </div>
//             <div className="card32">
//               <div className="cardscholarshipsbackgroundco35">
//                 <img
//                   className="cardscholarshipsimage-icon34"
//                   alt=""
//                   src="/cardscholarshipsimage3@2x.png"
//                 />
//               </div>
//               <b className="title32">Coca-Cola Scholars Scholarship</b>
//               <div className="organization32">Coca-Cola</div>
//               <div className="award-amount33">$20,000</div>
//               <div className="deadline31">Deadline: 10/18/2023</div>
//               <img
//                 className="component-2-icon32"
//                 alt=""
//                 src="/component-22.svg"
//               />
//             </div>
//           </div>
//         </div>
//         <div className="see-more5">
//           <div className="see-more-progress-bar8">
//             <div className="div50">24 / 785</div>
//             <div className="buttonlarge26">
//               <div className="button34" />
//               <div className="button-text62">See More</div>
//             </div>
//             <img
//               className="progress-bar-icon4"
//               alt=""
//               src="/progress-bar.svg"
//             />
//           </div>
//         </div>
//         <div className="footer8">
//           <div className="footer-grid-row8">
//             <div className="footer-menu40">
//               <img className="footerlogo-icon8" alt="" src="/footerlogo6.svg" />
//               <div className="footer-links40">
//                 <div className="footerlinknav24">
//                   <div className="nav-link24">info@example.com</div>
//                 </div>
//                 <div className="footerlinknav24">
//                   <div className="nav-link24">+1 234 567 9830</div>
//                 </div>
//                 <div className="footerlinknav24">
//                   <div className="nav-link24">Address</div>
//                 </div>
//               </div>
//               <div className="icon-wrapper8">
//                 <img
//                   className="footericoninstagram8"
//                   alt=""
//                   src="/footericoninstagram.svg"
//                 />
//                 <img
//                   className="footericonfacbook8"
//                   alt=""
//                   src="/footericonfacbook.svg"
//                 />
//                 <img
//                   className="footericontwitter8"
//                   alt=""
//                   src="/footericontwitter2.svg"
//                 />
//                 <img
//                   className="footericonfacbook8"
//                   alt=""
//                   src="/footericonlinkedin.svg"
//                 />
//                 <img
//                   className="footericonyoutube8"
//                   alt=""
//                   src="/footericonyoutube2.svg"
//                 />
//               </div>
//             </div>
//             <div className="footer-menu-parent6">
//               <div className="footer-menu41">
//                 <div className="students8">Students</div>
//                 <div className="footer-links40">
//                   <div className="main-footer-link104">
//                     <div className="footer-link104">How it works</div>
//                   </div>
//                   <div className="main-footer-link104">
//                     <div className="footer-link104">Scholarships</div>
//                   </div>
//                   <div className="main-footer-link104">
//                     <div className="footer-link104">Community</div>
//                   </div>
//                   <div className="main-footer-link104">
//                     <div className="footer-link104">Services</div>
//                   </div>
//                 </div>
//               </div>
//               <div className="footer-menu41">
//                 <div className="students8">Coaches</div>
//                 <div className="footer-links40">
//                   <div className="main-footer-link104">
//                     <div className="footer-link104">How it works</div>
//                   </div>
//                   <div className="main-footer-link104">
//                     <div className="footer-link104">Blog</div>
//                   </div>
//                   <div className="main-footer-link104">
//                     <div className="footer-link104">Mission</div>
//                   </div>
//                   <div className="main-footer-link104">
//                     <div className="footer-link104">Footer Link</div>
//                   </div>
//                 </div>
//               </div>
//               <div className="footer-menu41">
//                 <div className="students8">Company</div>
//                 <div className="footer-links40">
//                   <div className="main-footer-link104">
//                     <div className="footer-link104">About Us</div>
//                   </div>
//                   <div className="main-footer-link104">
//                     <div className="footer-link104">Partners</div>
//                   </div>
//                   <div className="main-footer-link104">
//                     <div className="footer-link104">Careers</div>
//                   </div>
//                   <div className="main-footer-link104">
//                     <div className="footer-link104">Contact</div>
//                   </div>
//                 </div>
//               </div>
//               <div className="footer-menu41">
//                 <div className="students8">Join our Email List</div>
//                 <div className="footer-links44">
//                   <div className="main-footer-link116">
//                     <div className="footer-link116">
//                       Be the first to know about new scholarships, events,
//                       workshops and much more!
//                     </div>
//                   </div>
//                   <div className="subscribe-email-text-box8">
//                     <div className="text-box7" />
//                     <div className="input-placeholder7">Email</div>
//                   </div>
//                   <div className="buttonlarge27">
//                     <div className="button35" />
//                     <div className="button-text62">Sign Up</div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="footer-legal8">
//             <div className="page-title20">
//               © 2022 Scholist. All rights reserved.
//             </div>
//             <div className="page-title20">Disclaimer Cookies</div>
//           </div>
//         </div>
//       </div>
//       {isScholarshipFiltersOpen && (
//         <PortalDrawer
//           overlayColor="rgba(113, 113, 113, 0.3)"
//           placement="Bottom"
//           onOutsideClick={closeScholarshipFilters}
//         >
//           <ScholarshipFilters onClose={closeScholarshipFilters} />
//         </PortalDrawer>
//       )}
//     </>
//   );
// };

import { useState, useCallback, useEffect } from "react";
import "antd/dist/antd.min.css";
import { Menu, Dropdown, Button } from "antd";
import Select from "@mui/material/Select";
import axios from "axios";

import ScholarshipFilters from "../components/ScholarshipFilters";
import PortalDrawer from "../components/PortalDrawer";
import { Navigate, useNavigate, Link } from "react-router-dom";
import "./SCHOLARSHIPSCATEGORYPAGE.css";

import Container from "@mui/material/Container";
import UseScholarshipFilters from "../components/UseScholarshipFilters";
import { useSelector } from "react-redux";
import { BASE_Server_URL, userRequest } from "../requestMethods";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import * as React from "react";

import MenuItem from "@mui/material/MenuItem";
//apikey brndfetch CQ1QXoQHxNUjUIGrFRfqEuKjIWYMUy5Relk+Y8ojcqY=

import Box from "@mui/material/Box";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import FormControl from "@mui/material/FormControl";

import CircularProgress from "@mui/material/CircularProgress";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          sx={{
            backgroundColor: "rgb(217 214 214)",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "black",
            },
          }}
          variant="determinate"
          {...props}
        />
      </Box>
      {/* <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box> */}
    </Box>
  );
}

const BasedOnProfile = () => {
  const [isScholarshipFiltersOpen, setScholarshipFiltersOpen] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);

  const openScholarshipFilters = useCallback(() => {
    setScholarshipFiltersOpen(true);
  }, []);

  const closeScholarshipFilters = useCallback(() => {
    setScholarshipFiltersOpen(false);
  }, []);

  const onSeeAllButtonClick = useCallback(() => {
    navigate("/based-on-profile");
  }, [navigate]);

  const onFrameContainer9Click = useCallback(() => {
    navigate("/based-on-profile");
  }, [navigate]);

  const onSeeAllButton2Click = useCallback(() => {
    navigate("/popular");
  }, [navigate]);

  const onSeeAllButton4Click = useCallback(() => {
    navigate("/based-on-major");
  }, [navigate]);

  const [scholarships, setScholarships] = useState([]);
  const [scholarshipBookmarks, setScholarshipBookmarks] = useState([]);
  const [appliedScholarship, setAppliedScholarship] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [basedOnProfile, setBasedOnProfile] = useState([]);
  const [basedOnMajor, setBasedOnMajor] = useState([]);
  const [popular, setPopular] = useState([]);
  const [filteredScholarship, setFilteredScholarship] = useState([]);
  const [profile, setProfile] = useState({
    data: {
      profileCompleted: false,
    },
  });
  const [loadValue, setLoadValue] = useState(8);
  const [loadValue1, setLoadValue1] = useState(8);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [sortType, setSortType] = React.useState("deadline");

  const [searchLoading, setSearchLoading] = useState(false);

  const [basedOnProfileLoading, setBasedOnProfileLoading] = useState(false);
  const [basedOnMajorLoading, setBasedOnMajorLoading] = useState(false);
  const [popularLoading, setpopularLoading] = useState(false);

  const [todaysEvent, setTodaysEvent] = useState([]);

  const handleChange = (event) => {
    setSortType(event.target.value);
  };
  //   function fetchLogosTest() {
  //     //const url = 'https://api.brandfetch.io/v2/brands/brandfetch.com';
  //     const client = axios.create({
  //       baseURL: "http://www.imanet.org",
  //       headers: { Authorization: 'Bearer CQ1QXoQHxNUjUIGrFRfqEuKjIWYMUy5Relk+Y8ojcqY=' },

  //   });

  // // const options = {
  // //   method: 'GET',
  // //   headers: {
  // //     accept: 'application/json',
  // //     Authorization: 'Bearer CQ1QXoQHxNUjUIGrFRfqEuKjIWYMUy5Relk+Y8ojcqY='
  // //   }
  // // };

  // client.get()
  //   .then(res => {
  //     console.log(res)
  //     //res.json()
  //   }
  //   )
  //   //.then(json => console.log('brand details',json))
  //   .catch(err => console.error('error:' + err));
  //   }

  //   useEffect(()=> {
  //     fetchLogosTest()
  //   }, [])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
    const currentUser = user && JSON.parse(user).currentUser;
    const TOKEN = currentUser?.tokens.access.token;

    console.log(currentUser);
    const client = axios.create({
      baseURL: BASE_Server_URL,
      headers: { Authorization: `Bearer ${TOKEN}` },
    });
    client
      .get(`/profile`)
      .then((profile) => {
        //console.log('profile fnckme', profile)
        setProfile(profile);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    const listOfMajors = [
      "Accounting",
      "Actuarial Science",
      "Advertising",
      "Aerospace Engineering",
      "African Languages, Literatures, and Linguistics",
      "African Studies",
      "African-American Studies",
      "Agricultural Business and Management",
      "Agricultural Economics",
      "Agricultural Education",
      "Agricultural Journalism",
      "Agricultural Mechanization",
      "Agricultural Technology Management",
      "Agricultural/Biological Engineering and Bioengineering",
      "Agriculture",
      "Agronomy and Crop Science",
      "Air Traffic Control",
      "American History",
      "American Literature",
      "American Sign Language",
      "American Studies",
      "Anatomy",
      "Ancient Studies",
      "Animal Behavior and Ethology",
      "Animal Science",
      "Animation and Special Effects",
      "Anthropology",
      "Applied Mathematics",
      "Aquaculture",
      "Aquatic Biology",
      "Arabic",
      "Archeology",
      "Architectural Engineering",
      "Architectural History",
      "Architecture",
      "Art",
      "Art Education",
      "Art History",
      "Art Therapy",
      "Artificial Intelligence and Robotics",
      "Asian-American Studies",
      "Astronomy",
      "Astrophysics",
      "Athletic Training",
      "Atmospheric Science",
      "Automotive Engineering",
      "Aviation",
      "Bakery Science",
      "Biblical Studies",
      "Biochemistry",
      "Bioethics",
      "Biology",
      "Biomedical Engineering",
      "Biomedical Science",
      "Biopsychology",
      "Biotechnology",
      "Botany/Plant Biology",
      "Business Administration/Management",
      "Business Communications",
      "Business Education",
      "Canadian Studies",
      "Caribbean Studies",
      "Cell Biology",
      "Ceramic Engineering",
      "Ceramics",
      "Chemical Engineering",
      "Chemical Physics",
      "Chemistry",
      "Child Care",
      "Child Development",
      "Chinese",
      "Chiropractic",
      "Church Music",
      "Cinematography and Film/Video Production",
      "Circulation Technology",
      "Civil Engineering",
      "Classics",
      "Clinical Psychology",
      "Cognitive Psychology",
      "Communication Disorders",
      "Communications",
      "Speech Communication and Rhetoric",
      "Comparative Literature",
      "Computer and Information Science",
      "Computer Engineering",
      "Computer Graphics",
      "Computer Systems Analysis",
      "Construction Management",
      "Counseling",
      "Crafts",
      "Creative Writing",
      "Criminal Science",
      "Criminology",
      "Culinary Arts",
      "Dance",
      "Data Processing",
      "Dental Hygiene",
      "Developmental Psychology",
      "Diagnostic Medical Sonography",
      "Dietetics",
      "Digital Communications and Media/Multimedia",
      "Drawing",
      "Early Childhood Education",
      "East Asian Studies",
      "East European Studies",
      "Ecology",
      "Economics",
      "Education",
      "Education Administration",
      "Education of the Deaf",
      "Educational Psychology",
      "Electrical Engineering",
      "Elementary Education",
      "Engineering Mechanics",
      "Engineering Physics",
      "English",
      "English Composition",
      "English Literature",
      "Entomology",
      "Entrepreneurship",
      "Environmental Design/Architecture",
      "Environmental Science",
      "Environmental/Environmental Health Engineering",
      "Epidemiology",
      "Equine Studies",
      "Ethnic Studies",
      "European History",
      "Experimental Pathology",
      "Experimental Psychology",
      "Fashion Design",
      "Fashion Merchandising",
      "Feed Science",
      "Fiber, Textiles, and Weaving Arts",
      "Film",
      "Finance",
      "Floriculture",
      "Food Science",
      "Forensic Science",
      "Forestry",
      "French",
      "Furniture Design",
      "Game Design",
      "Gay and Lesbian Studies",
      "Genetics",
      "Geography",
      "Geological Engineering",
      "Geology",
      "Geophysics",
      "German",
      "Gerontology",
      "Government",
      "Graphic Design",
      "Health Administration",
      "Hebrew",
      "Hispanic-American, Puerto Rican, and Chicano Studies",
      "Historic Preservation",
      "History",
      "Home Economics",
      "Horticulture",
      "Hospitality",
      "Human Development",
      "Human Resources Management",
      "Illustration",
      "Industrial Design",
      "Industrial Engineering",
      "Industrial Management",
      "Industrial Psychology",
      "Information Technology",
      "Interior Architecture",
      "Interior Design",
      "International Agriculture",
      "International Business",
      "International Relations",
      "International Studies",
      "Islamic Studies",
      "Italian",
      "Japanese",
      "Jazz Studies",
      "Jewelry and Metalsmithing",
      "Jewish Studies",
      "Journalism",
      "Kinesiology",
      "Korean",
      "Land Use Planning and Management",
      "Landscape Architecture",
      "Landscape Horticulture",
      "Latin American Studies",
      "Law",
      "Library Science",
      "Linguistics",
      "Logistics Management",
      "Management Information Systems",
      "Managerial Economics",
      "Marine Biology",
      "Marine Science",
      "Marketing",
      "Mass Communication",
      "Massage Therapy",
      "Materials Science",
      "Mathematics",
      "Mechanical Engineering",
      "Medical Technology",
      "Medicine",
      "Medieval and Renaissance Studies",
      "Mental Health Services",
      "Merchandising and Buying Operations",
      "Metallurgical Engineering",
      "Microbiology",
      "Middle Eastern Studies",
      "Military Science",
      "Mineral Engineering",
      "Missions",
      "Modern Greek",
      "Molecular Biology",
      "Molecular Genetics",
      "Mortuary Science",
      "Museum Studies",
      "Music",
      "Music Education",
      "Music History",
      "Music Management",
      "Music Therapy",
      "Musical Theater",
      "Native American Studies",
      "Natural Resources Conservation",
      "Naval Architecture",
      "Neurobiology",
      "Neuroscience",
      "Nuclear Engineering",
      "Nursing",
      "Nutrition",
      "Occupational Therapy",
      "Ocean Engineering",
      "Oceanography",
      "Operations Management",
      "Organizational Behavior Studies",
      "Painting",
      "Paleontology",
      "Pastoral Studies",
      "Peace Studies",
      "Petroleum Engineering",
      "Pharmacology",
      "Pharmacy",
      "Philosophy",
      "Photography",
      "Photojournalism",
      "Physical Education",
      "Physical Therapy",
      "Physician Assistant",
      "Physics",
      "Physiological Psychology",
      "Piano",
      "Planetary Science",
      "Plant Pathology",
      "Playwriting and Screenwriting",
      "Political Communication",
      "Political Science",
      "Portuguese",
      "Pre-Dentistry",
      "Pre-Law",
      "Pre-Medicine",
      "Pre-Optometry",
      "Pre-Seminary",
      "Pre-Veterinary Medicine",
      "Printmaking",
      "Psychology",
      "Public Administration",
      "Public Health",
      "Public Policy Analysis",
      "Public Relations",
      "Radio and Television",
      "Radiologic Technology",
      "Range Science and Management",
      "Real Estate",
      "Recording Arts Technology",
      "Recreation Management",
      "Rehabilitation Services",
      "Religious Studies",
      "Respiratory Therapy",
      "Risk Management",
      "Rural Sociology",
      "Russian",
      "Scandinavian Studies",
      "Sculpture",
      "Slavic Languages and Literatures",
      "Social Psychology",
      "Social Work",
      "Sociology",
      "Soil Science",
      "Software Engineering",
      "Sound Engineering",
      "South Asian Studies",
      "Southeast Asia Studies",
      "Spanish",
      "Special Education",
      "Speech Pathology",
      "Sport and Leisure Studies",
      "Sports Management",
      "Statistics",
      "Surveying",
      "Sustainable Resource Management",
      "Teacher Education",
      "Teaching English as a Second Language",
      "Technical Writing",
      "Technology Education",
      "Textile Engineering",
      "Theatre",
      "Theology",
      "Tourism",
      "Toxicology",
      "Turfgrass Science",
      "Urban Planning",
      "Urban Studies",
      "Veterinary Medicine",
      "Visual Communication",
      "Voice",
      "Web Design",
      "Webmaster and Web Management",
      "Welding Engineering",
      "Wildlife Management",
      "Women's Studies",
      "Youth Ministries",
      "Zoology",
    ];

    const majorsArray = listOfMajors.map((major) => {
      return {
        name: major,
        value: major,
        checked: false,
      };
    });

    // Testing the output
    console.log(majorsArray);
  }, []);

  const colors = ["#F8D100", "#5D57F9", " #F40000", "#00B8C3"];
  //   const getScholarships= async () =>{
  //     setSearchLoading(true)

  //     try {
  //       //console.log('called')
  //        // const res = await axios.get(`http://localhost:3000/v1/scholarship?page=${page}&limit=8`);
  //        const res = await axios.get(`http://localhost:3000/v1/scholarship`);
  //         //console.log(res.data)
  //         // const pageC = await res.data.totalPages
  //        // console.log(res)
  //         setScholarships(res.data)
  //         setFilteredScholarship(res.data)
  //         setPageCount(pageC)

  //     } catch (error) {

  //     }setSearchLoading(false)
  // };
  //   useEffect(() => {

  //     getScholarships()
  // },[])

  //basedonYourprofile scholarships
  const getBasedOnYourProfileScholarships = async () => {
    setBasedOnProfileLoading(true);

    try {
      //
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.get("/basedOnProfile");
      //const res = await axios.get(`http://localhost:3000/v1/basedOnMajor`);
      console.log("based  to profile", res.data);
      //setAppliedScholarship(res.data)

      setBasedOnProfile(res.data);
      setScholarships(res.data);
      setFilteredScholarship(res.data);
    } catch (error) {
      console.log(error);
    }
    setBasedOnProfileLoading(false);
  };
  useEffect(() => {
    getBasedOnYourProfileScholarships();
  }, []);

  //basedonYourmajor scholarships
  const getBasedOnYourMajorScholarships = async () => {
    setBasedOnMajorLoading(true);

    try {
      // console.log('called')
      // const res = await axios.get(`http://localhost:3000/v1/scholarship`);
      // //console.log(res.data.results)
      // const pageC = await res.data.totalPages
      // console.log(res)

      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.get("/basedOnMajor");
      //const res = await axios.get(`http://localhost:3000/v1/basedOnMajor`);
      console.log("based  to", res.data);
      //setAppliedScholarship(res.data)

      setBasedOnMajor(res.data);
      // setPageCount(pageC)
    } catch (error) {
      console.log("based on maor", error);
    }
    setBasedOnMajorLoading(false);
  };
  useEffect(() => {
    getBasedOnYourMajorScholarships();
  }, []);

  //basedonYourmajor scholarships
  const getPopularScholarships = async () => {
    setpopularLoading(true);

    try {
      // console.log('called')

      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.get("/popular");
      const pageC = await res.data.totalPages;
      //console.log('populRS SCVB',res)
      setPopular(res.data);
      // setPageCount(pageC)
    } catch (error) {}
    setpopularLoading(false);
  };
  useEffect(() => {
    getPopularScholarships();
  }, []);

  const {
    render,
    search,
    categoryFilters,
    amountFilters,
    gradeLevelFilters,
    locationFilters,
    majorFilters,
    citizenshipFilters,
    filters,
    sort,
  } = UseScholarshipFilters();
  useEffect(
    () =>
      //console.log('filters changed', filters);

      setFilteredScholarship(
        filtering
          .categoryFiltering()
          .amountFiltering()
          .locationFiltering()
          .majorFiltering()
          .gradeLevelFiltering()
          .citizenshipFiltering().result
      ),
    [filters]
  );

  function filteredorscholarship() {
    if (
      search !== "" ||
      categoryFilters.categories.length > 0 ||
      amountFilters.amounts.length > 0 ||
      gradeLevelFilters.gradeLevels.length > 0 ||
      locationFilters.locations.length > 0 ||
      majorFilters.majors.length > 0 ||
      citizenshipFilters.citizenships.length > 0
    ) {
      setFilteredScholarship(scholarships);
    } else {
      setFilteredScholarship(scholarships);
    }
  }
  useEffect(
    () =>
      //console.log('search changed', search)
      //setSearchLoading(true),
      setFilteredScholarship(
        scholarships.filter((scholarship) =>
          scholarship.name.toLowerCase().includes(search)
        )
      ),
    // setScholarships(scholarships.filter(scholarship => scholarship.name.toLowerCase().includes(search)))
    //setSearchLoading(false)
    [search]
  );

  function FilterScholarships() {
    console.log("called from child");
  }

  function getSubstring(string, char1, char2) {
    //console.log(string.split(".").length - 1)

    if (string.split(".").length - 1 > 1) {
      const first = string.indexOf(char1) + 1;
      const link = string.slice(
        string.indexOf(char1) + 1,
        //string.lastIndexOf(char2),
        string.indexOf(char2, first + 1)
      );

      return link.charAt(0).toUpperCase() + link.slice(1);
    } else {
      //console.log(string)
      const firstSlash = string.indexOf("/") + 1;
      //console.log(string.indexOf("/", firstSlash + 1))
      // console.log(  string.indexOf('/') + 1)
      // console.log( string.indexOf(char1) + 1)
      const link = string.slice(
        // string.indexOf(char1) + 1,
        //string.lastIndexOf(char2),
        //string.indexOf("/", firstSlash + 1),
        string.indexOf("/") + 2,
        string.indexOf(char1)
      );

      return link.charAt(0).toUpperCase() + link.slice(1);
      //return ()
    }
    //count number of dot in string
    //get word between second slash
  }

  function getSubstringForLogo(string, char1, char2) {
    //console.log(string.split(".").length - 1)

    if (string.split(".").length - 1 > 1) {
      const first = string.indexOf(char1) + 1;
      const link = string.slice(
        string.indexOf(char1) + 1,
        //string.lastIndexOf(char2),
        string.indexOf(char2, first + 1) + 4
      );
      //console.log(link)

      return link.charAt(0).toUpperCase() + link.slice(1);
    } else {
      //console.log(string)
      const firstSlash = string.indexOf("/") + 1;
      //console.log(string.indexOf("/", firstSlash + 1))
      // console.log(  string.indexOf('/') + 1)
      // console.log( string.indexOf(char1) + 1)
      const link = string.slice(
        // string.indexOf(char1) + 1,
        //string.lastIndexOf(char2),
        //string.indexOf("/", firstSlash + 1),
        string.indexOf("/") + 2,
        string.indexOf(char1) + 4
      );
      //console.log(link)

      return link.charAt(0).toUpperCase() + link.slice(1);
      //return ()
    }
    //count number of dot in string
    //get word between second slash
  }

  function LoadMore() {
    //setSearchLoading(true)
    if (loadValue < filteredScholarship.length) {
      setLoadValue(loadValue + 8);
    }
    if (loadValue > filteredScholarship.length) {
      setLoadValue(filteredScholarship.length);
      console.log("this");
    }

    if (loadValue + 8 > filteredScholarship.length) {
      setLoadValue(filteredScholarship.length);
    }

    // if(!user && loadValue + 8 > 20){
    //   console.log('Pls sign UPdcec')
    // }
    else {
      console.log("No more data to display");
    }
    //setSearchLoading(false)
  }

  function LoadMore1() {
    console.log(loadValue1);
    console.log(filteredScholarship);
    if (loadValue1 < filteredScholarship.length) {
      setLoadValue1(loadValue1 + 8);
    }
    if (loadValue1 > filteredScholarship.length) {
      console.log("this");
      setLoadValue1(filteredScholarship.length);
    }
    if (loadValue1 + 8 > filteredScholarship.length) {
      setLoadValue1(filteredScholarship.length);
    } else {
      console.log("No more data to display");
    }
  }

  function ScholarshipName(scholarship) {
    //{elem.name.split(' ').slice(0,3).join(' ') + ' '+ elem.awardType}

    const elem = scholarship.name.split(" ");
    //console.log(elem)
    if (
      elem[2] == "Scholarship" ||
      elem[2] == "Grant" ||
      elem[2] == "Fellowship" ||
      elem[2] == "Loan"
    ) {
      return scholarship.name.split(" ").slice(0, 2).join(" ");
    }

    if (
      elem[1] == "Scholarship" ||
      elem[1] == "Grant" ||
      elem[1] == "Fellowship" ||
      elem[1] == "Loan"
    ) {
      return scholarship.name.split(" ").slice(0, 2).join(" ");
    } else {
      return (
        scholarship.name.split(" ").slice(0, 2).join(" ") +
        " " +
        scholarship.awardType
      );
    }
  }

  function DeadlineOrganiser(deadline) {
    const notSpecified = [];
    const pastDeadlines = [];
    const futureDeadlines = [];

    const currentDate = new Date();
    if (deadline === "not specified") {
      notSpecified.push(deadline);
    } else {
      // Split the deadline string into year, month, and day
      const dateParts = deadline.split("-");
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]);
      const day = parseInt(dateParts[2]);

      // Create a Date object to represent the deadline
      const deadlineDate = new Date(year, month - 1, day);

      const formattedDate = `${month}/${day}/${year}`;

      // Compare dates: add to appropriate array
      if (deadlineDate >= currentDate) {
        futureDeadlines.push(formattedDate);
      } else if (deadlineDate < currentDate) {
        pastDeadlines.push(`${formattedDate}`);
      }
    }

    // Combine the arrays: not specified, past deadlines, and future deadlines
    return [...futureDeadlines, ...notSpecified, ...pastDeadlines];
  }
  function BasedOnProfileLoading() {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    if (basedOnProfileLoading) {
      return (
        <div>
          {" "}
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <>
          {basedOnProfile.slice(0, 8).map((elem, index) => (
            <div
              className="card24"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div
                style={{
                  backgroundColor:
                    hoveredIndex === index
                      ? "#ccc"
                      : Number(elem.amount) > 1000 && Number(elem.amount) < 3000
                      ? colors[0]
                      : Number(elem.amount) > 3000 && Number(elem.amount) < 6000
                      ? colors[1]
                      : Number(elem.amount) > 6000 &&
                        Number(elem.amount) < 10000
                      ? colors[2]
                      : Number(elem.amount) > 10000
                      ? colors[3]
                      : colors[1],
                }}
                className="cardscholarshipsbackgroundco24"
              >
                <Link
                  className="link"
                  to={user ? `/scholarships-description1/${elem.id}` : `/login`}
                >
                  <img
                    className="cardscholarshipsimage-icon24"
                    alt={getSubstring(elem.link, ".", ".")}
                    //src="/cardscholarshipsimage4@2x.png"
                    // src="https://logo.clearbit.com/mortarboard.org/"
                    src={
                      "https://logo.clearbit.com/" +
                      getSubstringForLogo(elem.link, ".", ".") +
                      "/"
                    }
                  />
                </Link>
              </div>
              {/* <b className="title24">{elem.name.split(' ').slice(0,2).join(' ')}</b> */}
              {/* <b className="title24">{elem.name.split(' ').slice(0,3).join(' ') + ' '+ elem.awardType}</b> */}

              <b className="title24">
                <Link
                  className="link"
                  to={user ? `/scholarships-description1/${elem.id}` : `/login`}
                  style={{ color: "black" }}
                >
                  {ScholarshipName(elem)}
                </Link>
              </b>
              <div className="organization24">
                {getSubstring(elem.link, ".", ".")}
              </div>
              <div className="award-amount25">
                ${elem.amount ? elem.amount : "NaN"}
              </div>
              <div className="deadline23">
                Deadline:{" "}
                {DeadlineOrganiser(
                  elem.deadline !== null
                    ? elem.deadline.slice(0, elem.deadline.indexOf("T"))
                    : "not specified"
                )}
              </div>
              {/* <img
        className="component-2-icon24"
        alt=""
        src="/component-23.svg"
      /> */}
              {BookmarkChecker(elem.id, elem)}
            </div>
          ))}
        </>
      );
    }
  }

  function BasedOnMajorLoading() {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    if (basedOnMajorLoading) {
      return (
        <div>
          {" "}
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <>
          {basedOnMajor.slice(0, 8).map((elem, index) => (
            <div
              className="card24"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div
                style={{
                  backgroundColor:
                    hoveredIndex === index
                      ? "#ccc"
                      : Number(elem.amount) > 1000 && Number(elem.amount) < 3000
                      ? colors[0]
                      : Number(elem.amount) > 3000 && Number(elem.amount) < 6000
                      ? colors[1]
                      : Number(elem.amount) > 6000 &&
                        Number(elem.amount) < 10000
                      ? colors[2]
                      : Number(elem.amount) > 10000
                      ? colors[3]
                      : colors[1],
                }}
                className="cardscholarshipsbackgroundco24"
              >
                <Link
                  className="link"
                  to={
                    user ? `/scholarships-description1/${elem.id}` : `/Signup`
                  }
                >
                  <img
                    className="cardscholarshipsimage-icon24"
                    alt={getSubstring(elem.link, ".", ".")}
                    //src="/cardscholarshipsimage4@2x.png"
                    // src="https://logo.clearbit.com/mortarboard.org/"
                    src={
                      "https://logo.clearbit.com/" +
                      getSubstringForLogo(elem.link, ".", ".") +
                      "/"
                    }
                  />
                </Link>
              </div>
              {/* <b className="title24">{elem.name.split(' ').slice(0,3).join(' ') + ' '+ elem.awardType}</b> */}
              <b className="title24">
                <Link
                  className="link"
                  to={user ? `/scholarships-description1/${elem.id}` : `/login`}
                  style={{ color: "black" }}
                >
                  {ScholarshipName(elem)}
                </Link>
              </b>
              <div className="organization24">
                {getSubstring(elem.link, ".", ".")}
              </div>
              <div className="award-amount25">
                ${elem.amount ? elem.amount : "NaN"}
              </div>
              <div className="deadline23">
                Deadline:
                {DeadlineOrganiser(
                  elem.deadline !== null
                    ? elem.deadline.slice(0, elem.deadline.indexOf("T"))
                    : "not specified"
                )}
              </div>
              {/* <img
             className="component-2-icon24"
             alt=""
             src="/component-23.svg"
           /> */}{" "}
              {BookmarkChecker(elem.id, elem)}
            </div>
          ))}
        </>
      );
    }
  }

  function PopularLoading() {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    if (popularLoading) {
      return (
        <div>
          {" "}
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <>
          {popular.slice(0, 8).map((elem, index) => (
            <div
              className="card24"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div
                style={{
                  backgroundColor:
                    hoveredIndex === index
                      ? "#ccc"
                      : Number(elem.amount) > 1000 && Number(elem.amount) < 3000
                      ? colors[0]
                      : Number(elem.amount) > 3000 && Number(elem.amount) < 6000
                      ? colors[1]
                      : Number(elem.amount) > 6000 &&
                        Number(elem.amount) < 10000
                      ? colors[2]
                      : Number(elem.amount) > 10000
                      ? colors[3]
                      : colors[1],
                }}
                className="cardscholarshipsbackgroundco24"
              >
                <Link
                  className="link"
                  to={user ? `/scholarships-description1/${elem.id}` : `/login`}
                >
                  <img
                    className="cardscholarshipsimage-icon24"
                    alt={getSubstring(elem.link, ".", ".")}
                    //src="/cardscholarshipsimage4@2x.png"
                    // src="https://logo.clearbit.com/mortarboard.org/"
                    src={
                      "https://logo.clearbit.com/" +
                      getSubstringForLogo(elem.link, ".", ".") +
                      "/"
                    }
                  />
                </Link>
              </div>
              {/* <b className="title24">{elem.name.split(' ').slice(0,3).join(' ') + ' '+ elem.awardType}</b> */}

              <b className="title24">
                <Link
                  className="link"
                  to={user ? `/scholarships-description1/${elem.id}` : `/login`}
                  style={{ color: "black" }}
                >
                  {ScholarshipName(elem)}
                </Link>
              </b>
              <div className="organization24">
                {getSubstring(elem.link, ".", ".")}
              </div>
              <div className="award-amount25">
                ${elem.amount ? elem.amount : "NaN"}
              </div>
              <div className="deadline23">
                Deadline:{" "}
                {DeadlineOrganiser(
                  elem.deadline !== null
                    ? elem.deadline.slice(0, elem.deadline.indexOf("T"))
                    : "not specified"
                )}
              </div>
              {/* <img
        className="component-2-icon24"
        alt=""
        src="/component-23.svg"
      /> */}
              {BookmarkChecker(elem.id, elem)}
            </div>
          ))}
        </>
      );
    }
  }

  function SearchLoading() {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    if (searchLoading) {
      return (
        <div>
          {" "}
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <>
          {/* based on profile scholarship */}
          {filteredScholarship.slice(0, loadValue).map((elem, index) => (
            <div
              className="card24"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div
                style={{
                  backgroundColor:
                    hoveredIndex === index
                      ? "#ccc"
                      : Number(elem.amount) > 1000 && Number(elem.amount) < 3000
                      ? colors[0]
                      : Number(elem.amount) > 3000 && Number(elem.amount) < 6000
                      ? colors[1]
                      : Number(elem.amount) > 6000 &&
                        Number(elem.amount) < 10000
                      ? colors[2]
                      : Number(elem.amount) > 10000
                      ? colors[3]
                      : colors[1],
                }}
                className="cardscholarshipsbackgroundco24"
              >
                <Link
                  className="link"
                  to={user ? `/scholarships-description1/${elem.id}` : `/login`}
                >
                  <img
                    className="cardscholarshipsimage-icon24"
                    alt={getSubstring(elem.link, ".", ".")}
                    //src="/cardscholarshipsimage4@2x.png"
                    // src="https://logo.clearbit.com/mortarboard.org/"
                    src={
                      "https://logo.clearbit.com/" +
                      getSubstringForLogo(elem.link, ".", ".") +
                      "/"
                    }
                  />
                </Link>
              </div>
              {/* .split('.').slice(0,2).join(' ') */}
              {/* <b className="title24">{elem.name.split(' ').slice(0,3).join(' ') + ' '+ elem.awardType}</b> */}

              <b className="title24">
                <Link
                  className="link"
                  to={user ? `/scholarships-description1/${elem.id}` : `/login`}
                  style={{ color: "black" }}
                >
                  {ScholarshipName(elem)}
                </Link>
              </b>
              <div className="organization24">
                {getSubstring(elem.link, ".", ".")}
              </div>
              <div className="award-amount25">
                ${elem.amount ? elem.amount : "NaN"}
              </div>
              <div className="deadline23">
                Deadline:{" "}
                {DeadlineOrganiser(
                  elem.deadline !== null
                    ? elem.deadline.slice(0, elem.deadline.indexOf("T"))
                    : "not specified"
                )}
              </div>
              {/* <img
       className="component-2-icon24"
       alt=""
       src="/component-23.svg"
     /> */}
              {BookmarkChecker(elem.id, elem)}
            </div>
          ))}
        </>
      );
    }
  }

  function BasedOnYoutProfile() {
    return (
      <>
        <div className="category-header">
          <div className="based-on-your-profile-parent">
            <b className="based-on-your7">{`Based on Your Profile `}</b>
            <button className="see-all-button4" onClick={onSeeAllButtonClick}>
              <div className="see-all6">See all</div>
              <img
                className="arrow-forward-icon4"
                alt=""
                src="/arrow-forward3.svg"
              />
            </button>
          </div>
        </div>
        <Container>
          <div className="scholarshipsContainer-cat">
            {BasedOnProfileLoading()}
          </div>
        </Container>
        <div className="see-all7">
          <div
            className="see-all-button-frame"
            onClick={onFrameContainer9Click}
          >
            <div className="see-all-button5">
              <div className="see-all8">See all</div>
              <img
                className="arrow-forward-icon4"
                alt=""
                src="/arrow-forward4.svg"
              />
            </div>
          </div>
        </div>

        <div className="category-header1">
          <div className="based-on-your-profile-parent">
            <b className="based-on-your7">Popular</b>
            <button className="see-all-button4" onClick={onSeeAllButton2Click}>
              <div className="see-all6">See all</div>
              <img
                className="arrow-forward-icon4"
                alt=""
                src="/arrow-forward3.svg"
              />
            </button>
          </div>
        </div>

        {/* <div className="row-2-wrapper-wrapper">
          <div className="row-2-wrapper7">
            <div className="card44">
              <div className="cardscholarshipsbackgroundco40">
                <img
                  className="cardscholarshipsimage-icon40"
                  alt=""
                  src="/cardscholarshipsimage@2x.png"
                />
              </div>
              <b className="title40">“Be Bold” No-Essay Scholarship</b>
              <div className="organization40">Bold.org</div>
              <div className="award-amount41">$25,000</div>
              <div className="deadline39">Deadline: 04/30/2023</div>
              <img
                className="component-2-icon40"
                alt=""
                src="/component-2.svg"
              />
            </div>
            <div className="card44">
              <div className="cardscholarshipsbackgroundco41">
                <img
                  className="cardscholarshipsimage-icon40"
                  alt=""
                  src="/cardscholarshipsimage1@2x.png"
                />
              </div>
              <b className="title40">$2,000 Nitro Scholarship Sweepstakes</b>
              <div className="organization40">Nitro</div>
              <div className="award-amount41">$2,000</div>
              <div className="deadline39">Deadline: 12/31/2023</div>
              <img
                className="component-2-icon40"
                alt=""
                src="/component-2.svg"
              />
            </div>
            <div className="card44">
              <div className="cardscholarshipsbackgroundco42">
                <img
                  className="cardscholarshipsimage-icon42"
                  alt=""
                  src="/cardscholarshipsimage2@2x.png"
                />
              </div>
              <b className="title40">
                $50,000 ScholarshipOwl No Essay Scholarship
              </b>
              <div className="organization40">ScholarshipOwl</div>
              <div className="award-amount41">$50,000</div>
              <div className="deadline39">Deadline: 06/17/2023</div>
              <img
                className="component-2-icon40"
                alt=""
                src="/component-2.svg"
              />
            </div>
            <div className="card44">
              <div className="cardscholarshipsbackgroundco43">
                <img
                  className="cardscholarshipsimage-icon42"
                  alt=""
                  src="/cardscholarshipsimage3@2x.png"
                />
              </div>
              <b className="title40">Coca-Cola Scholars Scholarship</b>
              <div className="organization40">Coca-Cola</div>
              <div className="award-amount41">$20,000</div>
              <div className="deadline39">Deadline: 10/18/2023</div>
              <img
                className="component-2-icon40"
                alt=""
                src="/component-2.svg"
              />
            </div>
          </div>
        </div> */}

        <Container>
          <div className="scholarshipsContainer-cat">
            {/* based on profile scholarship */}
            {PopularLoading()}
          </div>
        </Container>

        <div className="see-all10">
          <div className="see-all-button-wrapper1">
            <div className="see-all-button5">
              <div className="see-all8">See all</div>
              <img
                className="arrow-forward-icon4"
                alt=""
                src="/arrow-forward4.svg"
              />
            </div>
          </div>
        </div>
        <div className="category-header2">
          <div className="based-on-your-profile-parent">
            <b className="based-on-your7">Based on Your Major</b>
            <button className="see-all-button4" onClick={onSeeAllButton4Click}>
              <div className="see-all6">See all</div>
              <img
                className="arrow-forward-icon4"
                alt=""
                src="/arrow-forward3.svg"
              />
            </button>
          </div>
        </div>

        {/* <div className="row-2-wrapper-container">
          <div className="row-2-wrapper7">
            <div className="card44">
              <div className="cardscholarshipsbackgroundco40">
                <img
                  className="cardscholarshipsimage-icon40"
                  alt=""
                  src="/cardscholarshipsimage@2x.png"
                />
              </div>
              <b className="title40">“Be Bold” No-Essay Scholarship</b>
              <div className="organization40">Bold.org</div>
              <div className="award-amount41">$25,000</div>
              <div className="deadline39">Deadline: 04/30/2023</div>
              <img
                className="component-2-icon40"
                alt=""
                src="/component-2.svg"
              />
            </div>
            <div className="card44">
              <div className="cardscholarshipsbackgroundco41">
                <img
                  className="cardscholarshipsimage-icon40"
                  alt=""
                  src="/cardscholarshipsimage1@2x.png"
                />
              </div>
              <b className="title40">$2,000 Nitro Scholarship Sweepstakes</b>
              <div className="organization40">Nitro</div>
              <div className="award-amount41">$2,000</div>
              <div className="deadline39">Deadline: 12/31/2023</div>
              <img
                className="component-2-icon40"
                alt=""
                src="/component-2.svg"
              />
            </div>
            <div className="card44">
              <div className="cardscholarshipsbackgroundco42">
                <img
                  className="cardscholarshipsimage-icon42"
                  alt=""
                  src="/cardscholarshipsimage2@2x.png"
                />
              </div>
              <b className="title40">
                $50,000 ScholarshipOwl No Essay Scholarship
              </b>
              <div className="organization40">ScholarshipOwl</div>
              <div className="award-amount41">$50,000</div>
              <div className="deadline39">Deadline: 06/17/2023</div>
              <img
                className="component-2-icon40"
                alt=""
                src="/component-2.svg"
              />
            </div>
            <div className="card44">
              <div className="cardscholarshipsbackgroundco43">
                <img
                  className="cardscholarshipsimage-icon42"
                  alt=""
                  src="/cardscholarshipsimage3@2x.png"
                />
              </div>
              <b className="title40">Coca-Cola Scholars Scholarship</b>
              <div className="organization40">Coca-Cola</div>
              <div className="award-amount41">$20,000</div>
              <div className="deadline39">Deadline: 10/18/2023</div>
              <img
                className="component-2-icon40"
                alt=""
                src="/component-2.svg"
              />
            </div>
          </div>
        </div> */}

        <Container>
          <div className="scholarshipsContainer-cat">
            {/* based on profile scholarship */}
            {BasedOnMajorLoading()}
          </div>
        </Container>

        <div className="see-all13">
          <div className="see-all-button-wrapper1">
            <div className="see-all-button5">
              <div className="see-all8">See all</div>
              <img
                className="arrow-forward-icon4"
                alt=""
                src="/arrow-forward4.svg"
              />
              {/* {BookmarkChecker(elem.id, elem)} */}
            </div>
          </div>
        </div>
      </>
    );
  }

  function OpenScholarshipDescription(id) {
    if (user) {
      navigate("/scholarships-description1", { state: id });
      // nav("/some-where", { state:data})
    } else {
      navigate("/login");
    }
  }

  // function LimitScholarships {
  //   if(user){
  //     return(filteredScholarship)
  //   }
  //   else{
  //     return(setFfilteredScholarship.slice(0, 20))
  //   }
  // }

  function DisplayProfileOrSearch(
    search,
    categoryFilters,
    amountFilters,
    gradeLevelFilters,
    locationFilters,
    majorFilters,
    citizenshipFilters
  ) {
    // if(
    //   search !== '' ||
    //   categoryFilters.categories.length > 0
    //   || amountFilters.amounts.length > 0
    //   || gradeLevelFilters.gradeLevels.length > 0
    //   || locationFilters.locations.length > 0
    //   || majorFilters.majors.length > 0
    //   || citizenshipFilters.citizenships.length > 0
    //   ||!user )
    //   {

    //console.log(filters)
    return (
      //   <div>
      //     <h1>Filters Available</h1>
      //     <p>search: {search}</p>
      // <p>categoryFilters: {categoryFilters.categories}</p>
      // <p>amountFilters: {amountFilters.amounts}</p>
      // <p>gradeLevelFilters: {gradeLevelFilters.gradeLevels}</p>
      // <p>locationFilters: {locationFilters.locations}</p>
      // <p>majorFilters: {majorFilters.majors}</p>
      // <p>citizenshipFilters: {citizenshipFilters.citizenships}</p>
      //   </div>
      <>
        <Container>
          {/* <p>{filters}</p>
           */}
          <div className="available3">
            <div className="body8">
              <div>
                <a className="scholarships-available3">
                  {filteredScholarship.length} scholarships available
                </a>
              </div>

              <div className="filter-dropdown-button3">
                <div className="filter-dropdown-button-inner1">
                  <div className="sort-by-parent1">
                    <div className="sort-by3">{`Sort by `}</div>
                    <FormControl>
                      <Select
                        label="Relevance"
                        sx={{
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                        }}
                        value={sortType}
                        onChange={handleChange}
                      >
                        <MenuItem value="relevance">
                          <a className="sortList">Relevance</a>
                        </MenuItem>
                        <MenuItem value="a-z">
                          <a className="sortList">A-Z</a>
                        </MenuItem>
                        <MenuItem value="deadline">
                          <a className="sortList">Deadline</a>
                        </MenuItem>
                        {/* <MenuItem value="newest"><a className="sortList">Newest</a></MenuItem> */}
                        <MenuItem value="amount">
                          <a className="sortList">Amount</a>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="availble3">
          <div className="see-more-progress-bar7">
            <div className="scholarship-available3">
              785 Scholarship Available
            </div>
          </div>
        </div> */}

          <div className="scholarshipsContainer-cat">{SearchLoading()}</div>
        </Container>

        {/* <div className="see-more5">
          <div className="see-more-progress-bar8">
            <div className="div50">{loadValue} / {filteredScholarship.length}</div>
            <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={loadValue/filteredScholarship.length * 100} />
    </Box>
            <div className="buttonlarge26" onClick={() => LoadMore()}>
              <div className="button34" />
              <div className="button-text62">See More</div>
            </div>
            {/* <img
              className="progress-bar-icon4"
              alt=""
              src="/progress-bar.svg"
            /> */}

        {/* </div>
        </div> */}

        <div className="seemore-progress-body">
          <div className="see-more-progress-bar11">
            <div className="progress-body">
              <div className="div58">
                {loadValue > filteredScholarship.length
                  ? filteredScholarship.length
                  : loadValue}{" "}
                / {filteredScholarship.length}
              </div>
              {/* <img
              className="progress-bar-icon6"
              alt=""
              src="/progress-bar.svg"
            /> */}
            </div>
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel
                value={(loadValue / filteredScholarship.length) * 100}
              />
            </Box>
            <div></div>

            <div className="buttonlarge30">
              <button
                className="button-seemore"
                onClick={() =>
                  !user && loadValue > 20
                    ? console.log("pls sign updnmm")
                    : LoadMore()
                }
                disabled={loadValue == filteredScholarship.length}
              >
                See More
              </button>
            </div>
          </div>
        </div>
      </>
    );
    //}
    //     else{

    //      //if(user){
    //       return(BasedOnYoutProfile())
    //      //}
    // //      else{
    // //       return(
    // //         <>
    // //         <Container>
    // //         {/* <p>{filters}</p>
    // //                */}
    // //           <div className="available3">
    // //               <div className="body8">
    // //                 <div className="scholarships-available3">
    // //                   {filteredScholarship.length} scholarships available
    // //                 </div>
    // //                 <div className="filter-dropdown-button3">
    // //                   <div className="filter-dropdown-button-inner1">
    // //                     <div className="sort-by-parent1">
    // //                       <p className="sortbyavailable">Sort by</p>
    // //                       {/* <button
    // //                        id="basic-button"
    // //                        aria-controls={open ? 'basic-menu' : undefined}
    // //                        aria-haspopup="true"
    // //                        aria-expanded={open ? 'true' : undefined}
    // //                        onClick={handleClick}
    // //                       className="sortbySelect">Relevance</button>
    // //                        */}

    // //                       <Select sx={{
    // //   "& fieldset": {
    // //     border: "none",
    // //   },
    // // }}>
    // //                         <option value="relevance">Relevance</option>
    // //                         <option value="a-z">A-Z</option>
    // //                         <option value="deadline">Deadline</option>
    // //                         <option value="newest">Newest</option>
    // //                         <option value="awardamount">Award Amount</option>
    // //                       </Select>
    // //                     </div>

    // //                   </div>
    // //                 </div>

    // //               </div>
    // //             </div>

    // //             {/* <div className="availble3">
    // //               <div className="see-more-progress-bar7">
    // //                 <div className="scholarship-available3">
    // //                   785 Scholarship Available
    // //                 </div>
    // //               </div>
    // //             </div> */}

    // //         <div className="scholarshipsContainer-cat">

    // //           {/* based on profile scholarship */}
    // //          {filteredScholarship.slice(0, loadValue1).map(elem => (
    // //          <div className="card24">
    // //          <div  style= {{
    // //                        backgroundColor: Number(elem.amount) > 1000 && Number(elem.amount) < 3000 ? colors[0] : Number(elem.amount) > 3000  && Number(elem.amount) < 6000 ? colors[1] : Number(elem.amount) > 6000 && Number(elem.amount) < 10000 ? colors[2]: Number(elem.amount) > 10000 ? colors[3]: colors[1]
    // //                     }}className="cardscholarshipsbackgroundco24" >

    // //     <Link className='link' to={ user? `/scholarships-description1/${elem.id}`: `/Signup`}>
    // //            <img
    // //              className="cardscholarshipsimage-icon24"
    // //              alt={getSubstring(elem.link, '.', '.')}
    // //              //src="/cardscholarshipsimage4@2x.png"
    // //             // src="https://logo.clearbit.com/mortarboard.org/"
    // //             src={"https://logo.clearbit.com/"+getSubstringForLogo(elem.link, '.', '.')+"/"}
    // //            />
    // //            </Link>
    // //          </div>
    // //          {/* .split('.').slice(0,2).join(' ') */}
    // //          <b className="title24">{elem.name.split(' ').slice(0,2).join(' ')}</b>
    // //           <div className="organization24">{getSubstring(elem.link, '.', '.')}</div>
    // //          <div className="award-amount25">${elem.amount}</div>
    // //          <div className="deadline23">Deadline: { elem.deadline !== null ? elem.deadline.slice(0, elem.deadline.indexOf('T')): 'not specified'}</div>
    // //          {/* <img
    // //            className="component-2-icon24"
    // //            alt=""
    // //            src="/component-23.svg"
    // //          /> */}
    // //          {BookmarkChecker(elem.id, elem)}
    // //        </div>
    // //       ))}
    // //       </div>
    // //         </Container>
    // //         {/* <div className="see-more5">
    // //           <div className="see-more-progress-bar8">
    // //             <div className="div50">{loadValue1} / {filteredScholarship.length}</div>
    // //             <Box sx={{ width: '100%' }}>
    // //       <LinearProgressWithLabel value={loadValue/filteredScholarship.length * 100} />
    // //     </Box>
    // //             <div className="buttonlarge26" onClick={() => LoadMore1()}>
    // //               <div className="button34" />
    // //               <div className="button-text62">See More</div>
    // //             </div>
    // //             {/* <img
    // //               className="progress-bar-icon4"
    // //               alt=""
    // //               src="/progress-bar.svg"
    // //             /> */}
    // //                    {/* <Box sx={{ width: '100%' }}>
    // //       <LinearProgressWithLabel value={loadValue/filteredScholarship.length * 100} />
    // //     </Box> */}
    // //           {/* </div>
    // //         </div> */}

    // //         <div className="seemore-progress-body">
    // //           <div className="see-more-progress-bar11">
    // //             <div className="progress-body">
    // //             <div className="div58">{loadValue1 > filteredScholarship.length ? filteredScholarship.length : loadValue1} / {filteredScholarship.length}</div>
    // //             {/* <img
    // //               className="progress-bar-icon6"
    // //               alt=""
    // //               src="/progress-bar.svg"
    // //             /> */}

    // //             </div>
    // //             <Box sx={{ width: '100%' }}>
    // //           <LinearProgressWithLabel value={loadValue1 /filteredScholarship.length * 100} />
    // //              </Box>
    // //             <div>

    // //               </div>

    // //             <div className="buttonlarge30">

    // //               <button className="button-seemore" onClick={() => LoadMore1()} disabled={loadValue1 == filteredScholarship.length}>See More</button>
    // //             </div>

    // //           </div>
    // //         </div>
    // //         </>
    // //       )
    // //      }

    //     }
  }

  function FilteredScholarshipNav() {
    console.log();
    if (
      search !== "" ||
      categoryFilters.categories.length > 0 ||
      amountFilters.amounts.length > 0 ||
      gradeLevelFilters.gradeLevels.length > 0 ||
      locationFilters.locations.length > 0 ||
      majorFilters.majors.length > 0 ||
      citizenshipFilters.citizenships.length > 0
    ) {
      return (
        <div className="allpage-header-search">
          <div className="headertop-navprimarybread">
            <div
              className="page-titleHome"
              onClick={() => navigate("/dashboard")}
            >
              <a className="scholarshipTitle">Dashboard</a>
              <img className="underline-icon12" alt="" src="/underline.svg" />
            </div>
            <img className="arrow-icon9" alt="" src="/arrow.svg" />
            <div
              className="page-title19"
              onClick={() => navigate("/scholarships-category-page")}
            >
              <a className="scholarshipTitle">Scholarships</a>
              <img className="underline-icon12" alt="" src="/underline.svg" />
            </div>
            <img className="arrow-icon9" alt="" src="/arrow.svg" />
            <div className="page-title21">
              <a className="scholarshipTitle">Search Results</a>
              <img className="underline-icon13" alt="" />
            </div>
            <img className="arrow-icon10" alt="" src="/arrow1.svg" />
            <div className="page-title22">
              <div className="page-title20">Page Title</div>
              <img className="underline-icon13" alt="" />
            </div>
          </div>

          {/* <div className="pageTitles">
            <a className="scholarshipTitle" onClick={()=> navigate('/dashboard')}>Home</a>
            <img  alt="" src="/arrow1.svg" />
              <a className="scholarshipTitle" onClick={()=> navigate('/scholarships-category-page')}>Scholarships</a>
              <img  alt="" src="/arrow1.svg" />
              <a className="scholarshipTitle">Search Results</a>
            </div> */}

          <p className="resultsFor">Results For "{search}</p>
          {/* <b className="scholarships3">Scholarships</b> */}
        </div>
      );
    } else {
      return (
        <>
          <div className="allpage-header-search">
            <div className="headertop-navprimarybread">
              {/* <div className="page-titleHome" onClick={()=> navigate('/dashboard')}> 
                <a className="scholarshipTitle">Dashboard</a>
                <img className="underline-icon12" alt="" src="/underline.svg" />
              </div> */}
              {/* <img className="arrow-icon9" alt="" src="/arrow.svg" /> */}
              <div
                className="page-title19"
                onClick={() => navigate("/dashboard")}
              >
                <div
                  className="dashboardheaderbox"
                  onClick={() => navigate("/dashboard")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 22"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1095_34041)">
                      <path
                        d="M12.8416 15.2075L9.02496 11L12.8416 6.7925L11.6666 5.5L6.66663 11L11.6666 16.5L12.8416 15.2075Z"
                        fill="#4A93CF"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1095_34041">
                        <rect width="20" height="22" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <a
                    className="scholarshipTitleMe"
                    style={{ cursor: "pointer" }}
                  >
                    Dashboard
                  </a>
                </div>
                {/* <img className="underline-icon12" alt="" src="/underline.svg" /> */}
              </div>
              {/* <img className="arrow-icon9" alt="" src="/arrow.svg" /> */}
              <div className="page-title21">
                <a className="scholarshipTitle"></a>
                <img className="underline-icon13" alt="" />
              </div>
              {/* <img className="arrow-icon10" alt="" src="/arrow1.svg" /> */}
              <div className="page-title22">
                <div className="page-title20">Page Title</div>
                <img className="underline-icon13" alt="" />
              </div>
            </div>

            {/* <div className="pageTitles">
            <a className="scholarshipTitle" onClick={()=> navigate('/dashboard')}>Home</a>
            <img  alt="" src="/arrow1.svg" />
              <a className="scholarshipTitle" onClick={()=> navigate('/scholarships-category-page')}>Scholarships</a>
              <img  alt="" src="/arrow1.svg" />
              <a className="scholarshipTitle">Search Results</a>
            </div> */}

            {/* <p className="resultsFor">Results For "{search}</p> */}
            {/* <b className="scholarships3">Scholarships</b> */}
          </div>
          <div className="allpage-header9">
            <b className="scholarships3">Based on Your Profile</b>
          </div>
        </>
      );
    }
  }

  var filtering = {
    result: {},
    categoryFiltering: function () {
      if (search) {
        this.result = categoryFilter(filteredScholarship, filters);
      } else {
        this.result = categoryFilter(scholarships, filters);
      }

      return this;
    },

    amountFiltering: function () {
      this.result = amountFilter(this.result, filters);

      return this;
    },

    locationFiltering: function () {
      this.result = locationFilter(this.result, filters);

      return this;
    },
    majorFiltering: function () {
      this.result = majorFilter(this.result, filters);

      return this;
    },
    gradeLevelFiltering: function () {
      this.result = gradeLevelFilter(this.result, filters);

      return this;
    },
    citizenshipFiltering: function () {
      this.result = citizenFilter(this.result, filters);

      return this;
    },
  };

  function categoryFilter(scholarships, filters) {
    let filteredScholarship = [];
    if (filters.categoryFilters.categories) {
      if (filters.categoryFilters.categories.length > 0) {
        //console.log('categories inside ')
        for (let i = 0; i < filters.categoryFilters.categories.length; i++) {
          if (filters.categoryFilters.categories[i] == "basedOnYourMajor") {
            //  const result = getMajorScholarships(scholarships)
            //  .then((scholarship) => {
            //   for(let i = 0; i < scholarship.length; i++){
            //     filteredScholarship.push(scholarship[i])
            //    }

            //   return(filteredScholarship)

            //   })
            //   .catch(err => {
            //     console.log(err)
            //   })

            const result = getScholarshipsMajor(scholarships);
            //console.log(result)
            for (let i = 0; i < result.length; i++) {
              if (!filteredScholarship.includes(result[i])) {
                filteredScholarship.push(result[i]);
              }
            }

            // return(getScholarshipsMajor(scholarships.result))
          }

          if (filters.categoryFilters.categories[i] == "basedOnYourProfile") {
            const resultProfile = getScholarshipsProfile(scholarships);

            for (let i = 0; i < resultProfile.length; i++) {
              if (!filteredScholarship.includes(resultProfile[i])) {
                filteredScholarship.push(resultProfile[i]);
              }
            }
          }

          if (filters.categoryFilters.categories[i] == "new") {
            const resultNew = scholarships.slice(
              scholarships.length - 30,
              scholarships.length
            );

            console.log("newwest added scholarship", resultNew);
            for (let i = 0; i < resultNew.length; i++) {
              if (!filteredScholarship.includes(resultNew[i])) {
                filteredScholarship.push(resultNew[i]);
              }
            }
          }

          if (filters.categoryFilters.categories[i] == "popular") {
            // const resultNew = scholarships.slice(scholarships.length - 30, scholarships.length)

            const filteredResult = scholarships.filter((item) => {
              return (
                item.location.length > 1 ||
                item.majors.length > 1 ||
                Number(item.amount) > 10000
              );
              //(item.majors.indexOf("All Majors Eligible") > 0);
            });
            console.log("newwest added scholarship", filteredResult);
            for (let i = 0; i < filteredResult.length; i++) {
              if (!filteredScholarship.includes(filteredResult[i])) {
                filteredScholarship.push(filteredResult[i]);
              }
            }
          }

          if (filters.categoryFilters.categories[i] == "needBased") {
            // const resultNew = scholarships.slice(scholarships.length - 30, scholarships.length)

            const filteredResult = scholarships.filter((item) => {
              const items = item.details
                .replaceAll(",", "")
                .replaceAll(".", "")
                .toLowerCase()
                .split(" ");

              //console.log('need based',items)

              if (
                item.details.includes("financial") &&
                item.details.includes("need")
              ) {
                return item;
              }

              //(item.majors.indexOf("All Majors Eligible") > 0);
            });
            console.log("need based scholarship", filteredResult);
            for (let i = 0; i < filteredResult.length; i++) {
              if (!filteredScholarship.includes(filteredResult[i])) {
                filteredScholarship.push(filteredResult[i]);
              }
            }
          }
        }
        return filteredScholarship;
      } else {
        filteredScholarship = scholarships;
        return filteredScholarship;
      }
    }
    // console.log(filteredScholarship)
  }

  function getScholarshipsMajor(scholarships) {
    if (user) {
      console.log("profile rncdcdvf", profile);
      if (profile.data == "") {
        console.log("create data profile");
        // createUserProfile().then((data) => {
        //     console.log('data', data)
        //     setProfile(data)
        // })
      }

      if (profile.data.profileCompleted) {
        // console.log('profile maor',profile.data.fieldOfStudy)
        //console.log(scholarships)
        const filteredResult1 = scholarships.filter((item) => {
          return item.majors == "All Majors Eligible";
          //(item.majors.indexOf("All Majors Eligible") > 0);
        });
        // console.log('profiles',filteredResult1)

        const filteredResult2 = scholarships.filter((item) => {
          return item.majors.indexOf(profile.data.fieldOfStudy) > 0;
        });

        const filteredResult = [...filteredResult1, ...filteredResult2];
        //console.log('profule filter',filteredResult)

        return filteredResult;
        // navigate('/dashboard')
      }
    } else {
      return scholarships.slice(0, 20);
    }

    //return(scholarships)
  }

  function getScholarshipsProfile(scholarships) {
    if (user) {
      console.log("profile rncdcdvf", profile);
      if (profile.data == "") {
        console.log("create data profile");
        // createUserProfile().then((data) => {
        //     console.log('data', data)
        //     setProfile(data)
        // })
      }

      if (profile.data.profileCompleted) {
        // console.log('profile maor',profile.data.fieldOfStudy)
        //console.log(scholarships)
        const filteredResult1 = scholarships.filter((item) => {
          return (
            item.majors == "All Majors Eligible" &&
            item.stage.includes(profile.data.classLevel)
          );
          //(item.majors.indexOf("All Majors Eligible") > 0);
        });
        // console.log('profiles',filteredResult1)

        const filteredResult2 = scholarships.filter((item) => {
          return (
            item.majors.indexOf(profile.data.fieldOfStudy) > 0 &&
            item.stage.includes(profile.data.classLevel)
          );
        });

        // const filteredResultM = scholarships.filter((item) => {
        //   return (item.stage.indexOf(profile.data.classLevel) > 0);
        // });

        //check undergraduate posgrdute religion african

        const filteredResult3 = [
          ...filteredResult1,
          ...filteredResult2 /*  ...filteredResultM */,
        ];

        //  const filteredResult = filteredResult3.slice(40)

        const filteredResult = filteredResult3;

        //console.log('profule filter',filteredResult)

        return filteredResult;
        // navigate('/dashboard')
      }
    } else {
      return scholarships.slice(0, 20);
    }
  }

  function amountFilter(scholarships, filters) {
    let filteredScholarship = [];
    if (filters.amountFilters.amounts) {
      if (filters.amountFilters.amounts.length > 0) {
        for (let i = 0; i < filters.amountFilters.amounts.length; i++) {
          if (filters.amountFilters.amounts[i] == "$0 - $1000") {
            //console.log('mount is here')
            const filteredResult = scholarships.filter((item) => {
              return Number(item.amount) > 0 && Number(item.amount) < 1000;
              //(item.majors.indexOf("All Majors Eligible") > 0);
            });

            for (let i = 0; i < filteredResult.length; i++) {
              if (!filteredScholarship.includes(filteredResult[i])) {
                filteredScholarship.push(filteredResult[i]);
              }
            }

            // return(filteredResult)
            // console
          }

          if (filters.amountFilters.amounts[i] == "$1001 - $2500") {
            const filteredResult = scholarships.filter((item) => {
              return Number(item.amount) > 1000 && Number(item.amount) < 2500;
              //(item.majors.indexOf("All Majors Eligible") > 0);
            });
            for (let i = 0; i < filteredResult.length; i++) {
              if (!filteredScholarship.includes(filteredResult[i])) {
                filteredScholarship.push(filteredResult[i]);
              }
            }

            //return(filteredResult)
            // console
          }

          if (filters.amountFilters.amounts[i] == "$2501 - $5000") {
            const filteredResult = scholarships.filter((item) => {
              return Number(item.amount) > 2501 && Number(item.amount) < 5000;
              //(item.majors.indexOf("All Majors Eligible") > 0);
            });
            for (let i = 0; i < filteredResult.length; i++) {
              if (!filteredScholarship.includes(filteredResult[i])) {
                filteredScholarship.push(filteredResult[i]);
              }
            }

            //return(filteredResult)
            // console
          }

          if (filters.amountFilters.amounts[i] == "$5001 - $10000") {
            const filteredResult = scholarships.filter((item) => {
              return Number(item.amount) > 5001 && Number(item.amount) < 10000;
              //(item.majors.indexOf("All Majors Eligible") > 0);
            });
            for (let i = 0; i < filteredResult.length; i++) {
              if (!filteredScholarship.includes(filteredResult[i])) {
                filteredScholarship.push(filteredResult[i]);
              }
            }

            //return(filteredResult)
            // console
          }

          if (filters.amountFilters.amounts[i] == "> $10000") {
            const filteredResult = scholarships.filter((item) => {
              return Number(item.amount) > 10000;
              //(item.majors.indexOf("All Majors Eligible") > 0);
            });
            for (let i = 0; i < filteredResult.length; i++) {
              if (!filteredScholarship.includes(filteredResult[i])) {
                filteredScholarship.push(filteredResult[i]);
              }
            }

            //return(filteredResult)
            // console
          }
        }
        return filteredScholarship;
      } else {
        filteredScholarship = scholarships;
        return filteredScholarship;
      }
    }
  }

  function getMonthNumberFromName(monthName) {
    const year = new Date().getFullYear();
    return new Date(`${monthName} 1, ${year}`).getMonth() + 1;
  }

  function locationFilter(scholarships, filters) {
    console.log(scholarships);
    let filteredScholarship = [];
    if (filters.locationFilters.locations) {
      if (filters.locationFilters.locations.length > 0) {
        for (let i = 0; i < filters.locationFilters.locations.length; i++) {
          const filteredResult = scholarships.filter((item) => {
            //console.log(item.deadline.split(" ").slice(0,2))

            return item.location.includes(filters.locationFilters.locations[i]);
          });

          const filteredResult1 = scholarships.filter((item) => {
            return (
              item.location.indexOf(filters.locationFilters.locations[i]) > 0
            );
          });
          console.log("locations", filteredResult);
          console.log("locations1", filteredResult1);
          for (let i = 0; i < filteredResult.length; i++) {
            //console.log('h',filteredResult[i])
            if (!filteredScholarship.includes(filteredResult[i])) {
              filteredScholarship.push(filteredResult[i]);
            }
          }
        }

        const filteredResult1 = scholarships.filter((item) => {
          //return (item.majors == "All Majors Eligible")
          return item.location.includes("No Geographic Restrictions");
          //(item.majors.indexOf("All Majors Eligible") > 0);
        });
        for (let i = 0; i < filteredResult1.length; i++) {
          if (!filteredScholarship.includes(filteredResult1[i])) {
            filteredScholarship.push(filteredResult1[i]);
          }
        }

        return filteredScholarship;
      } else {
        filteredScholarship = scholarships;
        return filteredScholarship;
      }
    }
  }

  function majorFilter(scholarships, filters) {
    let filteredScholarship = [];
    if (filters.majorFilters.majors) {
      if (filters.majorFilters.majors.length > 0) {
        //console.log('categories inside ')
        for (let i = 0; i < filters.majorFilters.majors.length; i++) {
          // if(filters.categoryFilters.categories[i] == 'basedOnYourMajor'){

          // }
          const filteredResult = scholarships.filter((item) => {
            //console.log(item.location)
            return item.majors.indexOf(filters.majorFilters.majors[i]) > 0;
          });
          for (let i = 0; i < filteredResult.length; i++) {
            //console.log('h',filteredResult[i])
            if (!filteredScholarship.includes(filteredResult[i])) {
              filteredScholarship.push(filteredResult[i]);
            }
          }
        }
        const filteredResult1 = scholarships.filter((item) => {
          return item.majors == "All Majors Eligible";
          //(item.majors.indexOf("All Majors Eligible") > 0);
        });

        for (let i = 0; i < filteredResult1.length; i++) {
          //console.log('h',filteredResult[i])
          if (!filteredScholarship.includes(filteredResult1[i])) {
            filteredScholarship.push(filteredResult1[i]);
          }
        }

        return filteredScholarship;
      } else {
        filteredScholarship = scholarships;
        return filteredScholarship;
      }
    }
  }

  function gradeLevelFilter(scholarships, filters) {
    let filteredScholarship = [];
    if (filters.gradeLevelFilters.gradeLevels) {
      if (filters.gradeLevelFilters.gradeLevels.length > 0) {
        //console.log('categories inside ')
        for (let i = 0; i < filters.gradeLevelFilters.gradeLevels.length; i++) {
          // if(filters.categoryFilters.categories[i] == 'basedOnYourMajor'){

          // }
          const filteredResult = scholarships.filter((item) => {
            // console.log(item.stage)
            return item.stage.includes(
              filters.gradeLevelFilters.gradeLevels[i]
            );
          });
          for (let i = 0; i < filteredResult.length; i++) {
            //console.log('h',filteredResult[i])
            if (!filteredScholarship.includes(filteredResult[i])) {
              filteredScholarship.push(filteredResult[i]);
            }
          }
        }

        const filteredResult1 = scholarships.filter((item) => {
          return item.stage == "no restrictions";
          //(item.majors.indexOf("All Majors Eligible") > 0);
        });

        for (let i = 0; i < filteredResult1.length; i++) {
          //console.log('h',filteredResult[i])
          if (!filteredScholarship.includes(filteredResult1[i])) {
            filteredScholarship.push(filteredResult1[i]);
          }
        }

        return filteredScholarship;
      } else {
        filteredScholarship = scholarships;
        return filteredScholarship;
      }
    }
  }

  function citizenFilter(scholarships, filters) {
    let filteredScholarship = [];
    console.log("vhbcdnsma,", filters);
    if (filters.citizenshipFilters.citizenships) {
      if (filters.citizenshipFilters.citizenships.length > 0) {
        //console.log('categories inside ')
        for (
          let i = 0;
          i < filters.citizenshipFilters.citizenships.length;
          i++
        ) {
          // if(filters.categoryFilters.categories[i] == 'needBased'){
          //   // const resultNew = scholarships.slice(scholarships.length - 30, scholarships.length)

          //   const filteredResult =  scholarships.filter((item) => {
          //     const items = item.details.replaceAll(",", "").replaceAll(".", "").toLowerCase().split(' ')

          //     //console.log('need based',items)

          //     if (item.details.includes('financial')  &&item.details.includes('need') ){
          //       return (item)
          //     }

          //    //(item.majors.indexOf("All Majors Eligible") > 0);
          //  });
          //    console.log('need based scholarship', filteredResult)
          //    for(let i = 0; i < filteredResult.length; i++){
          //      if(!filteredScholarship.includes(filteredResult[i])){
          //        filteredScholarship.push(filteredResult[i])
          //      }

          //     }
          //  }
          const filteredResult = scholarships.filter((scholarship) => {
            const items = scholarship.details
              .replaceAll(",", "")
              .replaceAll(".", "")
              .toLowerCase()
              .split(" ");
            //console.log(items)

            if (items.includes("citizen")) {
              // console.log('l bnnm,,n',scholarship)
              // console.log(items.indexOf('citizen') )
              // console.log('cdiklwl;s',items.slice(items.indexOf('citizen') - 1, items.indexOf('citizen')).join())
              if (
                filters.citizenshipFilters.citizenships[i].split(" ").length > 1
              ) {
                const state =
                  filters.citizenshipFilters.citizenships[i].split(" ");
                // console.log('two scholarship', scholarship)
                // console.log('state', state[1].toLowerCase())
                return (
                  state[1].toLowerCase() ==
                  items
                    .slice(
                      items.indexOf("citizen") - 1,
                      items.indexOf("citizen")
                    )
                    .join()
                );

                // console.log(state[1].toLowerCase() == items.slice(items.indexOf('citizen') - 1, items.indexOf('citizen')).join())
              } else {
                const state =
                  filters.citizenshipFilters.citizenships[i].split(" ");
                // console.log(items.indexOf('citizen') )
                // console.log('two scholarship', scholarship)
                // console.log('state', state)
                // console.log('one ca',items.slice(items.indexOf('citizen') - 1, items.indexOf('citizen')).join())
                console.log(state[0].toLowerCase());
                console.log(
                  items
                    .slice(
                      items.indexOf("citizen") - 1,
                      items.indexOf("citizen")
                    )
                    .join()
                );
                return (
                  state[0].toLowerCase() ==
                  items
                    .slice(
                      items.indexOf("citizen") - 1,
                      items.indexOf("citizen")
                    )
                    .join()
                );
              }
              //return (scholarship)
            }
          });

          // console.log(filteredResult)

          for (let i = 0; i < filteredResult.length; i++) {
            if (!filteredScholarship.includes(filteredResult[i])) {
              filteredScholarship.push(filteredResult[i]);
            }
          }

          //return(filteredScholarship)

          // console.log('rfnkdmdnskslsrfd', filters.citizenshipFilters.citizenships[i])
          // return(scholarships)
        }
        console.log("filyhdksvf", filteredScholarship);
        //return(filteredScholarship)
      } else {
        console.log("we here");
        filteredScholarship = scholarships;
        //return(filteredScholarship)
      }
    }
    return "vgbnm,bfgh", filteredScholarship;
  }

  const handleScholarshipBookmark = async (event, data) => {
    console.log(data);
    event.preventDefault();

    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      //console.log(currentUser)
      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.post("/scholarshipbookmark", {
        scholarshipId: data.id,
        name: data.name,
        deadline: data.deadline,
        amount: data.amount ? data.amount : "NaN",
        details: data.details,
        link: data.link,
      });

      //const eventReminder = await

      //console.log('gvhbnm vghcrcfghkhgb gghk gyhh ,',data.deadline)
      if (data.deadline) {
        const eventReminder = await client.post("/event", {
          itemId: data.id,
          name: data.name,
          link: data.link,
          date: data.deadline,
          eventType: "scholarship",
        });

        //console.log(res)
        console.log("event reminder", eventReminder);
      }

      getScholarshipBookmarks();
    } catch (error) {
      console.log(error);
    }
  };

  const getScholarshipBookmarks = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      //console.log(currentUser)
      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.get("/scholarshipbookmark");
      console.log("scholarship bookmarks", res.data);
      setScholarshipBookmarks(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getScholarshipBookmarks();
  }, []);

  const deleteScholarshipBookmark = async (event, id) => {
    console.log("delete", event);
    console.log(id);
    event.preventDefault();

    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      //console.log(currentUser)
      const client = axios.create({
        baseURL: BASE_Server_URL,
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const res = await client.delete("/scholarshipbookmark/" + id);
      console.log(res);
      getScholarshipBookmarks();
    } catch (error) {
      console.log(error);
    }
  };

  function BookmarkChecker(Id, elem) {
    //console.log(coachBookmarks)
    // const filteredResult = coachBookmarks.filter((item) => {
    //   return (item.coachId.indexOf(coachId) > 0);

    //});
    const isObjectPresent = scholarshipBookmarks.find(
      (o) => o.scholarshipId === Id
    );
    if (isObjectPresent) {
      //console.log(isObjectPresent)
      return (
        //red love

        <img
          className="component-2-icon24"
          alt=""
          src="/component-23.svg"
          onClick={(e) => deleteScholarshipBookmark(e, elem.id)}
        />
      );
    } else {
      return (
        // white love

        <img
          className="component-2-icon32"
          alt=""
          src="/component-22.svg"
          onClick={(e) => handleScholarshipBookmark(e, elem)}
        />
      );
    }
  }

  function Sorting(sortType) {
    console.log("sort has been called", sortType);
    if (sortType === "latest") {
      return (filteredScholarship) =>
        [...filteredScholarship].sort((a, b) => a.createdAt - b.createdAt);
    }
    if (sortType === "amount") {
      return (filteredScholarship) =>
        [...filteredScholarship].sort((a, b) => b.amount - a.amount);
    }

    if (sortType === "deadline") {
      return (filteredScholarships) =>
        [...filteredScholarships].sort((a, b) => {
          const dateA = a.deadline ? new Date(a.deadline) : null;
          const dateB = b.deadline ? new Date(b.deadline) : null;
          const currentDate = new Date(); // Current date

          // Function to determine if a date is greater than or equal to current date
          const isDateGreaterThanOrEqualToCurrent = (date) => {
            return date && date >= currentDate;
          };

          // Check if both dates are not specified
          const isDeadlineNotSpecified = (date) => {
            return date === null;
          };

          // Conditions for sorting
          if (
            isDateGreaterThanOrEqualToCurrent(dateA) &&
            !isDateGreaterThanOrEqualToCurrent(dateB)
          ) {
            return -1; // dateA comes before dateB
          } else if (
            !isDateGreaterThanOrEqualToCurrent(dateA) &&
            isDateGreaterThanOrEqualToCurrent(dateB)
          ) {
            return 1; // dateB comes before dateA
          } else if (
            isDateGreaterThanOrEqualToCurrent(dateA) &&
            isDateGreaterThanOrEqualToCurrent(dateB)
          ) {
            // Both dates are >= current date, sort by deadline
            return dateA - dateB;
          } else if (
            isDeadlineNotSpecified(dateA) &&
            !isDeadlineNotSpecified(dateB)
          ) {
            return -1; // "not specified" should come before specified deadlines
          } else if (
            !isDeadlineNotSpecified(dateA) &&
            isDeadlineNotSpecified(dateB)
          ) {
            return 1; // specified deadlines should come after "not specified"
          } else if (!dateA && dateB) {
            return 1; // If only b has a deadline, b comes first
          } else if (dateA && !dateB) {
            return -1; // If only a has a deadline, a comes first
          } else if (
            !isDateGreaterThanOrEqualToCurrent(dateA) &&
            !isDateGreaterThanOrEqualToCurrent(dateB)
          ) {
            return dateA - dateB;
          } else {
            return 0; // If neither have deadlines or both are < current date, they are equal
          }
        });
    }

    if (sortType === "a-z") {
      //setFilteredScholarship((filteredScholarship) =>[...filteredScholarship].sort((a, b) => a.name- b.name))

      // setFilteredScholarship(
      //   filteredScholarship.sort(function (a, b) {
      //     if (a.name < b.name) {
      //       return -1;
      //     }
      //     if (a.name > b.name) {
      //       return 1;
      //     }
      //     return 0;
      //   })
      //   //(filteredScholarship) =>[...filteredScholarship].sort((a, b) => b.name- a.name)
      //   )

      //return(filteredScholarship.sort((a,b) =>  a.name.localeCompare(b.name)))
      return (filteredScholarship) =>
        [...filteredScholarship].sort((a, b) => a.name.localeCompare(b.name));

      //return((filteredScholarship) =>[...filteredScholarship].sort((a, b) => a.name> b.name))
      //setFilteredScholarship(filteredScholarship.sort())

      // return a.name.localeCompare(b.name); // Sort by name
    } else {
      return filteredScholarship;
    }
  }

  // Function to sort scholarships based on the chosen criteria
  function sortScholarships(sortBy, scholarships) {
    return scholarships.sort((a, b) => {
      if (sortBy === "a-z") {
        return a.name.localeCompare(b.name); // Sort by name
      } else if (sortBy === "deadline") {
        return new Date(a.deadline) - new Date(b.deadline); // Sort by deadline
      } else if (sortBy === "amount") {
        console.log("amount");
        return Number(a.amount) - Number(b.amount); // Sort by amount
      }
      // Handle other sorting options if needed...
    });
  }

  useEffect(() => {
    setFilteredScholarship(Sorting(sortType));
    //setFilteredScholarship(sortScholarships(sortType, filteredScholarship))
  }, [sortType]);

  function filterScholarships(filters, scholarships) {
    return scholarships.filter((scholarship) => {
      return (
        (filters.category.length === 0 ||
          filters.category.some((filterValue) =>
            scholarship.category.includes(filterValue)
          )) &&
        (filters.amount.length === 0 ||
          filters.amount.includes(scholarship.amount)) &&
        (filters.location.length === 0 ||
          filters.location.some((filterValue) =>
            scholarship.location.includes(filterValue)
          )) &&
        (filters.major.length === 0 ||
          filters.major.some((filterValue) =>
            scholarship.major.includes(filterValue)
          )) &&
        (filters.gradeLevel.length === 0 ||
          filters.gradeLevel.some((filterValue) =>
            scholarship.gradeLevel.includes(filterValue)
          )) &&
        (filters.citizenship.length === 0 ||
          filters.citizenship.some((filterValue) =>
            scholarship.citizenship.includes(filterValue)
          ))
      );
    });
  }

  // useEffect(() =>{
  //   if(!user){
  //     setFilteredScholarship(filteredScholarship.slice(0, 20))
  //   }
  // }, [])
  // const getMajorScholarships = async(scholarships) => {

  //   //get user
  //   //get users maor
  //   try {
  //     const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
  //     const currentUser = user && JSON.parse(user).currentUser;
  //     const TOKEN = currentUser?.tokens.access.token;

  //      const client = axios.create({
  //       baseURL: "http://localhost:3000/v1/",
  //       headers: { Authorization: `Bearer ${TOKEN}` },
  //   });

  //   const profile= await client.get(`/profile`);
  //   //console.log(profile)

  //       if(profile.data == ''){
  //         console.log('create data profile')
  //         // createUserProfile().then((data) => {
  //         //     console.log('data', data)
  //         //     setProfile(data)
  //         // })
  //     }

  //         if(profile.data.profileCompleted){
  //      // console.log('profile maor',profile.data.fieldOfStudy)
  //       //console.log(scholarships)
  //       const filteredResult1 =  scholarships.filter((item) => {
  //         return (item.majors == "All Majors Eligible")
  //         //(item.majors.indexOf("All Majors Eligible") > 0);
  //       });
  //      // console.log('profiles',filteredResult1)

  //         const filteredResult2 = scholarships.filter((item) => {
  //           return (item.majors.indexOf(profile.data.fieldOfStudy) > 0);
  //         });

  //         const filteredResult = [...filteredResult1, ...filteredResult2]
  //         //console.log('profule filter',filteredResult)

  //         return(filteredResult)
  //        // navigate('/dashboard')
  //     }

  //   } catch (error) {

  //   }

  //   // const client = axios.create({
  //   //     baseURL: "http://localhost:3000/v1/",
  //   //     headers: { Authorization: `Bearer ${TOKEN}` },
  //   // });

  //   // console.log(currentUser)
  //   // console.log(userRequest)

  //   // const res = client.get("/profile")
  //   // .then((profile) => {
  //   //   console.log('profile',profile.data)
  //   //   if(profile.data == ''){
  //   //       console.log('create data profile')
  //   //       // createUserProfile().then((data) => {
  //   //       //     console.log('data', data)
  //   //       //     setProfile(data)
  //   //       // })
  //   //   }

  //   //   if(profile.data.profileCompleted){
  //   //     console.log('profile maor',profile.data.fieldOfStudy)
  //   //     console.log(scholarships)
  //   //     const filteredResult1 =  scholarships.filter((item) => {
  //   //       return (item.majors == "All Majors Eligible")
  //   //       //(item.majors.indexOf("All Majors Eligible") > 0);
  //   //     });
  //   //    // console.log('profiles',filteredResult1)

  //   //       const filteredResult2 = scholarships.filter((item) => {
  //   //         return (item.majors.indexOf(profile.data.fieldOfStudy) > 0);
  //   //       });

  //   //       const filteredResult = [...filteredResult1, ...filteredResult2]
  //   //       //console.log('profule filter',filteredResult)

  //   //       return(filteredResult)
  //   //      // navigate('/dashboard')
  //   //   }
  //   //   else{
  //   //      console.log('profile not completed')
  //   //   }

  //   // }).catch(err => {
  //   //   console.log(err)
  //   // })

  //   //return(res)
  // //   const filteredResult = scholarships.filter((item) => {
  // //     return (item.majors.indexOf('user mahor') > 0);
  // // });

  // }

  return (
    <>
      <div className="scholarships-category-page">
        <div className="search8">
          <div className="header10">{FilteredScholarshipNav()}</div>
          {/* <UseScholarshipFilters /> */}

          {render}
        </div>

        {DisplayProfileOrSearch(
          search,
          categoryFilters,
          amountFilters,
          gradeLevelFilters,
          locationFilters,
          majorFilters,
          citizenshipFilters
        )}

        {/* {BasedOnYoutProfile(search, categoryFilters, amountFilters, gradeLevelFilters, locationFilters, majorFilters, citizenshipFilters)} */}

        <div className="footer11">
          <div className="footer-grid-row11">
            <div className="footer-menu55">
              <img
                className="footerlogo-icon11"
                alt=""
                src="/footerlogo1.svg"
              />
              <div className="footer-links55">
                <div className="footerlinknav33">
                  <div className="nav-link33">info@example.com</div>
                </div>
                <div className="footerlinknav33">
                  <div className="nav-link33">+1 234 567 9830</div>
                </div>
                <div className="footerlinknav33">
                  <div className="nav-link33">Address</div>
                </div>
              </div>
              <div className="icon-wrapper11">
                <img
                  className="footericoninstagram11"
                  alt=""
                  src="/footericoninstagram.svg"
                />
                <img
                  className="footericonfacbook11"
                  alt=""
                  src="/footericonfacbook.svg"
                />
                <img
                  className="footericontwitter11"
                  alt=""
                  src="/footericontwitter.svg"
                />
                <img
                  className="footericonfacbook11"
                  alt=""
                  src="/footericonlinkedin.svg"
                />
                <img
                  className="footericonyoutube11"
                  alt=""
                  src="/footericonyoutube1.svg"
                />
              </div>
            </div>
            <div className="footer-menu-parent9">
              <div className="footer-menu56">
                <div className="students11">Students</div>
                <div className="footer-links55">
                  <div className="main-footer-link143">
                    <div className="footer-link143">How it works</div>
                  </div>
                  <div className="main-footer-link143">
                    <div className="footer-link143">Scholarships</div>
                  </div>
                  <div className="main-footer-link143">
                    <div className="footer-link143">Community</div>
                  </div>
                  <div className="main-footer-link143">
                    <div className="footer-link143">Services</div>
                  </div>
                </div>
              </div>
              <div className="footer-menu56">
                <div className="students11">Coaches</div>
                <div className="footer-links55">
                  <div className="main-footer-link143">
                    <div className="footer-link143">How it works</div>
                  </div>
                  <div className="main-footer-link143">
                    <div className="footer-link143">Blog</div>
                  </div>
                  <div className="main-footer-link143">
                    <div className="footer-link143">Mission</div>
                  </div>
                  <div className="main-footer-link143">
                    <div className="footer-link143">Footer Link</div>
                  </div>
                </div>
              </div>
              <div className="footer-menu56">
                <div className="students11">Company</div>
                <div className="footer-links55">
                  <div className="main-footer-link143">
                    <div className="footer-link143">About Us</div>
                  </div>
                  <div className="main-footer-link143">
                    <div className="footer-link143">Partners</div>
                  </div>
                  <div className="main-footer-link143">
                    <div className="footer-link143">Careers</div>
                  </div>
                  <div className="main-footer-link143">
                    <div className="footer-link143">Contact</div>
                  </div>
                </div>
              </div>
              <div className="footer-menu56">
                <div className="students11">Join our Email List</div>
                <div className="footer-links59">
                  <div className="main-footer-link155">
                    <div className="footer-link155">
                      Be the first to know about new scholarships, events,
                      workshops and much more!
                    </div>
                  </div>
                  <div className="subscribe-email-text-box11">
                    <div className="text-box10" />
                    <div className="input-placeholder10">Email</div>
                  </div>
                  <div className="buttonlarge32">
                    <div className="button48" />
                    <div className="button-text83">Sign Up</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-legal11">
            <div className="scholist-all-rights11">
              © 2022 Scholist. All rights reserved.
            </div>
            <div className="scholist-all-rights11">Disclaimer Cookies</div>
          </div>
        </div>
      </div>
      {isScholarshipFiltersOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom"
          onOutsideClick={closeScholarshipFilters}
        >
          <ScholarshipFilters onClose={closeScholarshipFilters} />
        </PortalDrawer>
      )}
    </>
  );
};

export default BasedOnProfile;
