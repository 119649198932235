import axios from "axios";
import "./ProfileForm.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ProfileForm = () => {
  const navigate = useNavigate();
  const [coach, setCoach] = useState({
    hourlyRate: "",
    bio: "",
    expertise: "",
    location: "",
  });

  const [file, setFile] = useState(null);
  const [serviceNumber, setServiceNumber] = useState(1);
  const [numberOfForms, setNumberOfForms] = useState(1);
  const [services, setServices] = useState([]);
  const [service, setService] = useState({});

  const [educations, setEducations] = useState([]);
  const [education, setEducation] = useState({});

  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState("");

  const [policies, setPolicies] = useState([]);
  const [policie, setPolicie] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCoach((prevCoach) => ({
      ...prevCoach,
      [name]: value,
    }));
  };

  const handleChangeService = (e) => {
    const { name, value } = e.target;
    setService((prevService) => ({
      ...prevService,
      [name]: value,
    }));
  };

  const handleChangeEducation = (e) => {
    const { name, value } = e.target;
    setEducation((prevEducation) => ({
      ...prevEducation,
      [name]: value,
    }));
  };

  const handleChangeLanguage = (e) => {
    const { name, value } = e.target;
    setLanguage(value);
  };

  const handleChangePolicie = (e) => {
    const { name, value } = e.target;
    setPolicie(value);
  };

  function addNewService() {
    console.log("sevice", service);

    setServices((prevServices) => [...prevServices, service]);
    //add to array services
    //setNumberOfForms((prev) => prev + 1)

    //clear service
  }

  useEffect(() => {
    console.log("services", services);
    setService({
      name: "",
      details: "",
      duration: "",
      link: "",
      price: "",
    });
  }, [services]);

  function addNewEducation() {
    setEducations((prevEducations) => [...prevEducations, education]);
    //add to array services
    //setNumberOfForms((prev) => prev + 1)

    //clear service
  }

  useEffect(() => {
    console.log("educations", educations);
    setEducation({
      abbreviation: "",
      course: "",
      universityName: "",
    });
  }, [educations]);

  function addNewLanguage() {
    console.log("language", language);

    setLanguages((prevLang) => [...prevLang, language]);
    //add to array services
    //setNumberOfForms((prev) => prev + 1)

    //clear service
  }

  useEffect(() => {
    console.log("languages", languages);
    setLanguage("");
  }, [languages]);

  function addNewPolicies() {
    console.log("policy", policie);

    setPolicies((prevPol) => [...prevPol, policie]);
    //add to array services
    //setNumberOfForms((prev) => prev + 1)

    //clear service
  }

  useEffect(() => {
    console.log("policys", policies);
    setPolicie("");
  }, [policies]);

  // Function to handle form submission
  //   const handleSubmit = (e) => {
  //     e.preventDefault();

  //     // Send the coach object to your backend or perform desired actions
  //     console.log(coach);
  //   };

  const upload = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "scholist");

    try {
      const res = await axios.post(
        "https://api.cloudinary.com/v1_1/db82bjix0/image/upload",
        data
      );

      const { url } = res.data;
      return url;
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = await upload(file);
    try {
      const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
      const currentUser = user && JSON.parse(user).currentUser;
      const TOKEN = currentUser?.tokens.access.token;

      const client = axios.create({
        baseURL: "http://localhost:3000/v1/",
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      await client.post("/coach", {
        ...coach,
        img: url,
        services: services,
        education: educations,
        languages: languages,
        policies: policies,
      });

      navigate("/welcome");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="coach-application-form">
        <div class="container">
          <img
            className="headerlogo-icon7"
            alt=""
            style={{ marginLeft: "26%", marginTop: "1.5%" }}
            src="/Logo Bird Vector.svg"
            //onClick={onButtonHomeClick}
          />
          <h1 class="headerText">Scholist Coach Application</h1>
        </div>
        <div>
          <label>
            Image:
            {/* <input type="text" name="img" value={coach.img} onChange={handleChange} /> */}
            <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          </label>
        </div>

        <div>
          <label>
            bio:
            <input
              type="text"
              name="bio"
              value={coach.bio}
              onChange={handleChange}
            />
          </label>
        </div>

        <div>
          <label>
            expertise:
            <input
              type="text"
              name="expertise"
              value={coach.expertise}
              onChange={handleChange}
            />
          </label>
        </div>

        <div>
          <label>
            location:
            <input
              type="text"
              name="location"
              value={coach.location}
              onChange={handleChange}
            />
          </label>
        </div>

        <div>
          <label>
            hourlyRate:
            <input
              type="text"
              name="hourlyRate"
              value={coach.hourlyRate}
              onChange={handleChange}
            />
          </label>
        </div>

        {/* Repeat the above pattern for other fields */}
        <div>
          {services.map((service, index) => (
            <div key={index}>
              <p>name: {service.name}</p>
              <p>details: {service.details}</p>
              <p>price: {service.price}</p>
              <p>duration: {service.duration}</p>
              <p>link: {service.bookingLink}</p>
            </div>
          ))}
        </div>

        <div>
          <label>
            Services:
            <div>
              <input
                type="text"
                name="name"
                placeholder="Service Name"
                value={service.name}
                onChange={handleChangeService}
              />
              <input
                type="text"
                name="details"
                placeholder="Service Details"
                value={service.details}
                onChange={handleChangeService}
              />
              <input
                type="number"
                name="price"
                placeholder="Service Price"
                value={service.price}
                onChange={handleChangeService}
              />
              <input
                type="number"
                name="duration"
                placeholder="Service Duration"
                value={service.duration}
                onChange={handleChangeService}
              />
              <input
                type="text"
                name="bookingLink"
                placeholder="Booking Link"
                value={service.bookingLink}
                onChange={handleChangeService}
              />
            </div>
            <div style={{ width: "100px" }} onClick={() => addNewService()}>
              Add Form
            </div>
          </label>
        </div>

        <div>
          {educations.map((education, index) => (
            <div key={index}>
              <p>abbreviation: {education.abbreviation}</p>
              <p>course: {education.course}</p>
              <p>universityName: {education.universityName}</p>
            </div>
          ))}
        </div>

        <div>
          <label>
            Educations:
            <div>
              <input
                type="text"
                name="abbreviation"
                placeholder="abbreviation"
                value={education.abbreviation}
                onChange={handleChangeEducation}
              />
              <input
                type="text"
                name="course"
                placeholder="course"
                value={education.course}
                onChange={handleChangeEducation}
              />
              <input
                type="text"
                name="universityName"
                placeholder="university name"
                value={education.universityName}
                onChange={handleChangeEducation}
              />
            </div>
            <div onClick={() => addNewEducation()}>Add Form</div>
          </label>
        </div>

        <div>
          {Array.isArray(languages) &&
            languages.map((language, index) => (
              <div key={index}>
                <p>language: {language}</p>
              </div>
            ))}
        </div>

        <div>
          <label>
            Languages:
            <div>
              <input
                type="text"
                name="language"
                placeholder="language"
                value={language}
                onChange={handleChangeLanguage}
              />
            </div>
            <div onClick={() => addNewLanguage()}>Add Form</div>
          </label>
        </div>

        <div>
          {Array.isArray(policies) &&
            policies.map((policie, index) => (
              <div key={index}>
                <p>policie: {policie}</p>
              </div>
            ))}
        </div>

        <div>
          <label>
            Policies:
            <div>
              <input
                type="text"
                name="policie"
                placeholder="policie"
                value={policie}
                onChange={handleChangePolicie}
              />
            </div>
            <div onClick={() => addNewPolicies()}>Add Form</div>
          </label>
        </div>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ProfileForm;
