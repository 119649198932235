import { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
//import { Checkbox } from "antd";
import "./ScholarshipFilters.css";
import { BASE_Server_URL } from "../requestMethods";

import debounce from "lodash.debounce";
import axios from "axios";

const allCategories = [
  {
    name: "Based On Your Profile",
    value: "basedOnYourProfile",
    checked: false,
  },
  { name: "Based On Your Major", value: "basedOnYourMajor", checked: false },
  { name: "Popular", value: "popular", checked: false },
  { name: "New", value: "new", checked: false },
  { name: "Need Based", value: "needBased", checked: false },
];

const allAwardAmounts = [
  { name: "$0 - $1000", value: "$0 - $1000", checked: false },
  { name: "$1001 - $2500", value: "$1001 - $2500", checked: false },
  { name: "$2501 - $5000", value: "$2501 - $5000", checked: false },
  { name: "$5001 - $10000", value: "$5001 - $10000", checked: false },
  { name: "> $10000", value: "> $10000", checked: false },
];

//load all locations from database
var allLocations = [
  { name: "United Kingdom", value: "unitedkingdom", checked: false },
  { name: "United States", value: "United States", checked: false },
  { name: "Nigeria", value: "nigeria", checked: false },
  { name: "Canada", value: "canadian", checked: false },
  { name: "Mexico", value: "mexican", checked: false },
  { name: "France", value: "france", checked: false },
  { name: "Germany", value: "germany", checked: false },
];
// const allMajors = [
//   { name: "Business Administration", value: "Business Administration", checked: false },
//   { name: "Information Technology", value: "Information Technology", checked: false },
//   { name: "Mathematics", value: "Mathematics", checked: false },
//   { name: "English",  value: "English", checked: false },
//   { name: "Computer Engineering", value: "Computer Engineering", checked: false },
//   { name: "Physics", value: "Physics", checked: false },
//   { name: "Pharmacy", value: "Pharmacy", checked: false },
//   { name: "Chemistry", value: "Chemistry", checked: false }

// ]

const allGradeLevels = [
  { name: "High School", value: "High School", checked: false },
  { name: "Bachelors", value: "Undergraduate", checked: false },
  { name: "Associate", value: "associate", checked: false },
  { name: "Masters", value: "Graduate", checked: false },
  { name: "PHD", value: "Phd", checked: false },
  { name: "Trade School", value: "trade school", checked: false },
];

// const allCitizenships = [
//   { name: "United Kingdom", value: "United Kingdom", checked: false },
//   { name: "United States", value: "United States", checked: false },
//   { name: "Nigeria", value: "Nigeria", checked: false },
//   { name: "Canada",  value: "Canadian", checked: false },
//   { name: "Mexico", value: "Mexican", checked: false },
//   { name: "France", value: "France", checked: false },
//   { name: "Germany", value: "Germany", checked: false }

// ]
const allCitizenships = [
  { name: "Afghanistan", value: "Afghan", checked: false },
  { name: "Albania", value: "Albanian", checked: false },
  { name: "Algeria", value: "Algerian", checked: false },
  { name: "Andorra", value: "Andorran", checked: false },
  { name: "Angola", value: "Angolan", checked: false },
  { name: "Antigua and Barbuda", value: "Antiguan, Barbudan", checked: false },
  { name: "Argentina", value: "Argentine", checked: false },
  { name: "Armenia", value: "Armenian", checked: false },
  { name: "Australia", value: "Australian", checked: false },
  { name: "Austria", value: "Austrian", checked: false },
  { name: "Azerbaijan", value: "Azerbaijani", checked: false },
  { name: "Bahamas", value: "Bahamian", checked: false },
  { name: "Bahrain", value: "Bahraini", checked: false },
  { name: "Bangladesh", value: "Bangladeshi", checked: false },
  { name: "Barbados", value: "Barbadian", checked: false },
  { name: "Belarus", value: "Belarusian", checked: false },
  { name: "Belgium", value: "Belgian", checked: false },
  { name: "Belize", value: "Belizean", checked: false },
  { name: "Benin", value: "Beninese", checked: false },
  { name: "Bhutan", value: "Bhutanese", checked: false },
  { name: "Bolivia", value: "Bolivian", checked: false },
  {
    name: "Bosnia and Herzegovina",
    value: "Bosnian, Herzegovinian",
    checked: false,
  },
  { name: "Botswana", value: "Botswana", checked: false },
  { name: "Brazil", value: "Brazilian", checked: false },
  { name: "Brunei", value: "Bruneian", checked: false },
  { name: "Bulgaria", value: "Bulgarian", checked: false },
  { name: "Burkina Faso", value: "Burkinabé", checked: false },
  { name: "Burundi", value: "Burundian", checked: false },
  { name: "Cabo Verde", value: "Cabo Verdean", checked: false },
  { name: "Cambodia", value: "Cambodian", checked: false },
  { name: "Cameroon", value: "Cameroonian", checked: false },
  { name: "Canada", value: "Canadian", checked: false },
  {
    name: "Central African Republic",
    value: "Central African",
    checked: false,
  },
  { name: "Chad", value: "Chadian", checked: false },
  { name: "Chile", value: "Chilean", checked: false },
  { name: "China", value: "Chinese", checked: false },
  { name: "Colombia", value: "Colombian", checked: false },
  { name: "Comoros", value: "Comorian", checked: false },
  { name: "Congo (Congo-Brazzaville)", value: "Congolese", checked: false },
  { name: "Costa Rica", value: "Costa Rican", checked: false },
  { name: "Croatia", value: "Croatian", checked: false },
  { name: "Cuba", value: "Cuban", checked: false },
  { name: "Cyprus", value: "Cypriot", checked: false },
  { name: "Czechia (Czech Republic)", value: "Czech", checked: false },
  { name: "Denmark", value: "Danish", checked: false },
  { name: "Djibouti", value: "Djiboutian", checked: false },
  { name: "Dominica", value: "Dominican", checked: false },
  { name: "Dominican Republic", value: "Dominican", checked: false },
  { name: "Ecuador", value: "Ecuadorian", checked: false },
  { name: "Egypt", value: "Egyptian", checked: false },
  { name: "El Salvador", value: "Salvadoran", checked: false },
  { name: "Equatorial Guinea", value: "Equatorial Guinean", checked: false },
  { name: "Eritrea", value: "Eritrean", checked: false },
  { name: "Estonia", value: "Estonian", checked: false },
  { name: 'Eswatini (fmr. "Swaziland")', value: "Swazi", checked: false },
  { name: "Ethiopia", value: "Ethiopian", checked: false },
  { name: "Fiji", value: "Fijian", checked: false },
  { name: "Finland", value: "Finnish", checked: false },
  { name: "France", value: "French", checked: false },
  { name: "Gabon", value: "Gabonese", checked: false },
  { name: "Gambia", value: "Gambian", checked: false },
  { name: "Georgia", value: "Georgian", checked: false },
  { name: "Germany", value: "German", checked: false },
  { name: "Ghana", value: "Ghanaian", checked: false },
  { name: "Greece", value: "Greek", checked: false },
  { name: "Grenada", value: "Grenadian", checked: false },
  { name: "Guatemala", value: "Guatemalan", checked: false },
  { name: "Guinea", value: "Guinean", checked: false },
  { name: "Guinea-Bissau", value: "Guinea-Bissauan", checked: false },
  { name: "Guyana", value: "Guyanese", checked: false },
  { name: "Haiti", value: "Haitian", checked: false },
  { name: "Holy See", value: "Vatican", checked: false },
  { name: "Honduras", value: "Honduran", checked: false },
  { name: "Hungary", value: "Hungarian", checked: false },
  { name: "Iceland", value: "Icelandic", checked: false },
  { name: "India", value: "Indian", checked: false },
  { name: "Indonesia", value: "Indonesian", checked: false },
  { name: "Iran", value: "Iranian", checked: false },
  { name: "Iraq", value: "Iraqi", checked: false },
  { name: "Ireland", value: "Irish", checked: false },
  { name: "Israel", value: "Israeli", checked: false },
  { name: "Italy", value: "Italian", checked: false },
  { name: "Jamaica", value: "Jamaican", checked: false },
  { name: "Japan", value: "Japanese", checked: false },
  { name: "Jordan", value: "Jordanian", checked: false },
  { name: "Kazakhstan", value: "Kazakhstani", checked: false },
  { name: "Kenya", value: "Kenyan", checked: false },
  { name: "Kiribati", value: "I-Kiribati", checked: false },
  { name: "Korea, North", value: "North Korean", checked: false },
  { name: "Korea, South", value: "South Korean", checked: false },
  { name: "Kosovo", value: "Kosovar", checked: false },
  { name: "Kuwait", value: "Kuwaiti", checked: false },
  { name: "Kyrgyzstan", value: "Kyrgyzstani", checked: false },
  { name: "Laos", value: "Laotian", checked: false },
  { name: "Latvia", value: "Latvian", checked: false },
  { name: "Lebanon", value: "Lebanese", checked: false },
  { name: "Lesotho", value: "Basotho", checked: false },
  { name: "Liberia", value: "Liberian", checked: false },
  { name: "Libya", value: "Libyan", checked: false },
  { name: "Liechtenstein", value: "Liechtensteiner", checked: false },
  { name: "Lithuania", value: "Lithuanian", checked: false },
  { name: "Luxembourg", value: "Luxembourger", checked: false },
  { name: "Madagascar", value: "Malagasy", checked: false },
  { name: "Malawi", value: "Malawian", checked: false },
  { name: "Malaysia", value: "Malaysian", checked: false },
  { name: "Maldives", value: "Maldivian", checked: false },
  { name: "Mali", value: "Malian", checked: false },
  { name: "Malta", value: "Maltese", checked: false },
  { name: "Marshall Islands", value: "Marshallese", checked: false },
  { name: "Mauritania", value: "Mauritanian", checked: false },
  { name: "Mauritius", value: "Mauritian", checked: false },
  { name: "Mexico", value: "Mexican", checked: false },
  { name: "Micronesia", value: "Micronesian", checked: false },
  { name: "Moldova", value: "Moldovan", checked: false },
  { name: "Monaco", value: "Monegasque", checked: false },
  { name: "Mongolia", value: "Mongolian", checked: false },
  { name: "Montenegro", value: "Montenegrin", checked: false },
  { name: "Morocco", value: "Moroccan", checked: false },
  { name: "Mozambique", value: "Mozambican", checked: false },
  { name: "Myanmar (formerly Burma)", value: "Burmese", checked: false },
  { name: "Namibia", value: "Namibian", checked: false },
  { name: "Nauru", value: "Nauruan", checked: false },
  { name: "Nepal", value: "Nepali", checked: false },
  { name: "Netherlands", value: "Dutch", checked: false },
  { name: "New Zealand", value: "New Zealander", checked: false },
  { name: "Nicaragua", value: "Nicaraguan", checked: false },
  { name: "Niger", value: "Nigerien", checked: false },
  { name: "Nigeria", value: "Nigerian", checked: false },
  { name: "North Macedonia", value: "Macedonian", checked: false },
  { name: "Norway", value: "Norwegian", checked: false },
  { name: "Oman", value: "Omani", checked: false },
  { name: "Pakistan", value: "Pakistani", checked: false },
  { name: "Palau", value: "Palauan", checked: false },
  { name: "Palestine State", value: "Palestinian", checked: false },
  { name: "Panama", value: "Panamanian", checked: false },
  { name: "Papua New Guinea", value: "Papua New Guinean", checked: false },
  { name: "Paraguay", value: "Paraguayan", checked: false },
  { name: "Peru", value: "Peruvian", checked: false },
  { name: "Philippines", value: "Filipino", checked: false },
  { name: "Poland", value: "Polish", checked: false },
  { name: "Portugal", value: "Portuguese", checked: false },
  { name: "Qatar", value: "Qatari", checked: false },
  { name: "Romania", value: "Romanian", checked: false },
  { name: "Russia", value: "Russian", checked: false },
  { name: "Rwanda", value: "Rwandan", checked: false },
  {
    name: "Saint Kitts and Nevis",
    value: "Kittitian, Nevisian",
    checked: false,
  },
  { name: "Saint Lucia", value: "Saint Lucian", checked: false },
  {
    name: "Saint Vincent and the Grenadines",
    value: "Saint Vincentian",
    checked: false,
  },
  { name: "Samoa", value: "Samoan", checked: false },
  { name: "San Marino", value: "Sammarinese", checked: false },
  { name: "Sao Tome and Principe", value: "São Toméan", checked: false },
  { name: "Saudi Arabia", value: "Saudi", checked: false },
  { name: "Senegal", value: "Senegalese", checked: false },
  { name: "Serbia", value: "Serbian", checked: false },
  { name: "Seychelles", value: "Seychellois", checked: false },
  { name: "Sierra Leone", value: "Sierra Leonean", checked: false },
  { name: "Singapore", value: "Singaporean", checked: false },
  { name: "Slovakia", value: "Slovak", checked: false },
  { name: "Slovenia", value: "Slovenian", checked: false },
  { name: "Solomon Islands", value: "Solomon Islander", checked: false },
  { name: "Somalia", value: "Somali", checked: false },
  { name: "South Africa", value: "South African", checked: false },
  { name: "South Sudan", value: "South Sudanese", checked: false },
  { name: "Spain", value: "Spanish", checked: false },
  { name: "Sri Lanka", value: "Sri Lankan", checked: false },
  { name: "Sudan", value: "Sudanese", checked: false },
  { name: "Suriname", value: "Surinamese", checked: false },
  { name: "Sweden", value: "Swedish", checked: false },
  { name: "Switzerland", value: "Swiss", checked: false },
  { name: "Syria", value: "Syrian", checked: false },
  { name: "Taiwan", value: "Taiwanese", checked: false },
  { name: "Tajikistan", value: "Tajikistani", checked: false },
  { name: "Tanzania", value: "Tanzanian", checked: false },
  { name: "Thailand", value: "Thai", checked: false },
  { name: "Timor-Leste", value: "East Timorese", checked: false },
  { name: "Togo", value: "Togolese", checked: false },
  { name: "Tonga", value: "Tongan", checked: false },
  {
    name: "Trinidad and Tobago",
    value: "Trinidadian or Tobagonian",
    checked: false,
  },
  { name: "Tunisia", value: "Tunisian", checked: false },
  { name: "Turkey", value: "Turkish", checked: false },
  { name: "Turkmenistan", value: "Turkmen", checked: false },
  { name: "Tuvalu", value: "Tuvaluan", checked: false },
  { name: "Uganda", value: "Ugandan", checked: false },
  { name: "Ukraine", value: "Ukrainian", checked: false },
  { name: "United Arab Emirates", value: "Emirati", checked: false },
  { name: "United Kingdom", value: "British", checked: false },
  { name: "United States", value: "United States", checked: false },
  { name: "Uruguay", value: "Uruguayan", checked: false },
  { name: "Uzbekistan", value: "Uzbekistani", checked: false },
  { name: "Vanuatu", value: "Ni-Vanuatu", checked: false },
  { name: "Venezuela", value: "Venezuelan", checked: false },
  { name: "Vietnam", value: "Vietnamese", checked: false },
  { name: "Yemen", value: "Yemeni", checked: false },
  { name: "Zambia", value: "Zambian", checked: false },
  { name: "Zimbabwe", value: "Zimbabwean", checked: false },
];

const allMajors = [
  // { name: "Business Administration", value: "Business Administration", checked: false },
  // { name: "Information Technology", value: "Information Technology", checked: false },
  // { name: "Mathematics", value: "Mathematics", checked: false },
  // { name: "English",  value: "English", checked: false },
  // { name: "Computer Engineering", value: "Computer Engineering", checked: false },
  // { name: "Physics", value: "Physics", checked: false },
  // { name: "Pharmacy", value: "Pharmacy", checked: false },
  // { name: "Chemistry", value: "Chemistry", checked: false }
  {
    name: "All-Majors",
    value: "All Majors Eligible",
    checked: false,
  },
  {
    name: "Accounting",
    value: "Accounting",
    checked: false,
  },
  {
    name: "Actuarial Science",
    value: "Actuarial Science",
    checked: false,
  },
  {
    name: "Advertising",
    value: "Advertising",
    checked: false,
  },
  {
    name: "Aerospace Engineering",
    value: "Aerospace Engineering",
    checked: false,
  },
  {
    name: "African Languages, Literatures, and Linguistics",
    value: "African Languages, Literatures, and Linguistics",
    checked: false,
  },
  {
    name: "African Studies",
    value: "African Studies",
    checked: false,
  },
  {
    name: "African-American Studies",
    value: "African-American Studies",
    checked: false,
  },
  {
    name: "Agricultural Business and Management",
    value: "Agricultural Business and Management",
    checked: false,
  },
  {
    name: "Agricultural Economics",
    value: "Agricultural Economics",
    checked: false,
  },
  {
    name: "Agricultural Education",
    value: "Agricultural Education",
    checked: false,
  },
  {
    name: "Agricultural Journalism",
    value: "Agricultural Journalism",
    checked: false,
  },
  {
    name: "Agricultural Mechanization",
    value: "Agricultural Mechanization",
    checked: false,
  },
  {
    name: "Agricultural Technology Management",
    value: "Agricultural Technology Management",
    checked: false,
  },
  {
    name: "Agricultural/Biological Engineering and Bioengineering",
    value: "Agricultural/Biological Engineering and Bioengineering",
    checked: false,
  },
  {
    name: "Agriculture",
    value: "Agriculture",
    checked: false,
  },
  {
    name: "Agronomy and Crop Science",
    value: "Agronomy and Crop Science",
    checked: false,
  },
  {
    name: "Air Traffic Control",
    value: "Air Traffic Control",
    checked: false,
  },
  {
    name: "American History",
    value: "American History",
    checked: false,
  },
  {
    name: "American Literature",
    value: "American Literature",
    checked: false,
  },
  {
    name: "American Sign Language",
    value: "American Sign Language",
    checked: false,
  },
  {
    name: "American Studies",
    value: "American Studies",
    checked: false,
  },
  {
    name: "Anatomy",
    value: "Anatomy",
    checked: false,
  },
  {
    name: "Ancient Studies",
    value: "Ancient Studies",
    checked: false,
  },
  {
    name: "Animal Behavior and Ethology",
    value: "Animal Behavior and Ethology",
    checked: false,
  },
  {
    name: "Animal Science",
    value: "Animal Science",
    checked: false,
  },
  {
    name: "Animation and Special Effects",
    value: "Animation and Special Effects",
    checked: false,
  },
  {
    name: "Anthropology",
    value: "Anthropology",
    checked: false,
  },
  {
    name: "Applied Mathematics",
    value: "Applied Mathematics",
    checked: false,
  },
  {
    name: "Aquaculture",
    value: "Aquaculture",
    checked: false,
  },
  {
    name: "Aquatic Biology",
    value: "Aquatic Biology",
    checked: false,
  },
  {
    name: "Arabic",
    value: "Arabic",
    checked: false,
  },
  {
    name: "Archeology",
    value: "Archeology",
    checked: false,
  },
  {
    name: "Architectural Engineering",
    value: "Architectural Engineering",
    checked: false,
  },
  {
    name: "Architectural History",
    value: "Architectural History",
    checked: false,
  },
  {
    name: "Architecture",
    value: "Architecture",
    checked: false,
  },
  {
    name: "Art",
    value: "Art",
    checked: false,
  },
  {
    name: "Art Education",
    value: "Art Education",
    checked: false,
  },
  {
    name: "Art History",
    value: "Art History",
    checked: false,
  },
  {
    name: "Art Therapy",
    value: "Art Therapy",
    checked: false,
  },
  {
    name: "Artificial Intelligence and Robotics",
    value: "Artificial Intelligence and Robotics",
    checked: false,
  },
  {
    name: "Asian-American Studies",
    value: "Asian-American Studies",
    checked: false,
  },
  {
    name: "Astronomy",
    value: "Astronomy",
    checked: false,
  },
  {
    name: "Astrophysics",
    value: "Astrophysics",
    checked: false,
  },
  {
    name: "Athletic Training",
    value: "Athletic Training",
    checked: false,
  },
  {
    name: "Atmospheric Science",
    value: "Atmospheric Science",
    checked: false,
  },
  {
    name: "Automotive Engineering",
    value: "Automotive Engineering",
    checked: false,
  },
  {
    name: "Aviation",
    value: "Aviation",
    checked: false,
  },
  {
    name: "Bakery Science",
    value: "Bakery Science",
    checked: false,
  },
  {
    name: "Biblical Studies",
    value: "Biblical Studies",
    checked: false,
  },
  {
    name: "Biochemistry",
    value: "Biochemistry",
    checked: false,
  },
  {
    name: "Bioethics",
    value: "Bioethics",
    checked: false,
  },
  {
    name: "Biology",
    value: "Biology",
    checked: false,
  },
  {
    name: "Biomedical Engineering",
    value: "Biomedical Engineering",
    checked: false,
  },
  {
    name: "Biomedical Science",
    value: "Biomedical Science",
    checked: false,
  },
  {
    name: "Biopsychology",
    value: "Biopsychology",
    checked: false,
  },
  {
    name: "Biotechnology",
    value: "Biotechnology",
    checked: false,
  },
  {
    name: "Botany/Plant Biology",
    value: "Botany/Plant Biology",
    checked: false,
  },
  {
    name: "Business Administration/Management",
    value: "Business Administration/Management",
    checked: false,
  },
  {
    name: "Business Communications",
    value: "Business Communications",
    checked: false,
  },
  {
    name: "Business Education",
    value: "Business Education",
    checked: false,
  },
  {
    name: "Canadian Studies",
    value: "Canadian Studies",
    checked: false,
  },
  {
    name: "Caribbean Studies",
    value: "Caribbean Studies",
    checked: false,
  },
  {
    name: "Cell Biology",
    value: "Cell Biology",
    checked: false,
  },
  {
    name: "Ceramic Engineering",
    value: "Ceramic Engineering",
    checked: false,
  },
  {
    name: "Ceramics",
    value: "Ceramics",
    checked: false,
  },
  {
    name: "Chemical Engineering",
    value: "Chemical Engineering",
    checked: false,
  },
  {
    name: "Chemical Physics",
    value: "Chemical Physics",
    checked: false,
  },
  {
    name: "Chemistry",
    value: "Chemistry",
    checked: false,
  },
  {
    name: "Child Care",
    value: "Child Care",
    checked: false,
  },
  {
    name: "Child Development",
    value: "Child Development",
    checked: false,
  },
  {
    name: "Chinese",
    value: "Chinese",
    checked: false,
  },
  {
    name: "Chiropractic",
    value: "Chiropractic",
    checked: false,
  },
  {
    name: "Church Music",
    value: "Church Music",
    checked: false,
  },
  {
    name: "Cinematography and Film/Video Production",
    value: "Cinematography and Film/Video Production",
    checked: false,
  },
  {
    name: "Circulation Technology",
    value: "Circulation Technology",
    checked: false,
  },
  {
    name: "Civil Engineering",
    value: "Civil Engineering",
    checked: false,
  },
  {
    name: "Classics",
    value: "Classics",
    checked: false,
  },
  {
    name: "Clinical Psychology",
    value: "Clinical Psychology",
    checked: false,
  },
  {
    name: "Cognitive Psychology",
    value: "Cognitive Psychology",
    checked: false,
  },
  {
    name: "Communication Disorders",
    value: "Communication Disorders",
    checked: false,
  },
  {
    name: "Communications",
    value: "Communications",
    checked: false,
  },
  {
    name: "Speech Communication and Rhetoric",
    value: "Speech Communication and Rhetoric",
    checked: false,
  },
  {
    name: "Comparative Literature",
    value: "Comparative Literature",
    checked: false,
  },
  {
    name: "Computer and Information Science",
    value: "Computer and Information Science",
    checked: false,
  },
  {
    name: "Computer Engineering",
    value: "Computer Engineering",
    checked: false,
  },
  {
    name: "Computer Graphics",
    value: "Computer Graphics",
    checked: false,
  },
  {
    name: "Computer Systems Analysis",
    value: "Computer Systems Analysis",
    checked: false,
  },
  {
    name: "Construction Management",
    value: "Construction Management",
    checked: false,
  },
  {
    name: "Counseling",
    value: "Counseling",
    checked: false,
  },
  {
    name: "Crafts",
    value: "Crafts",
    checked: false,
  },
  {
    name: "Creative Writing",
    value: "Creative Writing",
    checked: false,
  },
  {
    name: "Criminal Science",
    value: "Criminal Science",
    checked: false,
  },
  {
    name: "Criminology",
    value: "Criminology",
    checked: false,
  },
  {
    name: "Culinary Arts",
    value: "Culinary Arts",
    checked: false,
  },
  {
    name: "Dance",
    value: "Dance",
    checked: false,
  },
  {
    name: "Data Processing",
    value: "Data Processing",
    checked: false,
  },
  {
    name: "Dental Hygiene",
    value: "Dental Hygiene",
    checked: false,
  },
  {
    name: "Developmental Psychology",
    value: "Developmental Psychology",
    checked: false,
  },
  {
    name: "Diagnostic Medical Sonography",
    value: "Diagnostic Medical Sonography",
    checked: false,
  },
  {
    name: "Dietetics",
    value: "Dietetics",
    checked: false,
  },
  {
    name: "Digital Communications and Media/Multimedia",
    value: "Digital Communications and Media/Multimedia",
    checked: false,
  },
  {
    name: "Drawing",
    value: "Drawing",
    checked: false,
  },
  {
    name: "Early Childhood Education",
    value: "Early Childhood Education",
    checked: false,
  },
  {
    name: "East Asian Studies",
    value: "East Asian Studies",
    checked: false,
  },
  {
    name: "East European Studies",
    value: "East European Studies",
    checked: false,
  },
  {
    name: "Ecology",
    value: "Ecology",
    checked: false,
  },
  {
    name: "Economics",
    value: "Economics",
    checked: false,
  },
  {
    name: "Education",
    value: "Education",
    checked: false,
  },
  {
    name: "Education Administration",
    value: "Education Administration",
    checked: false,
  },
  {
    name: "Education of the Deaf",
    value: "Education of the Deaf",
    checked: false,
  },
  {
    name: "Educational Psychology",
    value: "Educational Psychology",
    checked: false,
  },
  {
    name: "Electrical Engineering",
    value: "Electrical Engineering",
    checked: false,
  },
  {
    name: "Elementary Education",
    value: "Elementary Education",
    checked: false,
  },
  {
    name: "Engineering Mechanics",
    value: "Engineering Mechanics",
    checked: false,
  },
  {
    name: "Engineering Physics",
    value: "Engineering Physics",
    checked: false,
  },
  {
    name: "English",
    value: "English",
    checked: false,
  },
  {
    name: "English Composition",
    value: "English Composition",
    checked: false,
  },
  {
    name: "English Literature",
    value: "English Literature",
    checked: false,
  },
  {
    name: "Entomology",
    value: "Entomology",
    checked: false,
  },
  {
    name: "Entrepreneurship",
    value: "Entrepreneurship",
    checked: false,
  },
  {
    name: "Environmental Design/Architecture",
    value: "Environmental Design/Architecture",
    checked: false,
  },
  {
    name: "Environmental Science",
    value: "Environmental Science",
    checked: false,
  },
  {
    name: "Environmental/Environmental Health Engineering",
    value: "Environmental/Environmental Health Engineering",
    checked: false,
  },
  {
    name: "Epidemiology",
    value: "Epidemiology",
    checked: false,
  },
  {
    name: "Equine Studies",
    value: "Equine Studies",
    checked: false,
  },
  {
    name: "Ethnic Studies",
    value: "Ethnic Studies",
    checked: false,
  },
  {
    name: "European History",
    value: "European History",
    checked: false,
  },
  {
    name: "Experimental Pathology",
    value: "Experimental Pathology",
    checked: false,
  },
  {
    name: "Experimental Psychology",
    value: "Experimental Psychology",
    checked: false,
  },
  {
    name: "Fashion Design",
    value: "Fashion Design",
    checked: false,
  },
  {
    name: "Fashion Merchandising",
    value: "Fashion Merchandising",
    checked: false,
  },
  {
    name: "Feed Science",
    value: "Feed Science",
    checked: false,
  },
  {
    name: "Fiber, Textiles, and Weaving Arts",
    value: "Fiber, Textiles, and Weaving Arts",
    checked: false,
  },
  {
    name: "Film",
    value: "Film",
    checked: false,
  },
  {
    name: "Finance",
    value: "Finance",
    checked: false,
  },
  {
    name: "Floriculture",
    value: "Floriculture",
    checked: false,
  },
  {
    name: "Food Science",
    value: "Food Science",
    checked: false,
  },
  {
    name: "Forensic Science",
    value: "Forensic Science",
    checked: false,
  },
  {
    name: "Forestry",
    value: "Forestry",
    checked: false,
  },
  {
    name: "French",
    value: "French",
    checked: false,
  },
  {
    name: "Furniture Design",
    value: "Furniture Design",
    checked: false,
  },
  {
    name: "Game Design",
    value: "Game Design",
    checked: false,
  },
  {
    name: "Gay and Lesbian Studies",
    value: "Gay and Lesbian Studies",
    checked: false,
  },
  {
    name: "Genetics",
    value: "Genetics",
    checked: false,
  },
  {
    name: "Geography",
    value: "Geography",
    checked: false,
  },
  {
    name: "Geological Engineering",
    value: "Geological Engineering",
    checked: false,
  },
  {
    name: "Geology",
    value: "Geology",
    checked: false,
  },
  {
    name: "Geophysics",
    value: "Geophysics",
    checked: false,
  },
  {
    name: "German",
    value: "German",
    checked: false,
  },
  {
    name: "Gerontology",
    value: "Gerontology",
    checked: false,
  },
  {
    name: "Government",
    value: "Government",
    checked: false,
  },
  {
    name: "Graphic Design",
    value: "Graphic Design",
    checked: false,
  },
  {
    name: "Health Administration",
    value: "Health Administration",
    checked: false,
  },
  {
    name: "Hebrew",
    value: "Hebrew",
    checked: false,
  },
  {
    name: "Hispanic-American, Puerto Rican, and Chicano Studies",
    value: "Hispanic-American, Puerto Rican, and Chicano Studies",
    checked: false,
  },
  {
    name: "Historic Preservation",
    value: "Historic Preservation",
    checked: false,
  },
  {
    name: "History",
    value: "History",
    checked: false,
  },
  {
    name: "Home Economics",
    value: "Home Economics",
    checked: false,
  },
  {
    name: "Horticulture",
    value: "Horticulture",
    checked: false,
  },
  {
    name: "Hospitality",
    value: "Hospitality",
    checked: false,
  },
  {
    name: "Human Development",
    value: "Human Development",
    checked: false,
  },
  {
    name: "Human Resources Management",
    value: "Human Resources Management",
    checked: false,
  },
  {
    name: "Illustration",
    value: "Illustration",
    checked: false,
  },
  {
    name: "Industrial Design",
    value: "Industrial Design",
    checked: false,
  },
  {
    name: "Industrial Engineering",
    value: "Industrial Engineering",
    checked: false,
  },
  {
    name: "Industrial Management",
    value: "Industrial Management",
    checked: false,
  },
  {
    name: "Industrial Psychology",
    value: "Industrial Psychology",
    checked: false,
  },
  {
    name: "Information Technology",
    value: "Information Technology",
    checked: false,
  },
  {
    name: "Interior Architecture",
    value: "Interior Architecture",
    checked: false,
  },
  {
    name: "Interior Design",
    value: "Interior Design",
    checked: false,
  },
  {
    name: "International Agriculture",
    value: "International Agriculture",
    checked: false,
  },
  {
    name: "International Business",
    value: "International Business",
    checked: false,
  },
  {
    name: "International Relations",
    value: "International Relations",
    checked: false,
  },
  {
    name: "International Studies",
    value: "International Studies",
    checked: false,
  },
  {
    name: "Islamic Studies",
    value: "Islamic Studies",
    checked: false,
  },
  {
    name: "Italian",
    value: "Italian",
    checked: false,
  },
  {
    name: "Japanese",
    value: "Japanese",
    checked: false,
  },
  {
    name: "Jazz Studies",
    value: "Jazz Studies",
    checked: false,
  },
  {
    name: "Jewelry and Metalsmithing",
    value: "Jewelry and Metalsmithing",
    checked: false,
  },
  {
    name: "Jewish Studies",
    value: "Jewish Studies",
    checked: false,
  },
  {
    name: "Journalism",
    value: "Journalism",
    checked: false,
  },
  {
    name: "Kinesiology",
    value: "Kinesiology",
    checked: false,
  },
  {
    name: "Korean",
    value: "Korean",
    checked: false,
  },
  {
    name: "Land Use Planning and Management",
    value: "Land Use Planning and Management",
    checked: false,
  },
  {
    name: "Landscape Architecture",
    value: "Landscape Architecture",
    checked: false,
  },
  {
    name: "Landscape Horticulture",
    value: "Landscape Horticulture",
    checked: false,
  },
  {
    name: "Latin American Studies",
    value: "Latin American Studies",
    checked: false,
  },
  {
    name: "Law",
    value: "Law",
    checked: false,
  },
  {
    name: "Library Science",
    value: "Library Science",
    checked: false,
  },
  {
    name: "Linguistics",
    value: "Linguistics",
    checked: false,
  },
  {
    name: "Logistics Management",
    value: "Logistics Management",
    checked: false,
  },
  {
    name: "Management Information Systems",
    value: "Management Information Systems",
    checked: false,
  },
  {
    name: "Managerial Economics",
    value: "Managerial Economics",
    checked: false,
  },
  {
    name: "Marine Biology",
    value: "Marine Biology",
    checked: false,
  },
  {
    name: "Marine Science",
    value: "Marine Science",
    checked: false,
  },
  {
    name: "Marketing",
    value: "Marketing",
    checked: false,
  },
  {
    name: "Mass Communication",
    value: "Mass Communication",
    checked: false,
  },
  {
    name: "Massage Therapy",
    value: "Massage Therapy",
    checked: false,
  },
  {
    name: "Materials Science",
    value: "Materials Science",
    checked: false,
  },
  {
    name: "Mathematics",
    value: "Mathematics",
    checked: false,
  },
  {
    name: "Mechanical Engineering",
    value: "Mechanical Engineering",
    checked: false,
  },
  {
    name: "Medical Technology",
    value: "Medical Technology",
    checked: false,
  },
  {
    name: "Medicine",
    value: "Medicine",
    checked: false,
  },
  {
    name: "Medieval and Renaissance Studies",
    value: "Medieval and Renaissance Studies",
    checked: false,
  },
  {
    name: "Mental Health Services",
    value: "Mental Health Services",
    checked: false,
  },
  {
    name: "Merchandising and Buying Operations",
    value: "Merchandising and Buying Operations",
    checked: false,
  },
  {
    name: "Metallurgical Engineering",
    value: "Metallurgical Engineering",
    checked: false,
  },
  {
    name: "Microbiology",
    value: "Microbiology",
    checked: false,
  },
  {
    name: "Middle Eastern Studies",
    value: "Middle Eastern Studies",
    checked: false,
  },
  {
    name: "Military Science",
    value: "Military Science",
    checked: false,
  },
  {
    name: "Mineral Engineering",
    value: "Mineral Engineering",
    checked: false,
  },
  {
    name: "Missions",
    value: "Missions",
    checked: false,
  },
  {
    name: "Modern Greek",
    value: "Modern Greek",
    checked: false,
  },
  {
    name: "Molecular Biology",
    value: "Molecular Biology",
    checked: false,
  },
  {
    name: "Molecular Genetics",
    value: "Molecular Genetics",
    checked: false,
  },
  {
    name: "Mortuary Science",
    value: "Mortuary Science",
    checked: false,
  },
  {
    name: "Museum Studies",
    value: "Museum Studies",
    checked: false,
  },
  {
    name: "Music",
    value: "Music",
    checked: false,
  },
  {
    name: "Music Education",
    value: "Music Education",
    checked: false,
  },
  {
    name: "Music History",
    value: "Music History",
    checked: false,
  },
  {
    name: "Music Management",
    value: "Music Management",
    checked: false,
  },
  {
    name: "Music Therapy",
    value: "Music Therapy",
    checked: false,
  },
  {
    name: "Musical Theater",
    value: "Musical Theater",
    checked: false,
  },
  {
    name: "Native American Studies",
    value: "Native American Studies",
    checked: false,
  },
  {
    name: "Natural Resources Conservation",
    value: "Natural Resources Conservation",
    checked: false,
  },
  {
    name: "Naval Architecture",
    value: "Naval Architecture",
    checked: false,
  },
  {
    name: "Neurobiology",
    value: "Neurobiology",
    checked: false,
  },
  {
    name: "Neuroscience",
    value: "Neuroscience",
    checked: false,
  },
  {
    name: "Nuclear Engineering",
    value: "Nuclear Engineering",
    checked: false,
  },
  {
    name: "Nursing",
    value: "Nursing",
    checked: false,
  },
  {
    name: "Nutrition",
    value: "Nutrition",
    checked: false,
  },
  {
    name: "Occupational Therapy",
    value: "Occupational Therapy",
    checked: false,
  },
  {
    name: "Ocean Engineering",
    value: "Ocean Engineering",
    checked: false,
  },
  {
    name: "Oceanography",
    value: "Oceanography",
    checked: false,
  },
  {
    name: "Operations Management",
    value: "Operations Management",
    checked: false,
  },
  {
    name: "Organizational Behavior Studies",
    value: "Organizational Behavior Studies",
    checked: false,
  },
  {
    name: "Painting",
    value: "Painting",
    checked: false,
  },
  {
    name: "Paleontology",
    value: "Paleontology",
    checked: false,
  },
  {
    name: "Pastoral Studies",
    value: "Pastoral Studies",
    checked: false,
  },
  {
    name: "Peace Studies",
    value: "Peace Studies",
    checked: false,
  },
  {
    name: "Petroleum Engineering",
    value: "Petroleum Engineering",
    checked: false,
  },
  {
    name: "Pharmacology",
    value: "Pharmacology",
    checked: false,
  },
  {
    name: "Pharmacy",
    value: "Pharmacy",
    checked: false,
  },
  {
    name: "Philosophy",
    value: "Philosophy",
    checked: false,
  },
  {
    name: "Photography",
    value: "Photography",
    checked: false,
  },
  {
    name: "Photojournalism",
    value: "Photojournalism",
    checked: false,
  },
  {
    name: "Physical Education",
    value: "Physical Education",
    checked: false,
  },
  {
    name: "Physical Therapy",
    value: "Physical Therapy",
    checked: false,
  },
  {
    name: "Physician Assistant",
    value: "Physician Assistant",
    checked: false,
  },
  {
    name: "Physics",
    value: "Physics",
    checked: false,
  },
  {
    name: "Physiological Psychology",
    value: "Physiological Psychology",
    checked: false,
  },
  {
    name: "Piano",
    value: "Piano",
    checked: false,
  },
  {
    name: "Planetary Science",
    value: "Planetary Science",
    checked: false,
  },
  {
    name: "Plant Pathology",
    value: "Plant Pathology",
    checked: false,
  },
  {
    name: "Playwriting and Screenwriting",
    value: "Playwriting and Screenwriting",
    checked: false,
  },
  {
    name: "Political Communication",
    value: "Political Communication",
    checked: false,
  },
  {
    name: "Political Science",
    value: "Political Science",
    checked: false,
  },
  {
    name: "Portuguese",
    value: "Portuguese",
    checked: false,
  },
  {
    name: "Pre-Dentistry",
    value: "Pre-Dentistry",
    checked: false,
  },
  {
    name: "Pre-Law",
    value: "Pre-Law",
    checked: false,
  },
  {
    name: "Pre-Medicine",
    value: "Pre-Medicine",
    checked: false,
  },
  {
    name: "Pre-Optometry",
    value: "Pre-Optometry",
    checked: false,
  },
  {
    name: "Pre-Seminary",
    value: "Pre-Seminary",
    checked: false,
  },
  {
    name: "Pre-Veterinary Medicine",
    value: "Pre-Veterinary Medicine",
    checked: false,
  },
  {
    name: "Printmaking",
    value: "Printmaking",
    checked: false,
  },
  {
    name: "Psychology",
    value: "Psychology",
    checked: false,
  },
  {
    name: "Public Administration",
    value: "Public Administration",
    checked: false,
  },
  {
    name: "Public Health",
    value: "Public Health",
    checked: false,
  },
  {
    name: "Public Policy Analysis",
    value: "Public Policy Analysis",
    checked: false,
  },
  {
    name: "Public Relations",
    value: "Public Relations",
    checked: false,
  },
  {
    name: "Radio and Television",
    value: "Radio and Television",
    checked: false,
  },
  {
    name: "Radiologic Technology",
    value: "Radiologic Technology",
    checked: false,
  },
  {
    name: "Range Science and Management",
    value: "Range Science and Management",
    checked: false,
  },
  {
    name: "Real Estate",
    value: "Real Estate",
    checked: false,
  },
  {
    name: "Recording Arts Technology",
    value: "Recording Arts Technology",
    checked: false,
  },
  {
    name: "Recreation Management",
    value: "Recreation Management",
    checked: false,
  },
  {
    name: "Rehabilitation Services",
    value: "Rehabilitation Services",
    checked: false,
  },
  {
    name: "Religious Studies",
    value: "Religious Studies",
    checked: false,
  },
  {
    name: "Respiratory Therapy",
    value: "Respiratory Therapy",
    checked: false,
  },
  {
    name: "Risk Management",
    value: "Risk Management",
    checked: false,
  },
  {
    name: "Rural Sociology",
    value: "Rural Sociology",
    checked: false,
  },
  {
    name: "Russian",
    value: "Russian",
    checked: false,
  },
  {
    name: "Scandinavian Studies",
    value: "Scandinavian Studies",
    checked: false,
  },
  {
    name: "Sculpture",
    value: "Sculpture",
    checked: false,
  },
  {
    name: "Slavic Languages and Literatures",
    value: "Slavic Languages and Literatures",
    checked: false,
  },
  {
    name: "Social Psychology",
    value: "Social Psychology",
    checked: false,
  },
  {
    name: "Social Work",
    value: "Social Work",
    checked: false,
  },
  {
    name: "Sociology",
    value: "Sociology",
    checked: false,
  },
  {
    name: "Soil Science",
    value: "Soil Science",
    checked: false,
  },
  {
    name: "Software Engineering",
    value: "Software Engineering",
    checked: false,
  },
  {
    name: "Sound Engineering",
    value: "Sound Engineering",
    checked: false,
  },
  {
    name: "South Asian Studies",
    value: "South Asian Studies",
    checked: false,
  },
  {
    name: "Southeast Asia Studies",
    value: "Southeast Asia Studies",
    checked: false,
  },
  {
    name: "Spanish",
    value: "Spanish",
    checked: false,
  },
  {
    name: "Special Education",
    value: "Special Education",
    checked: false,
  },
  {
    name: "Speech Pathology",
    value: "Speech Pathology",
    checked: false,
  },
  {
    name: "Sport and Leisure Studies",
    value: "Sport and Leisure Studies",
    checked: false,
  },
  {
    name: "Sports Management",
    value: "Sports Management",
    checked: false,
  },
  {
    name: "Statistics",
    value: "Statistics",
    checked: false,
  },
  {
    name: "Surveying",
    value: "Surveying",
    checked: false,
  },
  {
    name: "Sustainable Resource Management",
    value: "Sustainable Resource Management",
    checked: false,
  },
  {
    name: "Teacher Education",
    value: "Teacher Education",
    checked: false,
  },
  {
    name: "Teaching English as a Second Language",
    value: "Teaching English as a Second Language",
    checked: false,
  },
  {
    name: "Technical Writing",
    value: "Technical Writing",
    checked: false,
  },
  {
    name: "Technology Education",
    value: "Technology Education",
    checked: false,
  },
  {
    name: "Textile Engineering",
    value: "Textile Engineering",
    checked: false,
  },
  {
    name: "Theatre",
    value: "Theatre",
    checked: false,
  },
  {
    name: "Theology",
    value: "Theology",
    checked: false,
  },
  {
    name: "Tourism",
    value: "Tourism",
    checked: false,
  },
  {
    name: "Toxicology",
    value: "Toxicology",
    checked: false,
  },
  {
    name: "Turfgrass Science",
    value: "Turfgrass Science",
    checked: false,
  },
  {
    name: "Urban Planning",
    value: "Urban Planning",
    checked: false,
  },
  {
    name: "Urban Studies",
    value: "Urban Studies",
    checked: false,
  },
  {
    name: "Veterinary Medicine",
    value: "Veterinary Medicine",
    checked: false,
  },
  {
    name: "Visual Communication",
    value: "Visual Communication",
    checked: false,
  },
  {
    name: "Voice",
    value: "Voice",
    checked: false,
  },
  {
    name: "Web Design",
    value: "Web Design",
    checked: false,
  },
  {
    name: "Webmaster and Web Management",
    value: "Webmaster and Web Management",
    checked: false,
  },
  {
    name: "Welding Engineering",
    value: "Welding Engineering",
    checked: false,
  },
  {
    name: "Wildlife Management",
    value: "Wildlife Management",
    checked: false,
  },
  {
    name: "Women's Studies",
    value: "Women's Studies",
    checked: false,
  },
  {
    name: "Youth Ministries",
    value: "Youth Ministries",
    checked: false,
  },
  {
    name: "Zoology",
    value: "Zoology",
    checked: false,
  },
];

// //const ScholarshipFilters = ({ onClose }) => {
// const ScholarshipFilters = () => {
//   useEffect(() => {
//     const scrollAnimElements = document.querySelectorAll(
//       "[data-animate-on-scroll]"
//     );
//     const observer = new IntersectionObserver(
//       (entries) => {
//         for (const entry of entries) {
//           if (entry.isIntersecting || entry.intersectionRatio > 0) {
//             const targetElement = entry.target;
//             targetElement.classList.add("animate");
//             observer.unobserve(targetElement);
//           }
//         }
//       },
//       {
//         threshold: 0.15,
//       }
//     );

//     for (let i = 0; i < scrollAnimElements.length; i++) {
//       observer.observe(scrollAnimElements[i]);
//     }

//     return () => {
//       for (let i = 0; i < scrollAnimElements.length; i++) {
//         observer.unobserve(scrollAnimElements[i]);
//       }
//     };
//   }, []);

//   const [filtersPhone, setFiltersPhone] = useState({
//     categoryFiltersPhone:{
//       categories: []
//     },
//     amountFiltersPhone: {
//       amounts:[]
//     },
//     locationFiltersPhone:{
//       locations: []
//     },
//     majorFiltersPhone:{
//       majors: []
//     },
//     citizenshipFiltersPhone:{
//       citizenships:[]
//     },
//     gradeLevelFiltersPhone:{
//       gradeLevels:[]
//     },

//   });

//   const [categoryinfoPhone, setCategoryInfoPhone] = useState({});
//     const [categoryFiltersPhone, setCategoryFiltersPhone] = useState({
//       categories: []
//     });
//     const [categoryFiltersinfoPhone, setCategoryFiltersInfoPhone] = useState({
//       categories: [],
//       //response: [],
//     });

//     const [awardAmountsPhone, setAwardAmountsPhone] = useState([]);
//     const [filterAmountsPhone, setFilterAmountsPhone] = useState(allAwardAmounts);

//     //const [filters, setFilters] = useState({});
//     const [amountinfoPhone, setAmountInfoPhone] = useState({});
//     const [amountFiltersPhone, setAmountFiltersPhone] = useState({
//       amounts: []
//     });
//     const [amountFiltersinfoPhone, setAmountFiltersInfoPhone] = useState({
//       amounts: [],
//       //response: [],
//     });

//     const [majorsPhone, setMajorsPhone] = useState([]);
//     const [filterMajorsPhone, setFilterMajorsPhone] = useState(allMajors);

//     //const [filters, setFilters] = useState({});
//     const [majorinfoPhone, setMajorInfoPhone] = useState({});
//     const [majorFiltersPhone, setMajorFiltersPhone] = useState({
//       majors:[]
//     });
//     const [majorFiltersinfoPhone, setMajorFiltersInfoPhone] = useState({
//       majors: [],
//       //response: [],
//     });

//     const [LocationsPhone, setLocationsPhone] = useState([]);
//     const [filterLocationsPhone, setFilterLocationsPhone] = useState(allLocations);

//     //const [filters, setFilters] = useState({});
//     const [locationinfoPhone, setLocationInfoPhone] = useState({});
//     const [locationFiltersPhone, setLocationFiltersPhone] = useState({
//       locations: []
//     });
//     const [locationFiltersinfoPhone, setLocationFiltersInfoPhone] = useState({
//       locations: [],
//       //response: [],
//     });

//     const [citizenshipsPhone, setCitizenshipsPhone] = useState([]);
//     const [filterCitizenshipsPhone, setFilterCitizenshipsPhone] = useState(allCitizenships);

//     //const [filters, setFilters] = useState({});
//     const [citizenshipinfoPhone, setCitizenshipInfoPhone] = useState({});
//     const [citizenshipFiltersPhone, setCitizenshipFiltersPhone] = useState({
//       citizenships: []
//     });
//     const [citizenshipFiltersinfoPhone, setCitizenshipFiltersInfoPhone] = useState({
//       citizenships: [],
//       //response: [],
//     });

//     const [awardGradeLevelsPhone, setAwardGradeLevelsPhone] = useState([]);
//     const [filterGradeLevelsPhone, setFilterGradeLevelsPhone] = useState(allGradeLevels);

//     //const [filters, setFilters] = useState({});
//     const [gradeLevelinfoPhone, setGradeLevelInfoPhone] = useState({});
//     const [gradeLevelFiltersPhone, setGradeLevelFiltersPhone] = useState({
//       gradeLevels:[]
//     });
//     const [gradeLevelFiltersinfoPhone, setGradeLevelFiltersInfoPhone] = useState({
//       gradeLevels: [],
//       //response: [],
//     });

//     const [sortPhone, setSortsPhone] = useState('');

//   return {
//     categoryFiltersPhone, amountFiltersPhone, gradeLevelFiltersPhone, locationFiltersPhone, majorFiltersPhone, citizenshipFiltersPhone, filtersPhone, sortPhone,

//     renderFilters:(
//       <div className="scholarship-filters" data-animate-on-scroll>
//       <div className="scholarship-filters-inner">
//         <div className="clear-all-parent">
//           <div className="clear-all">Clear All</div>
//           <div className="clear-all">Refine</div>
//           <b className="clear-all">X</b>
//         </div>
//       </div>
//       <div className="categories-parent">
//         <div className="categories">Categories</div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" name="essayreview" />
//             <div className="based-on-your">Based On Your Profile</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">Based On Your Major</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">New</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">Need Based</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your"> INo Essay</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">Popular</div>
//           </div>
//         </div>
//         <div className="categories">Award Amount</div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">$0-$1000</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">$1001 - $2500</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your"> $2501 - $5000</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="div10">$5001 - $10000</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="div10">{`>  $10000`}</div>
//           </div>
//         </div>
//         <div className="categories">Grade Level</div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">High School</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">Bachelors</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your"> Associate</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your"> Masters</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">PHD</div>
//           </div>
//         </div>
//         <div className="categories">Location</div>
//         <div className="frame-wrapper19">
//           <input className="frame-child" type="text" placeholder="Search" />
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">United States</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">United Kingdom</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your"> Abuja</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">Lagos</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">Manchester</div>
//           </div>
//         </div>
//         <div className="categories">Major</div>
//         <div className="frame-wrapper19">
//           <input className="frame-child" type="text" placeholder="Search" />
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">Anthropology</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">Architecture</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your"> Arts</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="biology">Biology</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">Argentina</div>
//           </div>
//         </div>
//         <div className="categories">Citizenship</div>
//         <div className="frame-wrapper19">
//           <input className="frame-child" type="text" placeholder="Search" />
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">United States</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">United Kingdom</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your"> Albania</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">Angola</div>
//           </div>
//         </div>
//         <div className="frame-wrapper3">
//           <div className="check-box-outline-blank-parent">
//             <Checkbox className="clear-all" />
//             <div className="based-on-your">Argentina</div>
//           </div>
//         </div>
//       </div>
//     </div>
//     )
//     };
// };

// export default ScholarshipFilters;

const Checkbox = ({ isChecked, label, handleChange, index, value, name }) => {
  //     if(name == 'citizenships'){
  //       console.log('citizenships', isChecked)
  //     }
  //    // console.log("isCfghredwskhbr", isChecked)
  return (
    <div className="frame-wrapper3">
      <div className="check-box-outline-blank-parent">
        {/* <Checkbox className="clear-all" /> */}
        <input
          type="checkbox"
          id={`checkbox-${index}`}
          checked={isChecked}
          onChange={(event) => {
            handleChange(event, index);
          }}
          name={name}
          value={value}
          className="filterCheckBox"
        />

        <label htmlFor={`checkbox-${index}`} className="based-on-your">
          {label}
        </label>

        {/* <div className="based-on-your">Architecture</div> */}
      </div>
    </div>
  );
};

const ScholarshipFilters = (props) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  // Your existing useState hooks and other logic
  const [filtersPhone, setFiltersPhone] = useState({
    categoryFiltersPhone: {
      categories: [],
    },
    amountFiltersPhone: {
      amounts: [],
    },
    locationFiltersPhone: {
      locations: [],
    },
    majorFiltersPhone: {
      majors: [],
    },
    citizenshipFiltersPhone: {
      citizenships: [],
    },
    gradeLevelFiltersPhone: {
      gradeLevels: [],
    },
  });

  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);

  const [majorSearch, setMajorSearch] = useState("");
  const [citizenSearch, setCitizenSearch] = useState("");
  const [categories, setCategories] = useState([]);
  const [filterCategories, setFilterCategories] = useState(allCategories);

  const [categoryinfo, setCategoryInfo] = useState({});
  const [categoryFilters, setCategoryFilters] = useState({
    categories: [],
  });
  const [categoryFiltersinfo, setCategoryFiltersInfo] = useState({
    categories: [],
    //response: [],
  });

  const [awardAmounts, setAwardAmounts] = useState([]);
  const [filterAmounts, setFilterAmounts] = useState(allAwardAmounts);

  //const [filters, setFilters] = useState({});
  const [amountinfo, setAmountInfo] = useState({});
  const [amountFilters, setAmountFilters] = useState({
    amounts: [],
  });
  const [amountFiltersinfo, setAmountFiltersInfo] = useState({
    amounts: [],
    //response: [],
  });

  const [majors, setMajors] = useState([]);
  const [filterMajors, setFilterMajors] = useState(allMajors);

  //const [filters, setFilters] = useState({});
  const [majorinfo, setMajorInfo] = useState({});
  const [majorFilters, setMajorFilters] = useState({
    majors: [],
  });
  const [majorFiltersinfo, setMajorFiltersInfo] = useState({
    majors: [],
    //response: [],
  });

  const [Locations, setLocations] = useState([]);
  const [filterLocations, setFilterLocations] = useState(allLocations);

  //const [filters, setFilters] = useState({});
  const [locationinfo, setLocationInfo] = useState({});
  const [locationFilters, setLocationFilters] = useState({
    locations: [],
  });
  const [locationFiltersinfo, setLocationFiltersInfo] = useState({
    locations: [],
    //response: [],
  });

  const [citizenshipss, setCitizenshipss] = useState([]);
  const [filterCitizenships, setFilterCitizenships] = useState(allCitizenships);

  //const [filters, setFilters] = useState({});
  const [citizenshipinfo, setCitizenshipInfo] = useState({});
  const [citizenshipFilters, setCitizenshipFilters] = useState({
    citizenships: [],
  });
  const [citizenshipFiltersinfo, setCitizenshipFiltersInfo] = useState({
    citizenships: [],
    //response: [],
  });

  const [awardGradeLevels, setAwardGradeLevels] = useState([]);
  const [filterGradeLevels, setFilterGradeLevels] = useState(allGradeLevels);

  //const [filters, setFilters] = useState({});
  const [gradeLevelinfo, setGradeLevelInfo] = useState({});
  const [gradeLevelFilters, setGradeLevelFilters] = useState({
    gradeLevels: [],
  });
  const [gradeLevelFiltersinfo, setGradeLevelFiltersInfo] = useState({
    gradeLevels: [],
    //response: [],
  });

  const [sortPhone, setSortsPhone] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [scholarships, setScholarships] = useState([]);

  const PROXY_URL = `${BASE_Server_URL}proxy`; // Update this with your actual proxy server URL.
  const getScholarships = async () => {
    try {
      //console.log('called')
      // const res = await axios.get(`http://localhost:3000/v1/scholarship?page=${page}&limit=8`);
      const res = await axios.get(`${BASE_Server_URL}scholarship`);
      //console.log(res.data)
      // const pageC = await res.data.totalPages
      // console.log(res)
      setScholarships(res.data);
      //setFilteredScholarship(res.data)
      // setPageCount(pageC)
    } catch (error) {}
  };
  useEffect(() => {
    getScholarships();
  }, []);

  useEffect(() => {
    //const majorsList = []
    //console.log('mag', allMajors)

    for (let i = 0; i < scholarships.length; i++) {
      //console.log(scholarships[i])
      // console.log(scholarships[i].majors)
      for (let h = 0; h < scholarships[i].majors.length; h++) {
        // console.log(scholarships[i].majors[h])

        if (
          // !majorsList.includes(
          // {name: scholarships[i].majors[h], value:scholarships[i].majors[h], checked:false}
          // )
          !allMajors.some(
            (allMajors) => allMajors.name === scholarships[i].majors[h]
          )
        ) {
          allMajors.push({
            name: scholarships[i].majors[h],
            value: scholarships[i].majors[h],
            checked: false,
          });
          //scholarships[i].majors[h])
        }
      }
    }
    //console.log(majorsList)

    //setAllMajors(allMajors)
    setFilterMajors(allMajors);

    //console.log(allMajors)
  }, [scholarships]);

  useEffect(
    () => (
      //console.log('search changed', search)
      console.log("list called"),
      setFilterMajors(
        allMajors.filter((major) =>
          major.name.toLowerCase().includes(majorSearch)
        )
      )
      // setScholarships(scholarships.filter(scholarship => scholarship.name.toLowerCase().includes(search)))
    ),
    [majorSearch]
  );

  useEffect(
    () => (
      //console.log('search changed', search)
      console.log("list called"),
      setFilterCitizenships(
        allCitizenships.filter((citizenship) =>
          citizenship.name.toLowerCase().includes(citizenSearch)
        )
      )
      // setScholarships(scholarships.filter(scholarship => scholarship.name.toLowerCase().includes(search)))
    ),
    [citizenSearch]
  );

  // useEffect(() => {

  //   //const majorsList = []
  //   console.log('suggestions emnsxkm', suggestions)

  //   for (let i = 0; i < suggestions.length; i++) {
  //     //console.log(scholarships[i])
  //    // console.log(scholarships[i].majors)

  //     // console.log(scholarships[i].majors[h])

  //       if(
  //         // !majorsList.includes(
  //         // {name: scholarships[i].majors[h], value:scholarships[i].majors[h], checked:false}
  //         // )
  //         !allLocations.some(allLocations=> allLocations[i] === allLocations[i]))
  //         {
  //           allLocations.push(
  //           { name: suggestions[i].majors[h], value: suggestions[i].majors[h], checked: false }
  //         )
  //           //scholarships[i].majors[h])
  //       }

  //   }
  //   //console.log(majorsList)

  //   //setAllMajors(allMajors)
  //   //setFilterMajors(allMajors)

  //   //console.log(allMajors)
  // }, [suggestions])

  useEffect(() => {
    allLocations = [];

    //const majorsList = []
    console.log("mag", allLocations);

    for (let i = 0; i < suggestions.length; i++) {
      //console.log(scholarships[i])
      // console.log(scholarships[i].majors)

      // console.log(scholarships[i].majors[h])

      if (
        // !majorsList.includes(
        // {name: scholarships[i].majors[h], value:scholarships[i].majors[h], checked:false}
        // )
        !allLocations.some(
          (allLocations) => allLocations.name === suggestions[i]
        )
      ) {
        allLocations.push({
          name: suggestions[i],
          value: suggestions[i],
          checked: false,
        });
        //scholarships[i].majors[h])
      }
    }
    console.log(allLocations);

    //setAllMajors(allMajors)

    setFilterLocations(allLocations);

    //console.log(allMajors)
  }, [suggestions]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    // Call the debounced function instead of making immediate requests
    //debouncedFetchSuggestions(value);
  };

  const handleSuggestionClick = (suggestion) => {
    // Set the selected suggestion as the search term
    setSearchTerm(suggestion);

    // Clear the suggestion list
    setSuggestions([]);
  };

  const debouncedFetchSuggestions = debounce(async (inputValue) => {
    setLoading(true);
    setError(null);
    try {
      // const response = await axios.get(`${AUTOCOMPLETE_API_URL}&input=${inputValue}`);
      // const { predictions } = response.data;
      // const locationSuggestions = predictions.map((prediction) => prediction.description);

      //   const response = await axios.get(`${PROXY_URL}/?input=${inputValue}`);
      //   const { predictions } = response.data;
      //   const locationSuggestions = predictions.map((prediction) => prediction.description);

      //   setSuggestions(locationSuggestions);
      //   setLoading(false);

      const response = await axios.get(`${PROXY_URL}/?input=${inputValue}`);
      const { predictions } = response.data;

      // Filter predictions to include only cities, states, or countries
      const locationSuggestions = predictions
        .filter((prediction) => {
          // You may need to adjust these types based on the actual response structure
          const relevantTypes = [
            "locality",
            "administrative_area_level_1",
            "country",
          ];
          return prediction.types.some((type) => relevantTypes.includes(type));
        })
        .map((prediction) => prediction.description);

      setSuggestions(locationSuggestions);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
      setError("Error fetching suggestions. Please try again later.");
      setLoading(false);
    }
  }, 300);

  useEffect(() => {
    // Debounce the API call to avoid excessive requests
    debouncedFetchSuggestions(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    console.log(categoryFilters);
    console.log(amountFilters);
    console.log(gradeLevelFilters);
    console.log(locationFilters);
    console.log(majorFilters);
    console.log(citizenshipFilters);
  }, []);
  const handleChange = (e, i) => {
    updateCheckStatus(e, i);

    const { value, checked } = e.target;

    console.log("value", e.target);

    const { categories } = categoryFiltersinfo;
    const { amounts } = amountFiltersinfo;
    const { gradeLevels } = gradeLevelFiltersinfo;
    const { locations } = locationFiltersinfo;
    const { majors } = majorFiltersinfo;
    const { citizenships } = citizenshipFiltersinfo;

    if (checked) {
      if (e.target.name == "categories") {
        setCategoryFilters({
          ...categoryFilters,
          //[e.target.name]: value
          categories: [...categories, value],
        });

        setCategoryFiltersInfo({
          categories: [...categories, value],
          //response: [...services, value],
        });
      }

      if (e.target.name == "awardamounts") {
        setAmountFilters({
          ...amountFilters,
          //[e.target.name]: value
          amounts: [...amounts, value],
        });

        setAmountFiltersInfo({
          amounts: [...amounts, value],
          //response: [...services, value],
        });
      }

      if (e.target.name == "gradelevels") {
        setGradeLevelFilters({
          ...gradeLevelFilters,
          //[e.target.name]: value
          gradeLevels: [...gradeLevels, value],
        });

        setGradeLevelFiltersInfo({
          gradeLevels: [...gradeLevels, value],
          //response: [...services, value],
        });
      }
      if (e.target.name == "locations") {
        setLocationFilters({
          ...locationFilters,
          //[e.target.name]: value
          locations: [...locations, value],
        });

        setLocationFiltersInfo({
          locations: [...locations, value],
          //response: [...services, value],
        });
      }

      if (e.target.name == "majors") {
        setMajorFilters({
          ...majorFilters,
          //[e.target.name]: value
          majors: [...majors, value],
        });

        setMajorFiltersInfo({
          majors: [...majors, value],
          //response: [...services, value],
        });
      }

      if (e.target.name == "citizenships") {
        console.log("citizensnhgfghk  1");

        setCitizenshipFilters({
          ...citizenshipFilters,
          //[e.target.name]: value
          citizenships: [...citizenships, value],
        });

        setCitizenshipFiltersInfo({
          citizenships: [...citizenships, value],
          //response: [...services, value],
        });
      }
    } else {
      if (e.target.name == "categories") {
        setCategoryFilters({
          categories: categories.filter((e) => e !== value),
          //response: categories.filter((e) => e !== value),
        });

        setCategoryFiltersInfo({
          categories: categories.filter((e) => e !== value),
          // response: categories.filter((e) => e !== value),
          //response: [...services, value],
        });
      }

      if (e.target.name == "awardamounts") {
        setAmountFilters({
          amounts: amounts.filter((e) => e !== value),
          //response: categories.filter((e) => e !== value),
        });

        setAmountFiltersInfo({
          amounts: amounts.filter((e) => e !== value),
          // response: categories.filter((e) => e !== value),
          //response: [...services, value],
        });
      }

      if (e.target.name == "gradelevels") {
        setGradeLevelFilters({
          gradeLevels: gradeLevels.filter((e) => e !== value),
          //response: categories.filter((e) => e !== value),
        });

        setGradeLevelFiltersInfo({
          gradeLevels: gradeLevels.filter((e) => e !== value),
          // response: categories.filter((e) => e !== value),
          //response: [...services, value],
        });
      }

      if (e.target.name == "locations") {
        setLocationFilters({
          locations: locations.filter((e) => e !== value),
          //response: categories.filter((e) => e !== value),
        });

        setLocationFiltersInfo({
          locations: locations.filter((e) => e !== value),
          // response: categories.filter((e) => e !== value),
          //response: [...services, value],
        });
      }
      if (e.target.name == "majors") {
        setMajorFilters({
          majors: majors.filter((e) => e !== value),
          //response: categories.filter((e) => e !== value),
        });

        setMajorFiltersInfo({
          majors: majors.filter((e) => e !== value),
          // response: categories.filter((e) => e !== value),
          //response: [...services, value],
        });
      }

      if (e.target.name == "citizenships") {
        console.log("citizensnhgfghk  2");
        setCitizenshipFilters({
          citizenships: citizenships.filter((e) => e !== value),
          //response: categories.filter((e) => e !== value),
        });

        setCitizenshipFiltersInfo({
          citizenships: citizenships.filter((e) => e !== value),
          // response: categories.filter((e) => e !== value),
          //response: [...services, value],
        });
      }
    }
  };

  const updateCheckStatus = (event, index) => {
    console.log("check status update", event.target.name);

    if (event.target.name == "categories") {
      setFilterCategories(
        filterCategories.map((filterCategory, currentIndex) =>
          currentIndex === index
            ? { ...filterCategory, checked: !filterCategory.checked }
            : filterCategory
        )
      );
    }

    if (event.target.name == "awardamounts") {
      setFilterAmounts(
        filterAmounts.map((filterAmount, currentIndex) =>
          currentIndex === index
            ? { ...filterAmount, checked: !filterAmount.checked }
            : filterAmount
        )
      );
    }
    if (event.target.name == "gradelevels") {
      setFilterGradeLevels(
        filterGradeLevels.map((filterGradeLevel, currentIndex) =>
          currentIndex === index
            ? { ...filterGradeLevel, checked: !filterGradeLevel.checked }
            : filterGradeLevel
        )
      );
    }

    if (event.target.name == "locations") {
      setFilterLocations(
        filterLocations.map((filterLocation, currentIndex) =>
          currentIndex === index
            ? { ...filterLocation, checked: !filterLocation.checked }
            : filterLocation
        )
      );
    }

    if (event.target.name == "majors") {
      setFilterMajors(
        filterMajors.map((filterMajor, currentIndex) =>
          currentIndex === index
            ? { ...filterMajor, checked: !filterMajor.checked }
            : filterMajor
        )
      );
    }

    if (event.target.name == "citizenships") {
      console.log("vrcgydhxkl,cdxsbhnm");

      setFilterCitizenships(
        filterCitizenships.map((filterCitizenship, currentIndex) =>
          currentIndex === index
            ? { ...filterCitizenship, checked: !filterCitizenship.checked }
            : filterCitizenship
        )
      );
    }
  };

  //   // Your existing JSX code for renderFilters
  //   const renderFilters = (

  //   );

  const clearAll = () => {
    //setFilterCategories(categoryFilters.categories.map(categoryOption => ({ ...categoryOption, checked: false })))
    //setFilterCategories([])
    //console.log('fbcdxvshbnkaml,;.gvf, filte', filterCategories)
    // const data = filterCategories.forEach((item) => {
    //   item.checked = false;
    // })

    //if()

    const updatedDataCategory = filterCategories.map((item) => ({
      ...item,
      checked: false,
    }));
    setFilterCategories(updatedDataCategory);
    setCategoryFilters((prevFilters) => ({
      ...prevFilters,
      categories: [], // Reset the amounts to an empty array
    }));
    setCategoryFiltersInfo((prevFilters) => ({
      ...prevFilters,
      categories: [], // Reset the amounts to an empty array
    }));

    const updatedDataAmount = filterAmounts.map((item) => ({
      ...item,
      checked: false,
    }));

    setFilterAmounts(updatedDataAmount);
    setAmountFilters((prevFilters) => ({
      ...prevFilters,
      amounts: [], // Reset the amounts to an empty array
    }));
    setAmountFiltersInfo((prevFilters) => ({
      ...prevFilters,
      amounts: [], // Reset the amounts to an empty array
    }));

    const updatedDataGrade = filterGradeLevels.map((item) => ({
      ...item,
      checked: false,
    }));
    setFilterGradeLevels(updatedDataGrade);
    setGradeLevelFilters((prevFilters) => ({
      ...prevFilters,
      gradeLevels: [], // Reset the amounts to an empty array
    }));
    setGradeLevelFiltersInfo((prevFilters) => ({
      ...prevFilters,
      gradeLevels: [], // Reset the amounts to an empty array
    }));

    const updatedDataLocation = filterLocations.map((item) => ({
      ...item,
      checked: false,
    }));
    setFilterLocations(updatedDataLocation);
    setLocationFilters((prevFilters) => ({
      ...prevFilters,
      locations: [], // Reset the amounts to an empty array
    }));
    setLocationFiltersInfo((prevFilters) => ({
      ...prevFilters,
      locations: [], // Reset the amounts to an empty array
    }));

    const updatedDataMajors = filterMajors.map((item) => ({
      ...item,
      checked: false,
    }));
    setFilterMajors(updatedDataMajors);
    setMajorFilters((prevFilters) => ({
      ...prevFilters,
      majors: [], // Reset the amounts to an empty array
    }));
    setMajorFiltersInfo((prevFilters) => ({
      ...prevFilters,
      majors: [], // Reset the amounts to an empty array
    }));

    const updatedDataCitizenships = filterCitizenships.map((item) => ({
      ...item,
      checked: false,
    }));
    setFilterCitizenships(updatedDataCitizenships);
    setCitizenshipFilters((prevFilters) => ({
      ...prevFilters,
      citizenships: [], // Reset the amounts to an empty array
    }));
    setCitizenshipFiltersInfo((prevFilters) => ({
      ...prevFilters,
      citizenships: [], // Reset the amounts to an empty array
    }));

    // console.log('ghbvfecdnwxkmsl,',updatedData)

    //console.log('ghbvfenfbdxnsmcdnwxkmsl,',data)
    //setFilterCategories(
  };
  const applyFilters = () => {
    const filtersS = {
      categoryFilters,
      amountFilters,
      gradeLevelFilters,
      locationFilters,
      majorFilters,
      citizenshipFilters,
    };
    props.FilterData(filtersS);

    // Close the modal when filters are applied
    setIsModalOpen(false);
    props.handleCloseModal();
  };

  // Return the state and renderFilters as an object
  return (
    <div className="scholarship-filters" data-animate-on-scroll>
      <div className="scholarship-filters-inner">
        <div className="clear-all-parent">
          <div className="clear-all" onClick={() => clearAll()}>
            Clear All
          </div>
          <div className="clear-all">Refine</div>
          <b className="clear-all" onClick={() => props.handleCloseModal()}>
            X
          </b>
        </div>
      </div>
      <div className="categories-parent">
        <div className="categories">Categories</div>

        {filterCategories.map((filterCategory, index) => (
          <Checkbox
            key={filterCategory.name}
            isChecked={filterCategory.checked}
            handleChange={(e) => handleChange(e, index)}
            label={filterCategory.name}
            index={index}
            value={filterCategory.value}
            name="categories"
          />
        ))}

        <div className="categories">Award Amount</div>

        {filterAmounts.map((filterCategory, index) => (
          <Checkbox
            key={filterCategory.name}
            isChecked={filterCategory.checked}
            handleChange={(e) => handleChange(e, index)}
            label={filterCategory.name}
            index={index}
            value={filterCategory.value}
            name="awardamounts"
          />
        ))}

        <div className="categories">Grade Level</div>

        {filterGradeLevels.map((filterCategory, index) => (
          <Checkbox
            key={filterCategory.name}
            isChecked={filterCategory.checked}
            handleChange={(e) => handleChange(e, index)}
            label={filterCategory.name}
            index={index}
            value={filterCategory.value}
            name="gradelevels"
          />
        ))}
        <div className="categories">Location</div>

        <div className="frame-wrapper19">
          {/* <input className="frame-child" type="text" placeholder="Search" /> */}
          <input
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Type a location..."
            className="filtersearch"
            //className="frame-child"
          />
        </div>
        {filterLocations.slice(0, 6).map((filterCategory, index) => (
          <Checkbox
            key={filterCategory.name}
            //isChecked={filterCategory.checked}
            isChecked={locationFilters.locations.includes(filterCategory.name)}
            handleChange={(e) => handleChange(e, index)}
            label={filterCategory.name}
            index={index}
            value={filterCategory.value}
            name="locations"
          />
        ))}

        <div className="categories">Major</div>
        <div className="frame-wrapper19">
          {/* <input className="frame-child" type="text" placeholder="Search" /> */}
          <input
            className="frame-child"
            type="text"
            placeholder="Search"
            onChange={(e) => setMajorSearch(e.target.value)}
          />
        </div>
        {filterMajors.slice(0, 6).map((filterCategory, index) => (
          <Checkbox
            key={filterCategory.name}
            // isChecked={filterCategory.checked}
            isChecked={majorFilters.majors.includes(filterCategory.name)}
            handleChange={(e) => handleChange(e, index)}
            label={filterCategory.name}
            index={index}
            value={filterCategory.value}
            name="majors"
          />
        ))}

        <div className="categories">Citizenship</div>
        <div className="frame-wrapper19">
          {/* <input className="frame-child" type="text" placeholder="Search" /> */}
          <input
            placeholder="Search citizenships"
            type="text"
            /* className='filtersearch' */ className="frame-child"
            onChange={(e) => setCitizenSearch(e.target.value)}
          />
        </div>

        {filterCitizenships.slice(0, 6).map((filterCategory, index) => (
          <Checkbox
            key={filterCategory.name}
            isChecked={filterCategory.checked}
            //isChecked={citizenshipFilters.citizenships.includes(filterCategory.name)}
            handleChange={(e) => handleChange(e, index)}
            label={filterCategory.name}
            index={index}
            value={filterCategory.value}
            name="citizenships"
          />
        ))}
      </div>

      {isModalOpen && <button onClick={applyFilters}>Apply</button>}

      {/* <button
        onClick={() => {
          const filtersS = {
            categoryFilters,
            amountFilters,
            gradeLevelFilters,
            locationFilters,
            majorFilters,
            citizenshipFilters,
          };
          props.FilterData(filtersS);
        }}
      >
        Apply
      </button> */}
    </div>
  );
  // categoryFiltersPhone,
  // amountFiltersPhone,
  // gradeLevelFiltersPhone,
  // locationFiltersPhone,
  // majorFiltersPhone,
  // citizenshipFiltersPhone,
  // filtersPhone,
  // sortPhone,

  // // other state variables
  // // ...
  // renderFilters,
};

export default ScholarshipFilters;
